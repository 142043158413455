import React, { Component } from 'react';
import './styles.css'

class Error404External extends Component {
    constructor() {
        super()
        this.state = {
            name: "random",
            number: 13
        }
    }
    componentDidMount(){
        // let user = JSON.parse(sessionStorage.getItem("accessToken"))
        // if(!user){
            // window.location.href = "/404"
        // }
    }
    render(){
        return (
            <div className="error404">
                
                <section class="page_404">
                <div class="container">
                    <div class="row"> 
                        <div class="col-sm-12 ">
                            <div class="col-sm-10 col-sm-offset-1  text-center" style={{paddingTop: "200px"}}>
                                {/* <div class="four_zero_four_bg" style={{width: "800px"}}>
                                    <h1 class="text-center ">404</h1>
                                </div> */}
                            
                                <div class="contant_box_404" style={{paddingLeft: "170px"}}>
                                    <h3 class="h2">
                                        Look like you're lost
                                    </h3>
                                    
                                    <p>The page you are looking for is not available!</p>
                                    
                                    <a href="/" class="link_404">Go to Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>

            </div>
        )
    }
}

export default Error404External