import { React } from "react";
const ReverseColorIndicator = ({ num, exact }) => {
    if (num == undefined && num > 9) {
        num = 2
    }
    return (
        <>
            <div className="colorIndicators">
                <div>
                    <span className={num === 5 ? "active" : ""} style={{ "backgroundColor": "#ED6D3C" }}></span>
                    <div>{num === 1 ? exact : ""}</div>
                </div>
                <div>
                    <span style={{ "backgroundColor": "#F1882E" }}></span>
                    <div>{num === 0 ? "" : ""}</div>
                </div>
                <div>
                    <span className={num === 4 ? "active" : ""} style={{ "backgroundColor": "#F9BB14" }}></span>
                    <div>{num === 2 ? exact : ""}</div>
                </div>
                <div>
                    <span style={{ "backgroundColor": "#FFE201" }}></span>
                    <div>{num === 0 ? "" : ""}</div>
                </div>
                <div>
                    <span className={num === 3 ? "active" : ""} style={{ "backgroundColor": "#FEEF27" }}></span>
                    <div>{num === 3 ? exact : ""}</div>
                </div>
                <div>
                    <span style={{ "backgroundColor": "#F3F82A" }}></span>
                    <div>{num === 0 ? "" : ""}</div>
                </div>
                <div>
                    <span className={num === 2 ? "active" : ""} style={{ "backgroundColor": "#D9EB02" }}></span>
                    <div>{num === 4 ? exact : ""}</div>
                </div>
                <div>
                    <span style={{ "backgroundColor": "#C0E204" }}></span>
                    <div>{num === 0 ? "" : ""}</div>
                </div>
                <div>
                    <span className={num === 1 ? "active" : ""} style={{ "backgroundColor": "#AAD905" }}></span>
                    <div>{num === 5 ? exact : ""}</div>
                </div>
            </div>
        </>
    )
}


export default ReverseColorIndicator