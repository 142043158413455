import { React } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom"

import ColorIndicator from "../../components/ColorIndicator/ColorIndicator";
const ReportDetails = ({data}) => {

    return (
        <>

            <div className="reportDetail-wrp">
                {/* <ContentTable></ContentTable> */}
                {/* use this "ContentTable" table component with below hard coded table data */}

                <div className="cmnTable">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>Rsid</th>
                                <th>Genotypes Index</th>
                                {/* <th>Magnitude</th> */}
                                <th>Notes</th>
                                <th>Reference</th>
                            </tr>
                        </thead>
                        {
                            data ?
                            (
                                <tbody>
                                    {data.map((item)=>(
                                        <tr>
                                            <td>{item.rsid}</td>
                                            {/* <td style={{ color: item.value.color == "yellow"? "darkorange" : item.value.color}}>{item.index}</td> */}
                                            <td>{item.index}</td>
                                            {/* <td>
                                                {console.log("item", item)}
                                                <ColorIndicator num={item.num} exact={item.value.num}></ColorIndicator>
                                            </td> */}
                                            <td style={{ width:"30%"}}>{item.value.unique? item.value.unique_value: null} </td>
                                            <td><a href={item.value.reference} target="__blank" className="reference-link">{item.value.reference}</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            ):(
                                <tbody>
                                <tr>
                                    <td>{1}</td>
                                    <td>rs2187668</td>
                                    <td>AA</td>
                                    <td>
                                        <ColorIndicator></ColorIndicator>
                                    </td>
                                    <td>Risk of gluten sensitivity is increased due to ... </td>
                                </tr>

                                <tr>
                                    <td>{1}</td>
                                    <td>rs2187668</td>
                                    <td>AA</td>
                                    <td>
                                        <ColorIndicator></ColorIndicator>
                                    </td>
                                    <td>Risk of gluten sensitivity is increased due to ... </td>
                                </tr>

                                <tr>
                                    <td>{1}</td>
                                    <td>rs2187668</td>
                                    <td>AA</td>
                                    <td>
                                        <ColorIndicator></ColorIndicator>
                                    </td>
                                    <td>Risk of gluten sensitivity is increased due to ... </td>
                                </tr>

                                <tr>
                                    <td>{1}</td>
                                    <td>rs2187668</td>
                                    <td>AA</td>
                                    <td>
                                        <ColorIndicator></ColorIndicator>
                                    </td>
                                    <td>Risk of gluten sensitivity is increased due to ... </td>
                                </tr>



                            </tbody>
                            )
                        }
                    </Table>

                </div>

            </div>
        </>
    )

}

export default ReportDetails
