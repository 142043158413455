import React from "react";
import moment from "moment";
import { Table, Modal } from "react-bootstrap";
import LineGraphs from "../../components/LineGraph/LineGraph";
import PageHead from "../../components/PageHead/PageHead";
import { apiService } from "../../services/api_service";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import HorizontalFaceAgeIndicator from "../../components/HorizontalFaceAgeIndicator";

function CustomTable({ fullResult, message, handleShow1, handleShow }) {
  if (message) {
    return <h5 style={{ textAlign: "center", padding: "20px" }}>{message}</h5>;
  }

  const checkValidDescription = Object.values(fullResult?.result || {})?.filter(
    (result) => result?.description?.name
  );

  if (!checkValidDescription.length) {
    return (
      <h5 style={{ textAlign: "center", padding: "20px" }}>
        Face age result not found for this user
      </h5>
    );
  }

  return (
    <Table responsive striped>
      <thead>
        <tr style={{ color: "white" }}>
          <th>Title</th>
          <th>Indicator</th>
          <th className="txt-center">Most Recent Score</th>
          <th className="txt-center">Previous Score</th>
          <th className="txt-center">Average</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(fullResult?.result || {})?.map((result) =>
          result?.description?.name ? (
            <>
              <tr>
                <td>
                  {result?.description?.name
                    ? camelize(result?.description?.name)
                    : "N.A"}
                </td>

                <td>
                  <HorizontalFaceAgeIndicator
                    value={result?.recent}
                    aspect={result?.description?.key || ""}
                    age={fullResult.real_age}
                  />
                </td>
                <td className="txt-center">{result?.recent || "-"}</td>
                <td className="txt-center">{result?.previous || "-"}</td>
                <td className="txt-center">
                  {result?.average?.toFixed(1) || "-"}
                </td>
                <td>
                  <a
                    onClick={() => handleShow(result)}
                    style={{ cursor: "pointer" }}
                  >
                    View Graph
                  </a>
                  <a
                    className="ml-3"
                    onClick={() => handleShow1(result)}
                    style={{ cursor: "pointer" }}
                  >
                    View Result
                  </a>
                </td>
              </tr>
            </>
          ) : null
        )}
      </tbody>
    </Table>
  );
}

class FaceScanResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: false,
      users_list: [],
      loader: false,
      practitioner_id: this.props.user?.id,
      email: this.props.user?.email,
      message: "Please select patients from the above dropdown",
      ScoreData: {},
      result: {},
      fullResult: [],
      scoreList: [],
      access: [],
      clearUser: false,
      scoreCreatedateList: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose1 = this.handleClose1.bind(this);
    this.handleShow1 = this.handleShow1.bind(this);
  }
  async componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        this.setState({ loader: true });
        const practitioner_id = btoa(nextProps.user._id);
        //let users_list = await apiService.AccessUsersList(practitioner_id);
        this.setState({
          //users_list,
          loader: false,
          practitioner_id,
          ...nextProps.user,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (result) => {
    console.log("result", result);

    const graph = result.graph.filter((g) => g?.value && g.value !== 0);

    graph.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    let formattedDatesAsc = graph.map((item) =>
      moment(item.createdAt).format("DD-MM-YYYY")
    );

    this.setState({
      show: true,
      result,
      scoreList: graph.map((g) => g?.value),
      scoreCreatedateList: formattedDatesAsc,
    });
  };

  handleClose1 = () => {
    this.setState({ show1: false });
  };
  handleShow1 = (data) => {
    this.setState({ show1: true, result: data });
  };
  async getResult(user_id) {
    if (!this.props.user?._id || !user_id) return;
    this.setState({ loader: true });
    if (user_id == "select") {
      this.setState({
        loader: false,
        message: "Please select patients from the above dropdown",
        fullResult: [],
      });
      return;
    }
    let userId = Buffer.from(String(user_id)).toString("base64");
    let access = this.state.access;
    if (
      this.state.access === undefined ||
      Object.keys(this.state.access).length <= 0
    ) {
      let practitioner_details = this.props.user;
      access = practitioner_details.access_controls.facial_analysis;
    }

    let face_age_result = await apiService.FaceScanResult(userId);

    if (
      !face_age_result ||
      !face_age_result?.data ||
      !face_age_result?.success ||
      face_age_result?.message === "user not found" ||
      face_age_result?.data?.status === "Image not uploaded"
    ) {
      this.setState({
        loader: false,
        message: "No results available",
        fullResult: {},
        access,
        clearUser: true,
      });
      return;
    }

    this.setState({
      message: "",
      loader: false,
      fullResult: face_age_result?.data,
      access,
    });
  }

  render() {
    const {
      show,
      show1,
      users_list,
      message,
      result,
      scoreList,
      clearUser,
      fullResult,
      scoreCreatedateList,
    } = this.state;

    return (
      <>
        <div className="dnaReport-wrp">
          <PageHead
            title="Face Scan Report"
            UserList={users_list}
            page={"Face_Scan_Report"}
            DetailsGetAPI={this.getResult.bind(this)}
            clearUser={clearUser}
          ></PageHead>

          {/* <SelectionsBar></SelectionsBar> */}
          {/* use this "SelectionsBar" component with below hard coded data */}
          <div className="selectionBar">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-auto">
                <h3>Age at Most Recent Sample: {fullResult?.real_age}</h3>
              </div>
            </div>
          </div>

          <div className="epigeneticReporttable">
            {/* <ContentTable></ContentTable> */}
            {/* use this "ContentTable" table component with below hard coded table data */}

            <div className="cmnTable">
              {this.state.loader ? (
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="info"
                  style={{
                    marginLeft: "50%",
                    padding: "20px",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                />
              ) : fullResult ? (
                <CustomTable
                  fullResult={fullResult}
                  message={message}
                  handleShow1={this.handleShow1}
                  handleShow={this.handleShow}
                />
              ) : (
                <h5 style={{ textAlign: "center", padding: "20px" }}>
                  {message}
                </h5>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={this.handleClose}
          centered
          className="cmnModal epigeneticgraphModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{result?.description?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="graphCard">
                  <LineGraphs
                    data={
                      scoreList.length <= 1
                        ? [0, scoreList[0]]
                        : scoreList.length > 14
                        ? scoreList.slice(0, 14)
                        : scoreList
                    }
                    label_names={
                      scoreCreatedateList.length <= 1
                        ? [1, scoreCreatedateList[0]]
                        : scoreCreatedateList.length > 14
                        ? scoreCreatedateList.slice(0, 14)
                        : scoreCreatedateList
                    }
                    xLabel={"Report Date"}
                    yLabel={"Scores"}
                    roundOffHundred={true}
                  ></LineGraphs>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show1 && result?.description}
          onHide={this.handleClose1}
          centered
          className="cmnModal dnareportModal"
        >
          {show1 && result?.description && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>{result?.description?.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: result.description.html_description,
                  }}
                ></div>
              </Modal.Body>
            </>
          )}
        </Modal>
      </>
    );
  }
}
function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toUpperCase();
    })
    .replace(/\s+/g, " ");
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
};

export default connect(mapState, actionCreators)(FaceScanResult);
