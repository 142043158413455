import React from "react";
import TabItems from "../../components/TabItems/TabItems_old";
import PageHead from "../../components/PageHead/PageHead";
import Users from "./Users";
import Tracking from "./Tracking";
import Engagement from "./Engagement";
import {apiService} from '../../services/api_service'
import { connect } from "react-redux";
import { alertActions } from "../../actions";


class BusinessInsight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practitioner_id: this.props.user?.id,
      users_list: [],
      loader: false,
      tab: ["Users", "Engagement", "Tracking"],
      view: "Users",
    };
  }

  // async componentDidMount() {
  //   let user_details = await apiService.AccessUsersList(
  //     this.state.practitioner_id
  //   );
  //   this.setState({
  //     users_list: user_details,
  //     loader: false,
  //   });
  // }

  async componentWillReceiveProps(nextProps){
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        const practitioner_id = btoa(nextProps.user._id)
        //let users_list = await apiService.AccessUsersList(practitioner_id);

        this.setState({
          //users_list,
          loader: false,
          practitioner_id,
          ...nextProps.user,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  ChangeTabs(tab) {
    this.setState({ view: tab });
  }

  // handleTabs(view){
  //     this.setState({view})
  // }

  render() {
    const { tab, view } = this.state;
    return (
      <>
        <div className="businessinfo-wrp">
          <PageHead title="Business Information" is_disabled={true}></PageHead>

          {/* use this for tab head */}
          {/* <TabItems></TabItems> */}
          <TabItems
            tabs={tab}
            view={view}
            ChangeTabs={this.ChangeTabs.bind(this)}
            key={view}
          ></TabItems>
          <div className="selectionBar">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-auto">
                <h3>Business Information </h3>
              </div>
            </div>
          </div>

          {/* <div className="tabContainer">
                  <ul>
                    <li onClick={() => this.handleTabs("users")} className="tabItem active">Users</li>
                    <li onClick={() => this.handleTabs("engagement")}  className="tabItem ">Engagement</li>
                    <li onClick={() => this.handleTabs("tracking")}  className="tabItem">Tracking</li>
                  </ul>
                </div> */}

          {/* use this for tab head */}

          <div className="tabContent">
           {view == "Users" ? (
              <Users practitioner_id={this.state.practitioner_id}></Users>
            ) : null}
            {view == "Engagement"  ?(
              <Engagement
                practitioner_id={this.state.practitioner_id}
              ></Engagement>
            ): null}
            {view == "Tracking"  ? (
              <Tracking practitioner_id={this.state.practitioner_id}></Tracking>
            ): null}
          </div>
        </div>
      </>
    );
  }
};

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return {loggingIn, user};
}

const actionCreators = {
  success: alertActions.success
}

export default connect(mapState, actionCreators)(BusinessInsight)
