import React from "react";
import Multiselect from "multiselect-react-dropdown";

class FilterDropdown extends React.Component {

  constructor(props) {
    super(props)
    this.style={
      multiselectContainer: {
        width: "260px",
        height:"10px",        
      },
      chips:{
        display:"none"
      }, 
      searchBox:{
        height: "39px"
      }
    }

  }
  
  render() {
    return (
      <>
        <Multiselect
          style={this.style}
          className="filterBtn"
          displayValue="text"
          onSelect={this.props.reportTypeCheckboxChange}
          options={this.props.reportTypes}
          selectedValues={this.props.reportChecked}
          onRemove={this.props.reportTypeCheckboxChange}
          placeholder="Filter By Report Type"
          showCheckbox
          showArrow
        >
        </Multiselect>
      </>
    )
  }
}

export default FilterDropdown