import React from "react";
import UserMenu from "../../components/UserMenu/UserMenu";
import { Modal, Form, Spinner, Button } from 'react-bootstrap'
import { apiService } from "../../services/api_service";
import { userActions } from "../../actions"
import { connect } from 'react-redux'
import Select from 'react-select'
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { userService } from "../../services";
import { nameRegex, usernameRegex } from "../../constants";
import { getPartnerUrlPrefix } from '../../helpers/partner';


class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            username: "",
            id: "",
            email: "",
            partner: null,
            submitted: false,
            show: false,
            emailError: false,
            first_nameError: false,
            user_nameError: false,
            modal: [],
            phoneError: false,
            phoneValid: false,
            logo: '/images/logo.svg',
            logoWidth: '26%',
            logoheight: '',
            objectPosition: '',
            objectFit: '',
            width: '',
            height: '',
            availablePartners: [],
            selectedPartner: { label: "Select a Partner", value: "select" },
            practitioner_id: ""
        }
        this.handleexit = this.handleexit.bind()
        this.handleModal = this.handleModal.bind()
    }

    componentDidMount() {
        if (this.props.user?._id) {
            this.setState({
                practitioner_id: btoa(this.props.user._id),
                username: this.props.user?.name || "",
                id: this.props.user?.id || "",
                email: this.props.user?.email || "",
                partner: this.props.user?.partner_id || null,
            })
        }
    }

    async componentWillReceiveProps(nextProps) {
        const userData = nextProps.user;
        const partner_id = userData?.partner_id;
        let logo = await this.logoPick(partner_id);

        if (nextProps.user?._id && nextProps.user?._id !== this.props.user?._id) {
            try {
                const practitioner_id = btoa(userData._id);

                if (practitioner_id) {
                    let availablePartners = await apiService.getAvailablePartners({
                        // practitioner_id,
                    });
                    let selectedPartner = availablePartners.filter(
                        (p) => p.value == partner_id
                    );

                    this.setState({
                        availablePartners,
                        selectedPartner,
                        username: userData?.name || "",
                        id: userData?.id || "",
                        email: userData?.email || "",
                        partner: userData?.partner_id || null,
                        ...this.props.user,
                    });
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.user?._id !== this.props.user?._id) {
            this.setState({
                username: this.props.user?.name || "",
                id: this.props.user?.id || "",
                email: this.props.user?.email || "",
                partner: this.props.user?.partner_id || null,
                practitioner_id: btoa(this.props.user?._id),
                ...this.props.user,
            })
        }
    }

    handleexit = async (e) => {
        sessionStorage.setItem("sessionExpired", false);
        const partner = this.props.user?.partner_id || null;
        const url = `${getPartnerUrlPrefix(partner)}/signin`
        await userService.logout().then(() => {
            window.location.replace(url);
        });
        this.props.history.push(url);
    }


    handleModal = async () => {
        let data = await apiService.viewManagementUser("self", this.state.id);
        if (!Array.isArray(data)) {
            this.setState({ show: !this.state.show, modal: data });
        }
    }

    handleEdit = async () => {
        this.setState({ loading: true })
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        let modal = this.state.modal
        if (modal["email"] && modal["first_name"] && modal["phone"]) {
            let condition = {
                first_name: this.state.modal.first_name,
                last_name: this.state.modal.last_name,
                //user_name: this.state.modal.user_name,
                //email: this.state.modal.email,
                phone: this.state.modal.phone
            }
            let data = await apiService.editManagementUser(this.state.practitioner_id, condition)

            if (data) {
                console.log("Successfully updated user")
                this.props.updateUserData({ ...this.props.user, ...condition })
                this.setState({ message: "Successfully Updated User", loading: false, usersCollection: [], show: false })
            } else {
                console.log("Something went wrong", data)
                this.setState({ message: data, loading: false, usersCollection: [] })
            }
        } else {
            this.setState({
                emailError: modal["email"] ? modal["email"].match(regexEmail) : modal["email"] ? false : true,
                first_nameError: modal["first_name"] ? false : true,
                phoneError: modal["phone"] ? false : true,
                loading: false
            })
        }
    }

    async validateEmail(email) {
        let result = await apiService.validateUMEmail(email)
        if (result == true) {
            return true
        } else {
            return false
        }

    }
    async handleChange(e) {
        let name = e.target.name, value = e.target.value
        let emailValid = false
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        switch (name) {
            case "email":
                if (!value.match(regexEmail)) {
                    this.setState({ emailError: true, emailExist: false, message: "" })
                } else {
                    this.setState({ emailError: false, emailExist: false, message: "" })
                }
                break;
            case "user_name":
                if (!value) {
                    this.setState({
                        user_nameError: true,
                        user_nameMessage: "",
                    });
                } else if (!usernameRegex.test(value)) {
                    this.setState({
                        user_nameError: true,
                        user_nameMessage: "Please enter valid username.",
                    });
                } else {
                    this.setState({
                        user_nameError: false,
                        user_nameMessage: "",
                    });
                }

                break;
            case "first_name":
                if (!value) {
                    this.setState({
                        first_nameError: true,
                        first_nameMessage: "",
                    });
                } else if (!nameRegex.test(value)) {
                    this.setState({
                        first_nameError: true,
                        first_nameMessage: "Please enter a valid first name.",
                    });
                } else {
                    this.setState({
                        first_nameError: false,
                        first_nameMessage: "",
                    });
                }
                break;
            case "last_name":
                if (!value) {
                    this.setState({
                        last_nameError: true,
                        last_nameMessage: "",
                    });
                } else if (!nameRegex.test(value)) {
                    this.setState({
                        last_nameError: true,
                        last_nameMessage: "Please enter a valid last name.",
                    });
                } else {
                    this.setState({
                        last_nameError: false,
                        last_nameMessage: "",
                    });
                }
                break;
            case "phone":
                let phoneno = /^\+\d+$/
                if (this.state.modal["email"] && this.state.modal["first_name"]) {
                    if (!value.match(phoneno)) {
                        this.setState({ phoneValid: true, message: "" })
                    } else {
                        this.setState({ phoneValid: false, message: "" })
                    }
                    if (!value) {
                        this.setState({ phoneError: true, message: "" })
                    } else {
                        this.setState({ phoneError: false, message: "" })
                    }
                } else {
                    this.setState({
                        emailError: this.state.modal["email"] ? this.state.modal["email"].match(regexEmail) : this.state.modal["email"],
                        first_nameError: this.state.modal["first_name"],
                        message: ""
                    })
                }
                break;
            default:
                break
        }
        this.state.modal[name] = value
        if (this.state.modal.email && this.state.modal.email.match(regexEmail)) {
            emailValid = await this.validateEmail(this.state.modal.email)
            this.setState({ emailExist: emailValid, message: "" })
        }
    }
    async logoPick(partner_id) {
        let logo, logoWidth = '26%', logoheight, objectPosition, objectFit, width, height
        switch (partner_id) {
            case 1:
                logo = '/images/logo.svg';
                break;
            case 7:
                logo = '/images/logos/biosynergy.png';
                logoWidth = '100%';
                logoheight = '100%';
                objectPosition = 'center';
                objectFit = 'contain';
                width = '100px';
                height = '65px';
                break;
            case 13:
                logo = '/images/logos/TNLogo.png';
                break;
            case 25:
            case 31:
            case 33:
                logo = '/images/logos/hc_logo.png'
                logoWidth = '26%';
                break;
            case 26:
                logo = '/images/logos/truepi.png';
                break;
            case 28:
                logo = '/images/logos/mamh.png';
                logoWidth = '26%';
                break;
            case 45:
                logo = '/images/logos/promake_logo.png';
                logoWidth = '19%';
                break;
            case 47:
                logo = '/images/logos/longevity-clinic.jpg';
                logoWidth = '47%';
                break;
            case 46:
                logo = '/images/logos/longevity-logo.png';
                logoWidth = '65%';
                break;
            case 48:
                logo = '/images/logos/lifetime_logo.png';
                logoWidth = '32%';
                break;
            case 11:
                logo = '/images/logos/formulahealth_logo.png';
                logoWidth = '50%';
                break;
            case 15:
                logo = '/images/logos/bodyology_logo.png';
                logoWidth = '45%';
                break;
            case 50:
                logo = '/images/logos/eterna_life.png';
                logoWidth = '40%';
                break;
            case 51:
                logo = '/images/logos/bevivo.png';
                logoWidth = '30%';
                break;
            case 52:
                logo = '/images/logos/biolimitless.png';
                logoWidth = '60%';
                break;
            default:
                logo = '/images/logo.svg'
                break;
        }
        this.setState({ logo, logoWidth, logoheight, objectFit, objectPosition, width, height })
        return logo

    }

    async changePartner(e) {
        let switchPartner = await apiService.switchPartner({ practitioner_id: this.state.id, partner_id: e.value });

        console.log("switchPartner", switchPartner)
        let user = this.props?.user
        if (user) {
            user.partner_id = e.value;
            this.props.updateUserData(user);
            this.setState({ selectedPartner: e });
            //window.location.reload();
        }
    }

    render() {
        const {
            modal,
            emailError,
            first_nameError,
            phoneError,
            phoneValid,
            user_nameError,
            loading,
            logo,
            logoWidth,
            availablePartners,
            selectedPartner,
            logoheight,
            objectPosition,
            objectFit,
            width,
            height,
            user_nameMessage,
            first_nameMessage,
            last_nameError,
            last_nameMessage,
        } = this.state;

        const username = this.props?.user?.username || "";

        return this.state.practitioner_id && (
            <>
                <header>
                    <div className="container-wrp">
                        <div className="row justify-content-between">
                            <div className="col-4 col-md-3">
                                <div style={{ width: `${width}`, height: `${height}` }}>
                                    <img className="img-fluid" src={logo} style={{ width: `${logoWidth}`, height: `${logoheight}`, objectFit: `${objectFit}`, objectPosition: `${objectPosition}` }} alt="Logo" ></img>
                                </div>
                            </div>
                            {availablePartners && availablePartners.length > 0 &&
                                <div className="col-12 col-md-3 partner-select">
                                    <p>Partner :  </p>
                                    <Select
                                        className="select-options"
                                        aria-labelledby="aria-label"
                                        value={selectedPartner}
                                        inputId="aria-example-input"
                                        name="aria-live-color"
                                        options={availablePartners}
                                        onChange={(e) => this.changePartner(e)}
                                    />
                                </div>
                            }
                            <div className="col-auto col-md-auto d-flex align-items-center">

                                <UserMenu username={username} logout={(e) => this.handleexit} modal={() => this.handleModal}></UserMenu>
                                <button className="menuicon d-md-none"><img src="/images/hamburgericon.svg" alt="Menu"></img></button>
                            </div>
                        </div>
                    </div>
                </header>
                <Modal show={this.state.show} onHide={() => this.handleModal()} centered className="trackingModal" size="sm" >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Profile:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-management-modal">
                        <Form>
                            <Form.Group className="sign-field">
                                <Form.Label>Email</Form.Label>
                                <Form.Control placeholder="Enter Email" type="email" name="email" value={modal.email} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {emailError ? "Please Enter a valid Email" : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>User Name</Form.Label>
                                <Form.Control placeholder="Enter Username" type="text" name="user_name" value={modal.user_name}
                                    //onChange={(e)=>this.handleChange(e)} 
                                    disabled
                                />
                                <Form.Text className="text-muted">
                                    {user_nameError ? user_nameMessage || "Please Enter User Name" : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control placeholder="Enter FirstName" type="text" name="first_name" value={modal.first_name} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {first_nameError ? first_nameMessage || "Please Enter First Name" : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control placeholder="Enter LastName" type="text" name="last_name" value={modal.last_name} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {last_nameError ? last_nameMessage || "Please Enter Last Name" : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control placeholder="EnterPhone" type="text" name="phone" value={modal.phone} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {phoneError ? "Please Enter Phone number" : null}
                                    {phoneValid ? "Please enter valid phone number eg:(+1987654321) " : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Button className="continuebtn" onClick={() => this.handleEdit()}>Edit Profile{loading ? <Spinner animation="border" variant="light" size="sm" style={{ float: 'center' }} className='spinnerClass' /> : null}</Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
};

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    //userLogout : userService.logout,
    updateUserData: userActions.updateUserData,
}
export default connect(mapState, actionCreators)(Header);
