import React from "react";
// import { Table, Modal } from "react-bootstrap";
import { apiService } from "../../services/api_service";
import { Spinner } from "react-bootstrap";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SearchBox from "../../components/SearchBox/SearchBox";
import Pagination from "../../components/Pagination/Pagination";
import FilerButton from "../../components/UserMenu/FilerButton";
import KitsTable from "../../components/Tables/KitsTable";
import Bloodmodal from "../../components/Modal/BloodKitModal";
import BulkUploadModal from "../../components/Modal/BulkUploadModal";
import { connect } from "react-redux";

class BloodKits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      users_list: [],
      loading: false,
      message: "Please select patients from the above dropdown",
      practitioner_id: "",
      email: "",
      currentIndex: 0,
      kit_details: [],
      total: 0,
      pages: [0],
      skip: 0,
      limit: 10,
      kit_type: [
        { label: "------ALL------", value: -1 },
        { label: "Assigned", value: 1 },
        { label: "Unassigned", value: 0 },
      ],
      filter_type: -1,
      search: "",
      submitted: false,
    };
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
      this.setState({
        loading: true,
        practitioner_id: btoa(nextProps.user._id),
        ...nextProps.user,
      });
      try {
       setTimeout(async () => {
        await this.GetAllKits();
       },5)
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  async GetAllKits() {
    try {
      const { skip, limit } = this.state;
      let condition = {
        skip: skip * limit,
        limit,
      };
      if (this.state.search) {
        condition.search_item = this.state.search;
      }
      if ([0, 1, -1].includes(this.state.filter_type)) {
        condition.filter = {
          assigned_status: this.state.filter_type,
        };
      }
      let data = await apiService.GetBloodKits(condition);
      console.log("bloodkits", data);
      let total = data[0].total.length > 0 ? data[0].total[0].count : 0;
      let pages = [...Array(Math.ceil(total / limit)).keys()];
      this.setState({
        kit_details: data[0].kits.sort((a, b) => b.createdAt - a.createdAt),
        total: total,
        pages: pages,
        loading: false,
      });
    } catch (error) {
      console.log("Error in fetching users", error);
      this.setState({ kit_details: [], loading: false });
    }
  }
  async FilterByAssignedStatus(value) {
    this.state.filter_type = value;
    this.setState(
      {
        filter_type: value,
        skip: 0,
      },
      async () => {
        await this.GetAllKits();
      }
    );
  }
  async KitListBySearch(value) {
    this.setState(
      {
        search: value,
        skip: 0,
      },
      async () => {
        await this.GetAllKits({ search: value });
      }
    );
  }
  async handlePagination(skip) {
    this.setState({ loading: true, skip }, async () => {
      await this.GetAllKits();
    });
  }
  addKitHandler() {
    this.setState({
      show1: true,
    });
  }
  bulkUploadHandler() {
    console.log("bulk upload");
    this.setState({
      show2: true,
    });
  }
  handleClose1 = () => {
    this.setState({
      show1: false,
    });
  };
  handleClose2 = () => {
    this.setState({
      show2: false,
    });
  };
  render() {
    const { show1, show2, kit_type, loading, kit_details } = this.state;
    return (
      <>
        <div className="homepagehead">
          <div className="row justify-content-between align-items-end">
            <div className="col-12 col-sm-auto">
              <h1>Blood Test Kits</h1>
              <BreadCrumb title="Blood Test Kits"></BreadCrumb>
            </div>

            <div className="col-12 col-sm-auto d-flex">
              <FilerButton
                types={kit_type}
                updateList={this.FilterByAssignedStatus.bind(this)}
              ></FilerButton>
              <SearchBox
                UserListBYSearch={this.KitListBySearch.bind(this)}
                limit={this.state.limit}
                skip={this.state.skip}
              ></SearchBox>
            </div>
          </div>
          <div className="addBtn-home">
            <button className="addBtn" onClick={() => this.addKitHandler()}>
              <i class="bi bi-file-plus-fill"></i> Add Blood Kit
            </button>
            <button className="addBtn" onClick={() => this.bulkUploadHandler()}>
              <i class="bi bi-upload"></i> Bulk Upload Blood Kits
            </button>
          </div>
          <div className="patientsTable">
            {this.state.wait_loading ? (
              <Spinner
                className="spinnerClass"
                animation="border"
                style={{
                  float: "center",
                  position: "absolute",
                  margin: "10% 10%",
                  left: "45%",
                  top: "25%",
                }}
              />
            ) : null}
            <KitsTable loader={loading} kitDetails={kit_details}></KitsTable>
          </div>
          <div className="d-flex justify-content-end">
            <Pagination
              paginationClick={(num) => this.handlePagination(num)}
              total={this.state.total}
              pages={this.state.pages}
              skip={this.state.skip}
              limit={this.state.limit}
            ></Pagination>
          </div>
        </div>
        {show1 && (
          <Bloodmodal
            show={show1}
            title="Create Blood Kit"
            type="create"
            closeModal={this.handleClose1}
            updateAPI={this.GetAllKits.bind(this)}
            practitioner_id={this.state.practitioner_id}
          ></Bloodmodal>
        )}
        {show2 && (
          <BulkUploadModal
            show={show2}
            title="Bulk Upload Blood Kits"
            closeModal={this.handleClose2}
            updateAPI={this.GetAllKits.bind(this)}
            practitioner_id={this.state.practitioner_id}
          ></BulkUploadModal>
        )}
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return {loggingIn, user};
}

const actionCreators = {
  //success: alertActions.success
}

export default connect(mapState, actionCreators)(BloodKits)
