import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "bootstrap-icons/font/bootstrap-icons.css";


ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();