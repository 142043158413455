import React, { Component } from 'react';
import './styles.css'

class AboutPage extends Component {
    constructor() {
        super()
        this.state = {
            name: "random",
            number: 13
        }
    }
    render(){
        return (
            <div>
                <p>This is the About screen</p>
                {this.state.number}
            </div>
        )
    }
}

export default AboutPage