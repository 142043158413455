import { currentConstants } from '../constants';

const localCurrent = sessionStorage.getItem('current')
let currentVal = localCurrent ? JSON.parse(localCurrent) : {}

const initialState = currentVal ? { type: "add-user", currentVal } : {};

export function current(state = initialState, action) {
  switch (action.type) {
    case currentConstants.ADD:
      return {
        type: 'add-user',
        currentVal: action.users
      };
    case currentConstants.REMOVE:
      return {
        type: 'remove-user',
        currentVal: {}
      };
    default:
      return state
  }
}