import React from 'react';
import { Route } from 'react-router-dom';
import { getBackgroundImage } from '../../helpers/partner';

const AuthLayout = ({ children }) => (
  <>
    <div className="signin-wrp">
      <div
        className="bgPart"
        style={{ backgroundImage: getBackgroundImage(children.props.partner) }}
      >
      </div>
      {children}
    </div>
  </>
);


const AuthLayoutRoute = ({ component: Component, partner: Partner, ...rest }) => {

  return (
    <Route {...rest} render={matchProps => (
      <AuthLayout>
        <Component {...matchProps} partner={Partner} />
      </AuthLayout>
    )} />
  )
};

export default AuthLayoutRoute;  
