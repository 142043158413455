import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Loader from "../../../components/Loader/Loader";

import { userActions, alertActions } from '../../../actions';
import { regexEmail } from "../../../constants";
import { getPartnerUrlPrefix } from "../../../helpers/partner";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.props.logout(this.props.history);

    this.state = {
      email: "",
      submitted: false,
      loading: false,
      emailError: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentDidMount() {
    // set default color
    let partner_id = this.props.partner
    // set default color
    if (partner_id == '7') {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#000000');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#000000');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#a6a6a6');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#e6e6e6');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#818181');
      document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
    }
    if (partner_id == '50') {
      document.getElementById("bodyID").style.setProperty("--themecolor", "#05aaff");
      document.getElementById("bodyID").style.setProperty("--themefontcolor", "#05aaff");
      document.getElementById("bodyID").style.setProperty("--liteThemecolor", "#b3e6ff");
      document.getElementById("bodyID").style.setProperty("--liteBackgroundColor", "#cceeff");
      document.getElementById("bodyID").style.setProperty("--navtext", "#ffffff");
      document.getElementById("bodyID").style.setProperty("--liteButtonBackground", "#cdd6e5");
    } else if (partner_id === 51) {
      document.getElementById("bodyID").style.setProperty("--themecolor", "#b34944");
      document.getElementById("bodyID").style.setProperty("--themefontcolor", "#b34944");
      document.getElementById("bodyID").style.setProperty("--liteThemecolor", "#f8eded");
      document.getElementById("bodyID").style.setProperty("--liteBackgroundColor", "#f8eded"); document.getElementById("bodyID").style.setProperty("--navtext", "#ffffff");
      document.getElementById("bodyID").style.setProperty("--liteButtonBackground", "#dca5a3");
    }
    else if (partner_id === 52) {
      document.getElementById("bodyID").style.setProperty("--themecolor", "#6037d9");
      document.getElementById("bodyID").style.setProperty("--themefontcolor", "#6037d9");
      document.getElementById("bodyID").style.setProperty("--liteThemecolor", "#b7a5ee");
      document.getElementById("bodyID").style.setProperty("--liteBackgroundColor", "#d7cdf6");
      document.getElementById("bodyID").style.setProperty("--liteButtonBackground", "#aa81ff");
      document.getElementById("bodyID").style.setProperty("--navtext", "#ffffff");
      document.getElementById("bodyID").style.setProperty("--headpadding", "22px 0px");
    } else {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#09a79e');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#09a79e');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#09a79e69');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#09a79e17');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#0ac2b9');
      document.getElementById("bodyID").style.setProperty('--filterSvg', '');
    }
  }
  handleKeyPress(e) {
    if (e.charCode === 13) {
      if (this.state.email) {
        e.preventDefault()
        this.handleSubmit()
      } else {
        this.setState({ emailError: true })
      }
    }
  }

  handleEmail(e) {
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({ email: value });
  }

  async handleSubmit(e) {
    if (this.state.email && this.state.email.match(regexEmail)) {
      this.setState({ submitted: true, emailError: false });
      const { email } = this.state;
      if (email) {
        let res = await this.props.forgotPassword(
          this.props.history,
          email,
          this.props.partner
        );
        if (!res) {
          this.setState({ submitted: true });
        }
      }
    } else {
      this.setState({ emailError: true });
    }
  }

  componentDidUpdate() {
    if (this.props.alert.message) {
      setTimeout(() => {
        this.props.clearAlerts();
      }, 5000)
    }
  }

  handleClose = () => {
    this.props.clearAlerts();
  }
  render() {
    const { loggingIn, alert, partner } = this.props;
    const { email, submitted, loading, emailError } = this.state;
    return (
      <div className="formPart forgotForm">
        {alert.message &&
          <div className={`alert ${alert.type}`} style={{ marginBottom: 0 }}>
            {alert.message}
            <button class="close" onClick={this.handleClose} >x</button>
          </div>
        }
        <Loader loading={loading} />
        <h1>Forgot Password</h1>
        <h6>Please Enter your registered email address here</h6>
        <Form>
          <Form.Group className="sign-field">
            <Form.Control placeholder="Email" type="email" name="email" value={email} onChange={this.handleEmail} onKeyPress={this.handleKeyPress} required />
            <Form.Text className="text-muted">
              {emailError ? "Please Enter a valid Email" : null}
            </Form.Text>
          </Form.Group>
          <Button className="continuebtn" onClick={this.handleSubmit}
            disabled={!this.state.email.match(regexEmail) || submitted}
          >
            Request password Reset link
          </Button>
          {<Link className="backtologin" to={`${getPartnerUrlPrefix(partner)}/signin`}>Back to Log In</Link>}
        </Form>
      </div>
    );
  }
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  forgotPassword: userActions.forgotPassword,
  login: userActions.login,
  logout: userActions.logout
}
export default connect(mapState, actionCreators)(ForgotPassword);
