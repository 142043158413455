export const handleDownloadFile = async (sourcePath,destinationPath,fileName) => {
    try {
        const response = await fetch(sourcePath);
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const pdfBlob = await response.blob();
        
        const url = URL.createObjectURL(pdfBlob);
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(destinationPath, fileName);
        
        // Append the anchor element to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Release the object URL
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    };

    