import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './alert-action';
// import { history } from '../helpers';
import { getPartnerUrlPrefix } from '../helpers/partner';

export const userActions = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    otp,
    register,
    getAll,
    delete: _delete,
    updateUserData
};

function updateUserData(user) {
    return {
        type: userConstants.UPDATE_USERDATA,
        user
    };
}

function login(history, username, password, partner) {
    return dispatch => {
        dispatch(request({ username }));

        return userService.login(username, password)
            .then(
                (user) => {
                    let url = `${getPartnerUrlPrefix(partner)}/changepassword`;

                    if (user.expiryToken) {
                        dispatch(alertActions.error("Credentials Expired"));
                        url += `/${encodeURIComponent(user.expiryToken)}`
                        history.push(url);
                        return;
                    }


                    if (user?.accessToken) {
                        url = `${getPartnerUrlPrefix(partner)}/otp`;
                        sessionStorage.setItem('accessToken', user?.accessToken);
                        dispatch(success(user));
                        dispatch(alertActions.success("Successfully Logged In"));
                        history.push(url);
                    }

                    return true
                },
                error => {
                    console.log('error', error)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return false
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    return { type: userConstants.LOGOUT };
}

function forgotPassword(history, username, partner) {
    return dispatch => {
        dispatch(request({ username }));
        return userService.forgotPassword(username)
            .then(
                user => {
                    dispatch(alertActions.success("Successfully Sent the Email"));
                    setTimeout(() => {
                        console.log("Successfully sent email")
                        const url = `${getPartnerUrlPrefix(partner)}/signin`
                        history.push(url, user);
                    }, 6000)
                    return true
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return false
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_FAILURE, error } }
}

function resetPassword(history, username, password, partner, token) {
    return dispatch => {
        dispatch(request({ username }));

        return userService.resetPassword(password, token)
            .then(
                user => {
                    if (user?.data?.statusCode === 400) {
                        dispatch(alertActions.error(user.data.message));
                        return user?.data
                    } else if (user.status === 200) {
                        dispatch(alertActions.success("Successfully reset the password"));
                        dispatch(success(user));
                        const url = `${getPartnerUrlPrefix(partner)}/signin`
                        history.push(url);
                        return user?.data

                    }
                }
            )
            .catch(error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error?.data
            });
    };
}

function request(user) { return { type: userConstants.RESET_REQUEST, user } }
function success(user) { return { type: userConstants.RESET_SUCCESS, user } }
function failure(error) {
    return { type: userConstants.RESET_FAILURE, error }
}

function otp(history, otp, token) {
    return dispatch => {
        dispatch(request({ otp }));

        //const user = history.location.state;
        return userService.otp(otp, token)
            .then(
                result => {
                    dispatch(successUser(result.data));
                    dispatch(success(result));
                    history.push('/');
                    dispatch(alertActions.success("Successfully Logged In"));
                    return result
                },
                error => {
                    const msg = error?.message || ""
                    console.log("action error", msg)
                    dispatch(failure(msg));
                    dispatch(alertActions.error(msg));
                    return error
                }
            );
    };

    function request(result) { return { type: userConstants.OTP_REQUEST, result } }
    function successUser(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function success(result) { return { type: userConstants.OTP_SUCCESS, result } }
    function failure(error, statusCode) { return { type: userConstants.OTP_FAILURE, error, statusCode } }
}
// function otp(history, otp) {
//     return dispatch => {
//       // Dispatch an action to indicate that an OTP request is being made.
//       dispatch(request({ otp }));

//       // Extract the user object from the history location state.
//       const user = history.location.state;

//       // Call a service (likely an API service) to verify the OTP.
//       userService.otp(user.email, otp)
//         .then(
//           response => {
//             // Check the status code in the API response.
//             const status = response.message;

//             if (response.ok) {
//               // If the OTP verification is successful, dispatch these actions:
//               // 1. Dispatch a successUser action with the user data.
//               // 2. Dispatch a success action with the result data and status code.
//               // 3. Redirect the user to the homepage ('/') using the history object.
//               // 4. Dispatch a success message using the alertActions.

//               dispatch(successUser(user));
//               dispatch(success(response, status)); // Pass the response and status code.
//               history.push('/');
//               dispatch(alertActions.success("Successfully Logged In"));
//             } else {
//               // If there's an error during OTP verification, dispatch these actions:
//               // 1. Dispatch a failure action with the error message and status code.
//               // 2. Dispatch an error message using the alertActions.
//               dispatch(failure(response.statusText, status)); // Pass the error message and status code.
//               dispatch(alertActions.error(response.statusText));
//             }
//           })
//         .catch(error => {
//           // Handle any other errors that occur during the API call.
//           dispatch(failure(error.toString()));
//           dispatch(alertActions.error(error.toString()));
//         });
//     };

//     // These are nested functions used to create Redux actions.
//     // They return objects with a 'type' property indicating the type of action
//     // and optionally, a 'result', 'user', or 'error' property containing relevant data.
//     function request(result) { return { type: userConstants.OTP_REQUEST, result } }
//     function successUser(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
//     function success(result, status) { return { type: userConstants.OTP_SUCCESS, result, status } }
//     function failure(error, status) { return { type: userConstants.OTP_FAILURE, error, status } }
//   }


function register(history, user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/signin');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}
