import  React  from "react";
import ContentTable from "../../components/Tables/ContentTable";
import TabItems from "../../components/TabItems/TabItems";
import PageHead from "../../components/PageHead/PageHead";
import {apiService} from '../../services/api_service'
import { Modal, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import { SortArray } from "../../constants";



class EpiQuestionnaires extends React.Component{

    constructor(props){
       super(props)
       this.state = {
           tabs:{},
           loader:false,
           users_list:[],
           questions:[],
           tabs:[],
           practitioner_id: "",
           email: "",
           category:'Health',
           all_question :[],
           show: false,
           modal: {},
           option_count: 1,
           formError: {},           
           radiostate: "bool",
           category_list: [
               {"name":"Health", "value" :"health"},
               {"name":"Exercise", "value" :"exercise"},
               {"name":"Diet", "value" :"diet"},
               {"name":"Vitamins Supplements", "value" :"vitamins_supplements"},
               {"name":"Lifestyle & Environment", "value" :"lifestyle_&_environment"},
               {"name":"Medications", "value" :"medications"},
           ],
           message: "Please select patients from dropdown",
           clearUser: false,
           practitioner_details:[],
       }
    }



    async componentWillReceiveProps(nextProps){
        if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
          this.setState({ loader: true });
          try {
            let practitioner_details = nextProps.user;
            let practitioner_id = btoa(nextProps.user._id);
            // let users_list = await apiService.AccessUsersList(
            //   practitioner_id
            // );
           
            this.setState({
              //users_list,
              practitioner_details,
              loader: false,
              practitioner_id,
              ...practitioner_details
            });
          } catch (error) {
            console.log("error", error);
          }
        }

    }    
    
    async GetQuestions(user_id, type) {
        if(!this.props.user?._id || !user_id || !type) return
        try {
            const practitioner_id = btoa(this.props.user?._id)
            this.setState({ loader: true })
            if (user_id == 'select') {
                this.setState({ loader: false, question: [], all_question: [], tabs: [], message: "Please select patients from dropdown" })
                return
                
            }
            if (type == 'EPI') {
                let questions = await apiService.questionnaireAnswer(practitioner_id, user_id, type)
                if (!questions.success && questions.message == 'user not found') {
                    this.setState({ clearUser: true, loader: false })
                    return;
                } else {
                    questions = questions.data
                    this.setState({ clearUser: false })
                }
                if (questions && questions.length > 0) {

                    let tab_item = []
                    await questions.map(question => {
                        tab_item.push(question.category)

                    })
                    let cat_question = questions.find(e => e.category == 'Health')
                    this.setState({
                        tabs: tab_item.reverse(),
                        question: cat_question,
                        all_question: questions,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false, user_id, question: [], all_question: [], tabs: [], message: "No Questionnaire answers found for the user" })
                }
            }
        } catch (error) {
            console.log("error", error)
        }
    }
   async changeCategory(category){
        let cat_question = this.state.all_question.find(e=>e._id== category)

        this.setState({
            question:cat_question,
            category:category,
            loader:false
        })
    }

    async toggleModal() {
        console.log("Hide modal")
        this.setState({ show: !this.state.show, modal: {}, option_count: 1,  radiostate: "bool"})
    }

    async handleAdd(){
        let formValid = this.validateForm()
        if(!formValid) {
            return;
        }
        this.setState({[`modal${'radio'}`]: this.state.radiostate});
        console.log("Handle add api", this.state.modal)
        let type =  "epi_question_1";
        let data = {
            "category": this.state.modal.category,
            "text": this.state.modal.text,
            "key": this.state.modal.key,
            "description": this.state.modal.description,
            "radio": this.state.radiostate
        }
        if(this.state.radiostate == "options") {
            console.log("options selected")
            data.options = this.getMCQOptions(this.state.modal, "option");
        }
        console.log("api data", data)
        try {
            await apiService.createQuestionnaire(this.state.practitioner_id, type, data);
            this.setState({show: false});
        } catch (error) {
            alert(error);          
        }
    }

    validateForm() {
        const { modal, radiostate, option_count } = this.state;
        console.log(modal);

        let valid = true, newState = { formError: {} }
        if (!modal.category) {
            valid = false;
            newState.formError.category = true;
        }
        if (!modal.key) {
            valid = false;
            newState.formError.key = true;
        }
        if (!modal.text) {
            valid = false;
            newState.formError.text = true;
        }
        if (!['bool', 'options'].includes(radiostate)) {
            valid = false;
            newState.formError.type = true;
        }
        if (radiostate == "options") {
            if(option_count < 2) {
                valid = false;
                newState.formError.optionCount = true;
            } else {
                for(let i = 0; i < option_count; i++) {
                    console.log(`${i} value`, modal[`option${i}`]);
                    if (!modal[`option${i}`]) {
                        valid = false;
                        newState.formError[`option${i}`] = true;
                    } else {
                        newState.formError[`option${i}`] = false;
                    }
                }
            }
        }

        this.setState(newState);
        return valid;
    }

    getMCQOptions(object, prefix) {
        let options = [];
        Object.keys(object).map(item => {
            if (item.toString().startsWith(prefix))
                options.push(object[item]);
        });
        console.log("options", options);
        return options;
    }
    async handleChange(e){
        let name = e.target.name, value= e.target.value
        let newState = {modal: this.state.modal, formError: this.state.formError};
        newState.modal[name] = value;
        newState.formError[name] = false;
        // console.log("handlechange", name, value, newState); 
        this.setState(newState);
        // console.log("state", this.state.modal);
    }

    render(){
        const { loader, tabs, users_list, question, category, modal, 
            option_count, formError, message, clearUser,practitioner_details } = this.state;
        return (
            <>
                
                <div className="questionnaires-wrp">
                    <PageHead title="Epigenetic Questionnaires"
                        UserList={users_list}
                        DetailsGetAPI={(user_id)=> this.GetQuestions(user_id, "EPI")}
                        page={'EPI'}
                        clearUser={clearUser}
                        showButton={practitioner_details.type == "admin"? true : false}
                        buttonTitle={"Epigenetic Question"}
                        handleAdd={this.toggleModal.bind(this)}
                    >
                    </PageHead>
                    <TabItems tab={tabs} categorySelector={this.changeCategory.bind(this)} category={category} ></TabItems>
                    <div className="tabContent">
                        <div className="qstnsTable">
                            <ContentTable loader={loader} questions={question} category={category} message={message}></ContentTable>
                        </div>
                    </div>
                    
                    <Modal show={this.state.show} onHide={() => this.toggleModal()} centered className="trackingModal" size="sm" >
                        <Modal.Header closeButton>
                            <Modal.Title>Add Epigenetic Question</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="user-management-modal">
                            <Form>
                                <Form.Group className="sign-field">
                                    <Form.Label>Category</Form.Label>
                                    {/* <Form.Control placeholder="Enter Category" type="text" name="category" value={modal.category} onChange={(e)=>this.handleChange(e)} required /> */}
                                    <Form.Control as="select" name ='category' value={modal.category} className="multiselect-patients" onChange={(e) => this.handleChange(e)}>
                                        <option key={`option-0`} value =''>--Select category--</option>
                                        {
                                            this.state.category_list && 
                                            SortArray(this.state.category_list,"name").map((items) => (
                                                <option key={`option-${items.value}`} value ={items.value}>{items.name}</option>
                                            ))

                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        {formError.category ? "Please Select Category": null}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>Key</Form.Label>
                                    <Form.Control placeholder="Enter Key" type="text" name="key" value={modal.key} onChange={(e)=>this.handleChange(e)} required />
                                    <Form.Text className="text-muted">
                                        {formError.key ? "Please Enter Key": null}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control placeholder="Enter Text" type="text" name="text" value={modal.text} onChange={(e)=>this.handleChange(e)} required />
                                    <Form.Text className="text-muted">
                                        {formError.text ? "Please Enter Question Text": null}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control placeholder="Enter Description" as="textarea" type="textarea" rows={3} name="description" value={modal.description} onChange={(e)=>this.handleChange(e)} style={{ height: '100px' }} required />
                                </Form.Group>
                                <Form.Label>Question Type</Form.Label>
                                <Form.Group controlId="formBasicCheckbox" className="customCheckbox">
                                    <input checked={this.state.radiostate == "bool"} onChange={() => this.setState({radiostate: "bool"})}  type="radio" name="radio" id="boolean" label="Boolean" />{' '}
                                    <Form.Label> Y/N </Form.Label>
                                    <br />
                                    <input checked={this.state.radiostate == "options"} onChange={() => this.setState({radiostate: "options"})} type="radio" name="radio" id="options" label="Options" />{' '}
                                    <Form.Label> Options </Form.Label>
                                    <Form.Text className="text-muted">
                                        {formError.type ? "Please Select Question Type": null}
                                    </Form.Text>
                                </Form.Group>
                                {
                                    this.state.radiostate == "options"?
                                    <>
                                    {
                                        [ ...Array(option_count>=0?option_count: 1).keys() ].map((num) => (
                                            <Form.Group key={`ans-${num}`} className="sign-field">
                                                <Form.Label>Option {num+1}</Form.Label>
                                                <Form.Control placeholder={`Enter Option ${num+1}`} type="text" name={`option${num}`} value={modal[`option-${num}`]} onChange={(e)=>this.handleChange(e)} required />
                                                <Form.Text className="text-muted">
                                                    {formError[`option${num}`] ? "Please Enter Options": null}
                                                </Form.Text>
                                            </Form.Group>
                                        ))
                                    }
                                    <Form.Text className="text-muted">
                                        {formError.optionCount ? "Please Enter More Than 1 Option": null}
                                    </Form.Text>
                                    <Button variant="primary" onClick={()=> this.setState({ option_count: option_count+1})}>Add Options</Button>{' '}
                                    <Button variant="danger" disabled={option_count <= 1} onClick={()=> this.setState({ option_count: option_count-1})}>Delete Options</Button>
                                    </>
                                    : null
                                }
                                <Form.Group className="sign-field">
                                    <Button className="continuebtn" onClick={()=> this.handleAdd()}>Add</Button>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
    
            </>
        )
    }
}


function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
  }
  
  const actionCreators = {
    success: alertActions.success,
  };
  
  export default connect(mapState, actionCreators)(EpiQuestionnaires);


