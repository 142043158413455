import React from "react";
import moment from 'moment';
import { Table, Modal } from "react-bootstrap";
import ColorIndicator from "../../components/ColorIndicator/ColorIndicator";
import LineGraphs from "../../components/LineGraph/LineGraph";
import PageHead from "../../components/PageHead/PageHead";
import {apiService} from '../../services/api_service'
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../actions";

import {BioAgeIcon,EyeAgeIcon,HearingAgeIcon,MemoryAgeIcon,InflammationAgeIcon,ProInflammationIcon,AntiInflammationIcon }  from "../../components/Icons/EpiReportIcons"
import { getScoreAntiPro } from "../../helpers/getAntiProScore";

class EpigeneticReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: false,
      users_list: [],
      loader: false,
      practitioner_id: this.props.user?.id,
      email: this.props.user?.email,
      epiResult: {},
      message: "Please select patients from the above dropdown",
      key: "",
      ScoreData: {},
      result: {},
      scoreList: [],
      ageAtSample: "",
      ageList: [],
      access: [],
      clearUser: false,
    };
  }
  async componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        this.setState({ loader: true });
        const practitioner_id = btoa(nextProps.user._id);
        //let users_list = await apiService.AccessUsersList(practitioner_id);
        this.setState({
          //users_list,
          loader: false,
          practitioner_id,
          ...nextProps.user,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  indicator(val) {
    switch (val) {
      case "Red":
        return { status: "Unhealthy", indicator: 1, class: "sHealthy" };
      case "Yellow":
        return { status: "Normal", indicator: 3, class: "sUnhealthy" };
      case "Green":
        return { status: "Healthy", indicator: 5, class: "sHealthy" };

      default:
        return { status: "Normal", indicator: 1, class: "sHealthy" };
    }
  }
  ImageSelect(key) {
    switch (key) {
      case "biological_age":
        return <BioAgeIcon/>;
      case "eye_age":
        return <EyeAgeIcon/>;
      case "hearing_age":
        return <HearingAgeIcon/>;
      case "memory_age":
        return <MemoryAgeIcon/>;
      case "inflammation_score":
        return <InflammationAgeIcon/>;
      case "pro_inflammation_score":
        return <ProInflammationIcon/>;
      case "anti_inflammation_score":
        return <AntiInflammationIcon/>;
      default:
        return <BioAgeIcon/>;
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (result) => {
    console.log("result", result);
    let ScoreData = {
      ...result.Data.score_data,
      isInflammation: result.isInflammation,
    };
    this.setState({
      show: true,
      ScoreData,
      scoreList: result.score,
      originalList: result.original_score,
      ageList: result.age_at_sample,
    });
    console.log("ScoreData", ScoreData);
  };

  handleClose1 = () => {
    this.setState({ show1: false });
  };
  handleShow1 = (data) => {
    this.setState({ show1: true, result: data });
  };
  async getResult(user_id) {
    if (!this.props.user?._id || !user_id) return;
    this.setState({ loader: true });
    if (user_id == "select") {
      this.setState({
        loader: false,
        message: "Please select patients from the above dropdown",
        epiResult: [],
        fullResult: [],
        ageAtSample: "",
      });
      return;
    }
    let userId = Buffer.from(String(user_id)).toString("base64");
    let access = this.state.access;
    if (
      this.state.access == undefined ||
      Object.keys(this.state.access).length <= 0
    ) {
      let practitioner_details = this.props.user;
      access = practitioner_details.access_controls.Epigenetic_results;
    }
    const practitioner_id = btoa(this.props.user._id);
    let Epi_result = await apiService.EpigeneticResult(practitioner_id, userId);
    console.log("Epi_result api", Epi_result);
    if (!Epi_result.success && Epi_result.message == "user not found") {
      this.setState({ clearUser: true, loader: false });
      return;
    } else {
      Epi_result = Epi_result.data;
      this.setState({ clearUser: false });
    }
    let Epi_summery;
    if (Epi_result && Epi_result.epi_result) {
      Epi_summery = Epi_result.epi_summery;
      Epi_result.epi_result.forEach((sections, i) => {
        let samples = Object.values(Epi_summery.samples)[i];
        let scores = [],
          original_score = [];
        let age_at_sample = [],
          date_of_scanning = [];
        samples.map((sample) => {
          scores.push(sample.score + sample.age_at_sample);
          // console.log("sample", sample)
          original_score.push(
            sample.original_score ? sample.original_score : 0
          );
          age_at_sample.push(sample.age_at_sample);
          date_of_scanning.push(
            moment(sample.date_of_scanning).format("DD-MM-YYYY")
          );
        });
        sections.score = scores;
        sections.original_score = original_score;
        sections.age_at_sample = age_at_sample;
        sections.date_of_scanning = date_of_scanning;
        sections.isInflammation =
          sections.title.indexOf("Inflammation Score") != -1 ? true : false;
      });
    } else {
      this.setState({
        loader: false,
        message: "Epigenetic result not found for this user",
        epiResult: [],
        fullResult: [],
        ageAtSample: "",
        access,
        ageList: [],
      });
      return;
    }

    this.setState({
      loader: false,
      epiResult: Epi_result.epi_result,
      fullResult: Epi_result,
      ageAtSample: Epi_summery.current.age_at_sample,
      access,
    });
  }

  render() {
    const {
      show,
      show1,
      users_list,
      epiResult,
      message,
      key,
      result,
      ScoreData,
      scoreList,
      originalList,
      ageAtSample,
      access,
      ageList,
      clearUser,
    } = this.state;
    // console.log("EPI result render", epiResult)
    return (
      <>
        <div className="dnaReport-wrp">
          <PageHead
            title="Epigenetic Report"
            UserList={users_list}
            page={"EPI_Report"}
            DetailsGetAPI={this.getResult.bind(this)}
            clearUser={clearUser}
          ></PageHead>

          {/* <SelectionsBar></SelectionsBar> */}
          {/* use this "SelectionsBar" component with below hard coded data */}
          <div className="selectionBar">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-auto">
                <h3>Age at Most Recent Sample: {ageAtSample}</h3>
              </div>
            </div>
          </div>

          <div className="epigeneticReporttable">
            {/* <ContentTable></ContentTable> */}
            {/* use this "ContentTable" table component with below hard coded table data */}

            <div className="cmnTable">
              {this.state.loader ? (
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="info"
                  style={{
                    marginLeft: "50%",
                    padding: "20px",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                />
              ) : epiResult.length > 0 ? (
                <Table responsive striped>
                  <thead>
                    <tr style={{ color: "white" }}>
                      <th></th>
                      <th>Title</th>
                      <th>Indicator</th>
                      <th>Delta</th>
                      <th>Value</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {epiResult.map((result) => (
                      <>
                        {/* {console.log("Each result", result)} */}
                        {access[
                          result.title.replace(/ |-/g, "_").toLocaleLowerCase()
                        ] ? (
                          <tr>
                            <td>
                              {this.ImageSelect(
                                  result.Data.score_data.key
                                )}
                                
                            </td>
                            <td>{camelize(result.title)}</td>
                            <td>
                              <ColorIndicator
                                num={
                                  this.indicator(result.Data.score_data.color)
                                    .indicator
                                }
                              ></ColorIndicator>
                            </td>
                            <td>
                              {result.isInflammation
                                ? ""
                                : (
                                    result.Data.score_data.score - ageAtSample
                                  ).toFixed(1)}
                            </td>
                            <td>{result.Data.score_data.score}</td>
                            <td>
                              <span className="sHealthy">
                                {
                                  this.indicator(result.Data.score_data.color)
                                    .status
                                }
                              </span>
                            </td>
                            <td>
                              <a
                                onClick={() => this.handleShow(result)}
                                style={{ cursor: "pointer" }}
                              >
                                View Graph{" "}
                              </a>{" "}
                              <a
                                className="ml-3"
                                onClick={() => this.handleShow1(result)}
                                style={{ cursor: "pointer" }}
                              >
                                View Result{" "}
                              </a>
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h5 style={{ textAlign: "center", padding: "20px" }}>
                  {message}
                </h5>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={this.handleClose}
          centered
          className="cmnModal epigeneticgraphModal"
        >
          <Modal.Header closeButton>
            {!ScoreData.isInflammation ? (
              <Modal.Title>{`${ScoreData.title} Vs Chronological Age`}</Modal.Title>
            ) : (
              <Modal.Title>{getScoreAntiPro(ScoreData)}</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {show && ScoreData.score && (
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="graphCard">
                    {!ScoreData.isInflammation ? (
                      <LineGraphs
                        data={
                          scoreList.length <= 1 ? [0, scoreList[0]] : scoreList
                        }
                        label_names={
                          ageList.length <= 1 ? [1, ageList[0]] : ageList
                        }
                        xLabel={"Biological Age at Sample"}
                        yLabel={"Age Delta"}
                      ></LineGraphs>
                    ) : (
                      <LineGraphs
                        data={
                          originalList.length <= 1
                            ? [0, ScoreData.score]
                            : originalList
                        }
                        label_names={
                          ageList.length <= 1 ? [1, ageList[0]] : ageList
                        }
                        xLabel={"Age at Sample"}
                        yLabel={ScoreData.title}
                      ></LineGraphs>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="graphCard">
                    <h6>
                      Status :{" "}
                      <span className="shealthy">
                        {this.indicator(ScoreData.color).status}
                      </span>
                    </h6>
                    <h3>{ScoreData.score}</h3>
                    <ColorIndicator
                      num={this.indicator(ScoreData.color).indicator}
                    ></ColorIndicator>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={show1}
          onHide={this.handleClose1}
          centered
          className="cmnModal dnareportModal"
        >
          {show1 && result.title && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  {`${camelize(result.title)} Summary`}{" "}
                  <span className="risktype" style={{ color: "#fc1504" }}>
                    {this.indicator(result.Data.score_data.color).status}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h6>Result</h6>
                <div
                  dangerouslySetInnerHTML={{ __html: result.Data.outcome }}
                ></div>
                <hr></hr>
                <h6>Recommendations</h6>
                {result.Data.recommendations_info.map((recommendation) => (
                  <div>
                    <h6>{recommendation.title}</h6>
                    <div
                      dangerouslySetInnerHTML={{ __html: recommendation.text }}
                      className="modalBody"
                      style={{ fontSize: "13px" }}
                    ></div>
                  </div>
                ))}
              </Modal.Body>
            </>
          )}
        </Modal>
      </>
    );
  }
}
function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toUpperCase();
    }).replace(/\s+/g, ' ');
  }


  function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
  }

  const actionCreators = {
    success: alertActions.success,
  };

  export default connect(mapState, actionCreators)(EpigeneticReport);  

