import { React } from "react";
import PieChart from "../../components/Graphs/pieChart/pieChart";

const ReportSummary = ({ num, data, summary }) => {
    let line_data = [0]
    let label_names = [""]
    data.map((item) => {
        if (item.value.num !== undefined) {
            line_data.push(item.value.num)
            label_names.push(item.rsid)
        }
        else {
            line_data.push(0)
            label_names.push(item.rsid)
        }
    })

    return (
        <>
            <div className="reportSummary-wrp">
                {/* <div style={{ display:"flex" }}> */}
                <div className="congenital-summary">
                    <div className="left-part">
                        <PieChart summary={summary} num={num} />
                    </div>
                    <div className="right-part">
                        <p className="summary-heading">{summary.title}</p>
                        {summary.summary &&
                            <div className="summary-text" dangerouslySetInnerHTML={{ __html: summary.summary }} ></div>
                        }
                    </div>
                </div>
                <hr />
                <h6>Summary</h6>
                {
                    summary.title ?
                        <p>The individual scores {num}, {summary.total && summary.total.length == 2 ? summary.total[1] : "64"} being the highest risk. This score would indicate a {summary.title} </p>
                        :
                        <p>Out of a potential {summary.total && summary.total.length == 2 ? summary.total[1] : "46"} the individual scores {summary.total && summary.total.length == 2 ? summary.total[0] : "20"}, {summary.total && summary.total.length == 2 ? summary.total[1] : "46"} being the highest risk. This is a generally average outcome with {num} areas of note</p>
                }
                <hr />
                {summary.details &&
                    <div className="disease-risk summary-text" dangerouslySetInnerHTML={{ __html: summary.details }} ></div>
                }
            </div>
        </>
    )
}

export default ReportSummary
