export function getPartnerUrlPrefix(partner_id) {
    switch (partner_id) {
        case 1: // Muhdo
            return "";
        case 7:
            return "/bio-synergy";
        case 48:
            return "/lifetime-health"
        case 50:
            return "/eterna-life";
        case 52:
            return "/biolimitlesslife";
        default:
            return "";
    }
}

export function getPartnerURLs(partner) {
    let privacy, terms, text
    switch (partner) {
        case 1:
            privacy = "https://muhdo.com/privacy-policy/";
            terms = "https://muhdo.com/terms-conditions/";
            text = "Muhdo Health";
            break;
        case 7:
            privacy = "https://www.bio-synergy.uk/privacy-policy/";
            terms = "https://www.bio-synergy.uk/terms-conditions/";
            text = "Bio-Synergy";
            break;
        case 25:
        case 31:
        case 33:
            privacy = "https://muhdo.com/privacy-policy/"
            terms = "https://muhdo.com/terms-conditions/";
            text = "Muhdo Health";
            break;
        case 26:
            privacy = "https://nulifesciences.com/privacy-policy/";
            terms = "https://nulifesciences.com/terms-of-use/";
            text = "Muhdo Health";
            break;
        case 28:
            privacy = "https://www.meandmyhealth.co.uk/privacy-policy/";
            terms = "https://www.meandmyhealth.co.uk/terms-of-use/";
            text = "Muhdo Health";
            break;
        case 13:
            privacy = "https://transformnow.co.uk/privacy-policy/";
            terms = "https://transformnow.co.uk/terms-conditions/";
            text = "Muhdo Health";
            break;
        case 45:
            privacy = "https://promake.com/privacy-policy/";
            terms = "https://promake.com/biopod/terms-and-conditions/";
            text = "Promake";
            break;
        case 46:
            privacy = "https://longevity.technology/privacy/";
            terms = "https://longevity.technology/terms-and-conditions/";
            text = "Longevity Technology";
            break;
        case 48:
            privacy = "https://lifetime-health.com/Datenschutz/";
            terms = "https://lifetime-health.com/allgemeine_geschaeftsbedingungen/";
            text = "Lifetime Health";
            break;
        case 11:
            privacy = "https://formulahealth.co.uk/dna-privacy-policy/";
            terms = "https://formulahealth.co.uk/dna-tscs/";
            text = "Formula Health";
            break;
        case 15:
            privacy = "https://muhdo.com/privacy-policy/"
            terms = "https://muhdo.com/terms-conditions/";
            text = "Bodyology";
            break;
        case 50:
            privacy = '#';
            terms = '#';
            text = "Eterna Life";
            break;
        case 51:
            privacy = 'https://bevivo.com/pages/faq';
            terms = 'https://bevivo.com/pages/terms';
            text = "Bevivo";
            break;
        case 52:
            privacy = 'https://biolimitlesslife.com/privacy';
            terms = 'https://biolimitlesslife.com/terms-conditions';
            text = "Biolimitless Life";
            break;
        default:
            privacy = "https://muhdo.com/privacy-policy/";
            terms = "https://muhdo.com/terms-conditions/";
            text = "Muhdo Health";
            break;
    }
    return { privacy, terms, text }
}

export function getBackgroundImage(partner) {
    switch (partner) {
        case 7:
            return "url(/images/biosynergyauth.jpg)"
        case 50:
            return "url(/images/eternaLifeAuth.jpeg)"
        case 48:
            return "url(/images/lifeTimeAuth.svg)"
        case 52:
            return "url(/images/bioLimitlessAuth.png)"
        default:
            return "url(/images/authbg2.png)"
    }
}

export function getPartnerColour(partner_id) {
    switch (partner_id) {
        case 1: // Muhdo
            return '#09A79E';
        case 7: // Bio-Synergy
            return '#000000';
        // Healthcare partners
        case 25:
        case 31:
        case 33:
            return '#c38adb';
        case 26: // TruEpi
            return '#398cb2';
        // Me and My Health
        case 28:
            return '#00CBB2';
        // Transform Now
        case 13:
            return '#17629e';
        // Promake
        case 45:
            return '#080B37';
        // Longevity Technology
        case 46:
            return '#2c2c2c';
        // Lifetime
        case 48:
            return '#65C0B6';
        // Formula Health
        case 11:
            return '#76D3CA';
        // Bodyology
        case 15:
            return '#243144';
        // Eterna Life
        case 50:
            return '#05aaff';
        // Bevivo
        case 51:
            return '#B34944';
        // Bio Limitless Life
        case 52:
            return '#6037d9';
        default:
            return '#09A79E';
    }
}

export function getPartnerGraphColour(partner_id) {
    switch (partner_id) {
        case 1: // Muhdo
            return '#09A79E';
        case 7: // Bio-Synergy
            return '#000000';
        // Healthcare partners
        case 25:
        case 31:
        case 33:
            return '#6e2b8b';
        case 26: // TruEpi
            return '#398cb2';
        // Me and My Health
        case 28:
            return '#00CBB2';
        // Transform Now
        case 13:
            return '#17629e';
        // Promake
        case 45:
            return '#000000';
        // Longevity Technology
        case 46:
            return '#2c2c2c';
        // Lifetime
        case 48:
            return '#09A79E';
        // Formula Health
        case 11:
            return '#65C0B6';
        // Bodyology
        case 15:
            return '#243144';
        // Eterna Life
        case 50:
            return '#05aaff';
        // Bevivo
        case 51:
            return '#B34944';
        // Bio Limitless Life
        case 52:
            return '#6037d9';
        default:
            return '#09A79E';
    }
}

export function getPartnerSecondaryColour(partner_id) {
    switch (partner_id) {
        case 1: // Muhdo
            return '#09a79e69';
        case 7: // Bio-Synergy
            return '#84a864';
        // Healthcare partners
        case 25:
        case 31:
        case 33:
            return '#84a864';
        case 26: // TruEpi
            return '#84a864';
        // Me and My Health
        case 28:
            return '#84a864';
        // Transform Now
        case 13:
            return '#84a864';
        // Promake
        case 45:
            return '#636ce9';
        // Longevity Technology
        case 46:
            return '#8f96ef';
        // Lifetime
        case 48:
            return '#a4dad4';
        // Formula Health
        case 11:
            return '#c5ece8';
        // Bodyology
        case 15:
            return '#bcc9dc';
        // Eterna Life
        case 50:
            return '#b3e6ff';
        // Bevivo
        case 51:
            return '#f8eded';
        // Bio Limitless Life
        case 52:
            return '#6037d9';
        default:
            return '#09a79e69';
    }
}

export function IsExampleReportViewable(partner_id) {
    switch (partner_id) {
        case 50:
        case 51:
        case 52:
            return false;
        default:
            return true;
    }
}
