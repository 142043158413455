import React from "react";
import PieChart from "../../components/Graphs/pieChart/pieChart";

const OverviewDetails = ({ overview, tabs, categorySelector }) => {
    // Function to split the overview array into chunks of 4
    const chunkArray = (array, size) => {
        let result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    // Splitting the overview array into chunks of 4
    const overviewChunks = chunkArray(overview, 4);

    return (
        <>
            <div className="reportDetail-wrp">
                {overviewChunks.map((chunk, chunkIndex) => (
                    <div key={chunkIndex} style={{ display: "flex", justifyContent: "space-around", marginBottom: "20px" }}>
                        {chunk.map((items, index) => (
                            // Using a combination of chunkIndex and index to create a unique key
                            <div key={`chunk-${chunkIndex}-item-${index}`} style={{ display: "flex", flexDirection: "column", width: "calc(100%/4)" }} onClick={() => categorySelector(tabs[chunkIndex * 4 + index])}>
                                <h5 style={{ fontSize: 15, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingBottom: "15px", cursor: "pointer" }}>{tabs[chunkIndex * 4 + index].title}</h5>
                                <PieChart className="pie-chart-overview" summary={items} num={items.score} />
                                <p style={{ fontSize: 15, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingTop: "15px", cursor: "pointer" }}>{items.title}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
};

export default OverviewDetails;
