import { React } from "react";
import { Dropdown } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";


const FilerButton = (props) => {
    return (
        <>
            <Dropdown className="customDropdown">
                <Dropdown.Toggle>
                    <figure>
                        <button className="filterBtn"><FaFilter size={15}></FaFilter>     Filter</button>
                    </figure>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {props.types && props.types.map(type=>(
                    <Dropdown.Item key={type.value} onClick={()=>props.updateList(type.value)}>{type.label}</Dropdown.Item>
                    ))}

                </Dropdown.Menu>
            </Dropdown>
        </>
    )

}

export default FilerButton