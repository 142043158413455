import React from "react";
import PageHead from "../../components/PageHead/PageHead";
import TabItems from "../../components/TabItems/TabItems_old";
import OverviewCard from "../../components/OverviewCard/OverviewCard";

import BarGraph from "../../components/Graphs/BarGragh";
import CurvedLineGraph from "../../components/Graphs/CurvedLineGraph";
import SelectionsBar from "../../components/SelectionsBar/SelectionsBar";


class OverviewDetails extends React.Component{
    constructor(props){
        super()
        this.state={
            tab:['Overview', 'Steps', 'Sleep', 'Active Time', 'Heart Rate', 'Water Intake', 'Calorie Intake']

        }
    }

    render(){
        const {tab ,view} = this.state
        const{details,lifestyleDetails,DetailsGetAPI,start_date,end_date,type} = this.props
        return (
            <>
                    
                    {/* <SelectionsBar></SelectionsBar> */}
                    {/* use this "SelectionsBar" component with below hard coded data */}
                    <div className="overviewdetails-wrp">
                        <div className="row">
                            <div className="col-12 col-xl-3">
                                <OverviewCard
                                  details={details}
                                ></OverviewCard>
                            </div>
                            <div className="col-12 col-xl-9">
                                {details.graph_type === 'bar' ?
                                <BarGraph
                                data={details.list}
                                xAxis={details.xAxis}
                                labels={lifestyleDetails.date}
                                DetailsGetAPI = {DetailsGetAPI}
                                start_date={start_date}
                                end_date={end_date}
                                selected_type={type}
                                ></BarGraph> :
                                <CurvedLineGraph
                                d1={details.list}
                                d2={details.list2}
                                d3={details.list3}
                                xAxis={details.xAxis}
                                type={details.Title}
                                labels={lifestyleDetails.date}
                                DetailsGetAPI = {DetailsGetAPI}
                                start_date={start_date}
                                end_date={end_date}
                                selected_type={type}
                                ></CurvedLineGraph>
                            }
                                
                                {/* <CurvedLineGraph></CurvedLineGraph> */}
                            </div>
                         </div>
                    </div>
            </>
        )
    }
}

export default OverviewDetails