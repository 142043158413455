import React from "react";

const HorizontalBrainAgeIndicator = ({ value }) => {
  const ColorSelector = (score) => {
    const normalizedScore = (score / 800) * 100; // Convert score to a range out of 100

    if (normalizedScore <= 25) return "#F24744"; // 0-200 in 800 range is 0-25 in 100 range
    if (normalizedScore > 25 && normalizedScore <= 50) return "#E7ED3A"; // 201-400 in 800 range is 26-50 in 100 range
    if (normalizedScore > 50) return "#9EDB30"; // 401-800 in 800 range is 51-100 in 100 range
  };

  const getColor = () => {
    return ColorSelector(value);
  };

  const progressStyle = {
    width: `${(value / 800) * 100}%`,
    backgroundColor: getColor(),
  };

  return (
    <div className="progress-bar">
      <div className="progress" style={progressStyle}></div>
    </div>
  );
};

export default HorizontalBrainAgeIndicator;
