import React from 'react';
import { Line } from 'react-chartjs-2';
import GraphFilter from "./GraphFilter/GraphFilter";




const CurvedLineGraph = ({d1,d2,d3,labels,xAxis,type,DetailsGetAPI,start_date,end_date,selected_type}) => {
    if(d1 ===undefined || d2 ===undefined){
        d1=[50, 200, 600, 150, 100, 80, 200, 300, 150, 100, 80]
        d2=[10, 50, 500, 25, 300, 400, 500, 300]
        d3=[500, 200, 400, 150, 150, 80, 200, 30, 10, 130, 180]
    }
    if(labels ===undefined){
        labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }

    let data2 = {
        labels: labels,
        datasets: [
            {
                label: 'Line 1',
                data: d2,
                backgroundColor: '#09a79e0d',
                fill: true,
                borderColor: '#09A79E',
                pointBackgroundColor: '#09A79E',
                pointHoverBackgroundColor: '#09A79E',
                pointHoverBorderColor: '#09A79E',
                
            },
            {
                label: 'Line 2',
                data: d3,
                backgroundColor: '#fc150408',
                fill: true,
                borderColor: '#FC1504',
                pointBackgroundColor: '#FC1504',
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#FC1504',
                pointHoverBorderColor: '#FC1504',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 5,
                // lineTension: 0.4,     
            },
            {
                label: 'Line 3',
                data: d1,
                backgroundColor: '#FF7700',
                fill: false,
                borderColor: '#FF7700',
                pointBackgroundColor: '#FF7700',
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#FF7700',
                pointHoverBorderColor: '#FF7700',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 5,
                // lineTension: 0.4,     
            },
        ],
    }
    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 14
                    }
                },
                title: {
                    color: '1E1E1E',
                    display: true,
                    text: xAxis
                }
    
            },
            x: {
                title: {
                    color: '1E1E1E',
                    display: true,
                    text: xAxis
                }
            }
        },
        elements: {
            line: {
                tension: .4 // disables bezier curves
            },
        },
    };

    return(
        <>
        <div className='graph-wrp'>

            <GraphFilter
            type='line'
            label1={type =='Heart Rate' ? 'Average Heart Rate' : 'Running Time'}
            label2={type =='Heart Rate' ?'Minimum Heart Rate' : 'Weighting TIme'}
            label3={type =='Heart Rate' ? 'Maximum Heart Rate': 'Walking Time'}
            DetailsGetAPI={DetailsGetAPI}
            start_date={start_date}
            end_date={end_date}
            selected_type={selected_type}
            ></GraphFilter>
            <div className="barchart-wrp cmnchart-wrp">

                <Line
                    data={data2}
                    options={options}
                />
            </div>

        </div>
      </>
    )
   
}

export default CurvedLineGraph;