import React,{ useState, useEffect } from "react";
import {BsCaretLeftFill, BsCaretRightFill} from "react-icons/bs"
import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    let [current, setCurrent] = useState({"selected": props.skip})

    // useEffect(() =>{
    //     let select = {"selected": props.skip}
    //     setCurrent(select)
    //     props.paginationClick(props.skip) 
    // }, [props.skip])

    function handleChange (selected) {
        let select = {"selected": selected}
        setCurrent(select)
        props.paginationClick(selected)       
    }
    return (
      <>
      {
        props.pages.length > 0 &&
        <div className="pagination-wrp">
            <ReactPaginate
                previousLabel={<><span><BsCaretLeftFill/>Previous</span></>}
                nextLabel={<><span>Next <BsCaretRightFill/></span></>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={props.pages.length}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                forcePage={props.skip}
                selected={current}
                // onPageChange={(page) => props.paginationClick(page.selected)}
                onPageChange={(page) => handleChange(page.selected)}
                containerClassName={'pagination'}
                activeClassName={'active'}
                // disabledClassName={"previous"}
                />
            {/* {
                props.skip+1 < props.pages.length &&
                <button className="pagination-next" title="next" onClick={()=> {
                    let skip = props.skip+1
                    if(skip < props.pages.length)
                        props.paginationClick(props.skip+1)
                    }}><span>Next</span><BsCaretRightFill/></button>
            } */}
             
            
        </div>
      }
     
      </>
    );
};

export default Pagination;
