/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Table, Modal } from "react-bootstrap";
import LineGraphs from "../../components/LineGraph/LineGraph";
import PageHead from "../../components/PageHead/PageHead";
import { apiService } from "../../services/api_service";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import HorizontalBrainAgeIndicator from "../../components/HorizontalBrainAgeIndicator";
import CommonTableHead from "./CommonTableHead";
import SelectionsBar from "../../components/SelectionsBar/SelectionsBar";
import { brainAgeType } from "../../constants";

function CustomTable({ fullResult, handleShow, handleShow1 }) {
  const [org, setOrg] = useState([]);
  const [result, setResult] = useState([]);

  useEffect(() => {
    console.log(fullResult);
    let sortedData = [...fullResult]; // Create a new array
    sortedData = sortedData.sort((a, b) => b["recent"] - a["recent"]);

    setOrg(sortedData || []);
    setResult(sortedData || []);
  }, [fullResult]);

  const tableBodyContentMemo = useMemo(() => {
    return result?.map((res) =>
      res?.description?.key ? (
        <>
          <tr>
            <td>
              <img
                src={res?.description?.images?.transparentIcon}
                className="filterit"
                style={{
                  width: "40px",
                  height: "40px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  cursor: "pointer",
                  background: "#09a79e",
                  borderRadius: "50%",
                  padding: "4px",
                }}
              ></img>
            </td>
            <td>
              {res?.description?.title
                ? camelize(res?.description?.title)
                : "N.A"}
            </td>

            <td>
              <HorizontalBrainAgeIndicator value={res?.recent} />
            </td>
            <td className="txt-center">{res?.recent || "-"}</td>
            <td className="txt-center">{res?.previous || "-"}</td>
            <td className="txt-center">{res?.average?.toFixed(1) || "-"}</td>
            <td>
              <a onClick={() => handleShow(res)} style={{ cursor: "pointer" }}>
                View Graph
              </a>
              <a
                className="ml-3"
                onClick={() => handleShow1(res)}
                style={{ cursor: "pointer" }}
              >
                View Result
              </a>
            </td>
          </tr>
        </>
      ) : null
    );
  }, [handleShow, handleShow1, result]);

  return (
    <Table responsive striped>
      <thead>
        <tr style={{ color: "white" }}>
          <th></th>
          <th>Title</th>
          <CommonTableHead {...{ org, setResult }} />
          <th>Action</th>
        </tr>
      </thead>
      <tbody>{tableBodyContentMemo}</tbody>
    </Table>
  );
}

class CognitiveSkills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: false,
      users_list: [],
      loader: false,
      practitioner_id: this.props.user?.id,
      email: this.props.user?.email,
      message: "Please select patients from the above dropdown",
      ScoreData: {},
      result: {},
      fullResult: [],
      scoreList: [],
      access: [],
      clearUser: false,
      scoreCreatedateList: [],
      selectedItemBrainAgeType: brainAgeType[0],
      userId: "",
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose1 = this.handleClose1.bind(this);
    this.handleShow1 = this.handleShow1.bind(this);
    this.brainAgeApi = this.brainAgeApi.bind(this);
  }
  async componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        this.setState({ loader: true });
        const practitioner_id = btoa(nextProps.user._id);
       /// let users_list = await apiService.AccessUsersList(practitioner_id);
        this.setState({
         // users_list,
          loader: false,
          practitioner_id,
          ...nextProps.user,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (result) => {
    console.log("result", result);

    const graph = result.graph.filter((g) => g?.value && g.value !== 0);

    graph.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    let formattedDatesAsc = graph.map((item) =>
      moment(item.createdAt).format("DD-MM-YYYY")
    );

    this.setState({
      show: true,
      result,
      scoreList: graph.map((g) => g?.value),
      scoreCreatedateList: formattedDatesAsc,
    });
  };

  handleClose1 = () => {
    this.setState({ show1: false });
  };
  handleShow1 = (data) => {
    this.setState({ show1: true, result: data });
  };
  async getResult(user_id) {
    if (!this.props.user?._id || !user_id) return;
    this.setState({ loader: true });

    await this.brainAgeApi(user_id, this?.state?.selectedItemBrainAgeType);
  }

  async brainAgeApi(user_id, selectedItemBrainAgeType) {
    if (user_id === "select") {
      this.setState({
        loader: false,
        message: "Please select patients from the above dropdown",
        fullResult: [],
      });
      return;
    }

    let userId = Buffer.from(String(user_id)).toString("base64");
    let access = this.state.access;

    if (
      this.state.access === undefined ||
      Object.keys(this.state.access).length <= 0
    ) {
      let practitioner_details = this.props.user;
      access = practitioner_details.access_controls.facial_analysis;
    }

    let result = await apiService.getCognitiveSkillsResult(userId, {
      type: selectedItemBrainAgeType?.text || "All",
    });

    if (
      !result ||
      !result?.data ||
      !result?.success ||
      result?.message === "user not found"
    ) {
      this.setState({
        userId,
        loader: false,
        message: "No results available",
        fullResult: {},
        access,
        clearUser: true,
      });
      return;
    }

    this.setState({
      userId,
      message: "",
      loader: false,
      fullResult: result?.data,
      access,
    });
  }

  async selectedItemResult(...arg) {
    if (!this.state.userId) return;
    const selectedItemBrainAgeType = arg[1];
    this.setState({ selectedItemBrainAgeType });
    await this.brainAgeApi(atob(this.state.userId), selectedItemBrainAgeType);
  }

  render() {
    const {
      show,
      show1,
      users_list,
      message,
      result,
      scoreList,
      clearUser,
      fullResult,
      scoreCreatedateList,
    } = this.state;

    return (
      <>
        <div className="dnaReport-wrp">
          <PageHead
            title="Cognitive Skills Report"
            UserList={users_list}
            page={"Cognitive_Skills_Report"}
            DetailsGetAPI={this.getResult.bind(this)}
            clearUser={clearUser}
            onUserClear={() => {
              this.setState({
                userId: "",
                selectedItemBrainAgeType: brainAgeType[0],
              });
            }}
          ></PageHead>

          <SelectionsBar
            value={
              this.state.userId
                ? this?.state?.selectedItemBrainAgeType || brainAgeType[0]
                : null
            }
            list={this.state.userId ? brainAgeType : []}
            title={<h3>Age at Most Recent Sample: {fullResult?.real_age}</h3>}
            changeItem={this.selectedItemResult.bind(this)}
          ></SelectionsBar>

          <div className="epigeneticReporttable">
            <div className="cmnTable">
              {this.state.loader ? (
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="info"
                  style={{
                    marginLeft: "50%",
                    padding: "20px",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                />
              ) : message ? (
                <h5 style={{ textAlign: "center", padding: "20px" }}>
                  {message}
                </h5>
              ) : fullResult ? (
                <CustomTable
                  fullResult={Object.values(fullResult?.result?.skills || {})}
                  message={message}
                  handleShow1={this.handleShow1}
                  handleShow={this.handleShow}
                />
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          show={show && result?.description?.title}
          onHide={this.handleClose}
          centered
          className="cmnModal epigeneticgraphModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{result?.description?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="graphCard">
                  <LineGraphs
                    data={
                      scoreList.length <= 1
                        ? [0, scoreList[0]]
                        : scoreList.length > 14
                        ? scoreList.slice(0, 14)
                        : scoreList
                    }
                    label_names={
                      scoreCreatedateList.length <= 1
                        ? [1, scoreCreatedateList[0]]
                        : scoreCreatedateList.length > 14
                        ? scoreCreatedateList.slice(0, 14)
                        : scoreCreatedateList
                    }
                    xLabel={"Report Date"}
                    yLabel={"Scores"}
                    roundOffHundred={true}
                  ></LineGraphs>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show1 && result?.description?.title}
          onHide={this.handleClose1}
          centered
          className="cmnModal dnareportModal"
        >
          <>
            <Modal.Header closeButton>
              <Modal.Title>{result?.description?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: result?.description?.description,
                  }}
                ></div>
              </div>
            </Modal.Body>
          </>
        </Modal>
      </>
    );
  }
}
function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toUpperCase();
    })
    .replace(/\s+/g, " ");
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
};

export default connect(mapState, actionCreators)(CognitiveSkills);
