import React from "react";

const HorizontalFaceAgeIndicator = ({ aspect, value, age }) => {
  const isAge = (aspect) => {
    switch (aspect) {
      case "age":
      case "eyes_age":
        return true;
      default:
        return false;
    }
  };
  const ColorSelector = (range) => {
    if (range <= 35) return "#F24744";
    if (range > 35 && range <= 70) return "#E7ED3A";
    if (range > 70) return "#9EDB30";
  };
  const AgeColorSelector = (age, real_age) => {
    let ageDiff = age - real_age;
    if (ageDiff > 2) return "#F24744";
    if (ageDiff >= 0 && ageDiff <= 2) return "#E7ED3A";
    if (ageDiff < 0) return "#9EDB30";
  };
  const SkinToneColorSelector = (range) => {
    if (range > 55) return "#fff4eb";
    else if (range >= 41 && range < 55) return "#ffe7d1";
    else if (range >= 28 && range < 41) return "#e6bc98";
    else if (range >= 11 && range < 28) return "#d4aa78";
    else if (range >= -30 && range < 11) return "#a16e4b";
    // else if (range >= -50 && range < -30) return '#3b2219'
    else if (range < -30) return "#3b2219";
  };

  const isSkin = (aspect) => {
    if (aspect == "skin_tone") return true;
    return false;
  };

  const getColor = () => {
    // if (value <= 35) return "#F24744";
    // if (value > 35 && value <= 70) return "#E7ED3A";
    // if (value > 70) return "#9EDB30";

    return isAge(aspect)
      ? AgeColorSelector(value, age)
      : isSkin(aspect)
        ? SkinToneColorSelector(value)
        : ColorSelector(value)
  };

  const progressStyle = {
    width: `${value}%`,
    backgroundColor: getColor(),
  };

  return (
    <div className="progress-bar">
      <div className="progress" style={progressStyle}></div>
    </div>
  );
};

export default HorizontalFaceAgeIndicator;
