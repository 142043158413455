import React from "react";
import moment from 'moment';
import { Table, Modal, Button, Spinner, Form } from "react-bootstrap";
import { apiService } from '../../services/api_service'
import { alertActions } from '../../actions/alert-action';
import { connect } from 'react-redux';
import { BsEye } from "react-icons/bs";
class KitsTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            show1: false,
            kit_id: '',
            trackingHistory: [],
            history_width: '0%',
            request_status: 'Request Access',
            open: true,
            loading: false,
            childLoader: false,
            show2: false,
            edit1: false,
            edit_user: {},
            showAlert: false,
            submitted: false,
            confirm_delete: false,
            released_user_id: {},
            release_dna: false,
            release_epi: false,
            release_dnaraw: false,
            check_error: false,
            check_user_error: false,
            release_loading: false,
            confirm_permission: false,
            extra: 0,
            user: [],
            departments: [],
            clinics: [],
            practitioner_email: false,
            user_mail: true,
            confirm_app_access: false,
            // appAccessPartners: [],
            add_child_kit: false,
            child_kit: "",
            kitErrorMsg: "",
            error: { child_kit: false },
            relation: []
        }
    }

    async componentDidMount() {
        // let appPartners = await apiService.getAppAllowedPartners();
        // let appAccessPartners = appPartners.map(p => p.partner_id);
        // this.setState({ appAccessPartners })
    }

    handleClose = () => this.setState({ show: false })

    handleOpen = () => {
        let error = false;
        if (!this.state.release_dna && !this.state.release_epi && !this.state.release_dnaraw) {
            this.setState({ check_error: true });
            error = true;
        }
        if (!this.state.user_mail && !this.state.practitioner_email) {
            this.setState({ check_user_error: true });
            error = true;
        }
        if (!error) {
            this.setState({ open: !this.state.open, check_error: false, check_user_error: false });
        }
    }


    handleClose1 = () => {
        this.setState({
            showView: false,
            // open: true,
            // check_error: false,
            // check_user_error: false,
            // release_dna: false,
            // release_epi: false,
            // release_dnaraw: false,
            // user_mail: true,
            // practitioner_email: false
        })
    }
    handleChange = (event) => {
        console.log("handleChange", event.target.value)
        this.setState({ child_kit: event.target.value })
        this.kitIdValidateHandler(event.target.value)
    }

    async kitIdValidateHandler(child_kit) {
        var regexKit = /^(?=.{4,})(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
        if (!child_kit || !child_kit.match(regexKit)) {
            this.state.error.child_kit = true
            this.setState({ error: this.state.error, kitErrorMsg: 'Please enter valid kit id' })
        } else {

            let valid = await apiService.KitIdValidator(child_kit)
            console.log("valid", valid)
            if (valid) {
                this.state.error.child_kit = false
                this.setState({ error: this.state.error, kitErrorMsg: "" })
            } else {
                this.state.error.child_kit = true
                this.setState({ kitErrorMsg: 'Invalid Child kit', error: this.state.error })
            }

        }

    }

    handleShow = (kit) => {
        this.setState({ showView: true })
    }
    closeViewModal = () => {
        this.setState({ showView: false })
    }
    confirmDelete = async () => {
        this.setState({ submitted: true })
        let body = {
            user_id: Buffer.from(String(this.state.edit_user._id)).toString('base64'),
          
        }
        let deleteUser = await apiService.DeleteUser(body)
        this.props.getAllUsers()
        // this.props.reloadUsers()
        this.setState({ confirm_delete: false, submitted: false })
        this.props.updateUserDetails(this.state.edit_user, 'delete')

    }
    updateUser = async (body) => {
        this.setState({ submitted: true })
        body.user_id = Buffer.from(String(this.state.edit_user._id)).toString('base64')
        // body.practitioner_id = this.props.practitionerId

        let updatedData = await apiService.UpdateUserDetails(body)
        this.props.getAllUsers()
        if (!updatedData) {
            this.setState({ submitted: false, showAlert: true })
        } else {
            this.setState({ showAlert: false, edit1: false, submitted: false, show2: false })
            this.props.updateUserDetails(updatedData, 'update')
        }

    }

    clearAlert() {
        this.setState({ showAlert: false })
    }

    componentDidUpdate() {
        if (this.state.showAlert === true) {
            setTimeout(() => {
                this.clearAlert();
            }, 3000)
        }
    }

    render() {
        // let {
        //     showView
        // } = this.state
        return (
            <div className="cmnTable">
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Kit Id</th>
                            <th>Upload Date</th>
                            <th>Assigned</th>
                            <th>Results Available</th>
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props.loader && this.props.kitDetails.length <= 0 ? <tr style={{ textAlign: "center" }}><td colSpan={10}><h5>Kits are not present</h5></td></tr> : null}
                        {!this.props.loader && this.props.kitDetails.map((kit) => (
                            <tr key={kit._id}>
                                <td>{kit.kit_id}</td>
                                <td>{moment(kit.createdAt).format('DD-MM-YYYY')}</td>
                                <td>{kit.assigned_status == 1 ? 'Assigned' : 'Unassigned'}</td>
                                <td>{kit.result_status == 1 ? 'Available' : "Not available"}</td>
                                {/* <td>
                                    <button
                                        disabled={kit.assigned_status == 0}
                                        onClick={() => this.handleShow(kit)}
                                    >
                                        <BsEye size={18} ></BsEye>
                                    </button>
                                </td> */}
                            </tr>
                        ))}


                    </tbody>
                </Table>
                {this.props.loader && <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />}

                {/* <Modal show={showView} onHide={this.closeViewModal} centered className="trackingModal" size="sm">
                    <Modal.Header closeButton >
                        <Modal.Title>View Blood Test Kit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> Are you sure want to delete user<br /> <br />
                        <Button className="bgBtn mr-3" type="submit" size='sm' onClick={this.confirmDelete}>
                            {this.state.submitted ? <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style={{ float: 'center' }} /> : 'YES'}
                        </Button>
                        <Button className="bgBtn mr-3" type="submit" size='sm' onClick={this.closeDelete}>NO</Button>
                    </Modal.Body>
                </Modal> */}

                {/* <Modal show={showDelete} onHide={this.closeDeleteModal} centered className="trackingModal" size="sm">
                    <Modal.Header closeButton >
                        <Modal.Title>Delete Blood Test Kit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> Are you sure want to delete kit?<br/> <br/>
                        <Button className="bgBtn mr-3" type="submit" size='sm' onClick={this.confirmDelete}>
                            {this.state.submitted ? <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style = {{float : 'center'}} /> : 'YES' }
                        </Button>
                        <Button className="bgBtn mr-3" type="submit" size='sm' onClick={this.closeDelete}>NO</Button>
                    </Modal.Body>
    
                </Modal> */}
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    success: alertActions.success,
    error: alertActions.error

}
export default connect(mapState, actionCreators)(KitsTable);
