import { React } from "react";

import { Line } from 'react-chartjs-2'
import { alertActions } from "../../actions";
import { connect } from "react-redux";
import { getPartnerColour } from "../../helpers/partner"

const LineGraphs = ({ data, label_names, xLabel, yLabel, roundOffHundred = false, ...props }) => {
    console.log("data", data, label_names)
    let partner_id = props.user?.partner_id
    let graph_color = getPartnerColour(partner_id)

    let label = []
    if (data === undefined) {
        data = [0, 1, 2, 3, 3, 4, 3, 2]
        label = ['01', '02', '03', '04', '05', '06', '07']
    } else {
        if (label_names && label_names.length > 0) {
            label = label_names
        } else {
            label = data.map((item, index) => index.toString())
        }
    }
    const state4 = {
        labels: label,
        datasets: [
            {
                data: data,
                fill: false,
                borderColor: graph_color,
                pointBackgroundColor: graph_color,
                pointHoverBackgroundColor: graph_color,
                pointHoverBorderColor: graph_color,
                pointHoverBorderWidth: 3,
                pointHoverRadius: 5,
                pointRadius: 3,
                pointHitRadius: 5,
                tension: 0.5,
            },
        ],
    }

    let y = {
        title: {
            color: "1E1E1E",
            display: true,
            text: yLabel,
        },
    };

    if (roundOffHundred) {
        y["min"] = Math.floor(Math.min(...data) / 100) * 100;
        y["max"] = Math.ceil(Math.max(...data) / 100) * 100 + 100;
    }

    return (
        <>
            <div className='linechart-wrp'>
                <Line
                    data={state4}
                    options={{
                        clip: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        scales: {
                            y,
                            x: label_names !== undefined ? {
                                title: {
                                    color: "1E1E1E",
                                    display: true,
                                    text: xLabel,
                                },
                            } : {},
                            // yAxes: [
                            //     {
                            //         ticks: {
                            //             fontSize: 100,
                            //             beginAtZero: false,
                            //         },
                            //         gridLines: {
                            //             lineWidth: 1,
                            //         },
                            //     },
                            // ],
                            // xAxes: label_names !== undefined?[{
                            //     ticks: {
                            //         autoSkip: false,
                            //         maxRotation: 90,
                            //         minRotation: 90
                            //     }
                            // }]:
                            // []
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            backgroundColor: graph_color,
                        },

                    }}
                />
            </div>
        </>
    )

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    success: alertActions.success
}

export default connect(mapState, actionCreators)(LineGraphs)


