import React from "react";

import { FcApproval } from "react-icons/fc";
import UserHeader from "../../components/UserHeader/UserHeader";

class PasswordSuccess extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            partnerId: null
          };
         
    }
    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const partnerId = Number(searchParams.get('partner_id'));  
        this.setState({ partnerId });
      }
    
     
    render() {
       console.log(this.state.partnerId)
        return (
            <>
            <UserHeader partner_id = {this.state.partnerId}></UserHeader>
                <div className="expire-wrp">
                    <div><h1>Success!</h1></div>
                    <div>
                        <FcApproval
                            size={250}
                        ></FcApproval>
                        <div className="expire-text">Signup complete. Please download the app and log in.</div>
                    </div>
                </div>
            </>
        );
    }
};

export default PasswordSuccess;
