import React from 'react';

class WarningIntro extends React.Component {
    constructor(props){
        super()
        this.state = {

        }
    }

    render() {
        return (
            <div className="intro-text">
                <h6>Please note that generating a Disease Risk Report for a user is not free of charge. A one-time fee per user will be charged when you view their report for the first time (you will not be charged if you view the same report again).</h6><br />
                <h6>The Disease Risk Report includes a “Male Health” and “Female Health” section.  Both sections will be displayed if you have not indicated the sex at birth of your client in their customer account profile. </h6><br />
                <h6>If you want to proceed, please select “Yes”, alternatively, please click “No” to go back.</h6>
            </div>
        )
    }
}

export default WarningIntro