import { React } from "react";

import { Line } from 'react-chartjs-2'

const LineGraphs = (props) => {

    const state4 = {
        labels: ['01', '02', '03', '04', '05', '06', '07'],
        datasets: [
            {
                fill: false,
                borderColor: '#1B96DD',
                pointBackgroundColor: '#09A79E',
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#09A79E',
                pointHoverBorderColor: '#09A79E',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 5,
                data: [0, 1, 2, 3, 3, 4, 3, 2],
            },
        ],
    }

    state4.labels = props.labels || ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    state4.datasets[0].data = props.data || [];

    return (
        <>
            <div className='linechart-wrp'>
                <Line
                    data={state4}
                    options={{
                        clip: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            backgroundColor: 'red',
                        },
                        scales: {
                            y: {
                                title: {
                                    color: "1E1E1E",
                                    display: true,
                                    text: props.yLabel,
                                },
                            },
                            x: {
                                title: {
                                    color: "1E1E1E",
                                    display: true,
                                    text: props.xLabel,
                                },
                            }
                        }
                    }}
                />
            </div>
        </>
    )

}

export default LineGraphs
