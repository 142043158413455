import { React } from "react";
import { Table } from "react-bootstrap";
import ColorIndicator from "../../components/ColorIndicator/ColorIndicator";
import { generateReferenceLink } from "../../helpers/generateReferenceLink";

const ReportDetails = ({ data }) => {
    return (
        <>
            <div className="reportDetail-wrp">
                <div className="cmnTable">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>Rsid</th>
                                <th>Genotypes Index</th>
                                {/* <th>Magnitude</th> */}
                                <th>Notes</th>
                                <th>Reference</th>
                            </tr>
                        </thead>
                        {
                            data ?
                                (
                                    <tbody>
                                        {data.map((item) => (
                                            <tr>
                                                <td>{item.rsid}</td>
                                                <td style={{
                                                    color: item.value.num > 0 ? 'red' : '',
                                                    fontWeight: item.value.num > 0 ? 'bold' : 'normal'
                                                }}>{item.index}</td>
                                                <td style={{ textAlign: "justify" }}>
                                                    {item.notes ? item.notes : "See link for more information"}
                                                </td>
                                                <td>
                                                    <a href={generateReferenceLink(item.rsid)} target="_blank" className="reference-link">
                                                        {generateReferenceLink(item.rsid)}
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td>{1}</td>
                                            <td>rs2187668</td>
                                            <td>AA</td>
                                            <td>
                                                <ColorIndicator></ColorIndicator>
                                            </td>
                                            <td>Risk of gluten sensitivity is increased due to ... </td>
                                        </tr>

                                        <tr>
                                            <td>{1}</td>
                                            <td>rs2187668</td>
                                            <td>AA</td>
                                            <td>
                                                <ColorIndicator></ColorIndicator>
                                            </td>
                                            <td>Risk of gluten sensitivity is increased due to ... </td>
                                        </tr>

                                        <tr>
                                            <td>{1}</td>
                                            <td>rs2187668</td>
                                            <td>AA</td>
                                            <td>
                                                <ColorIndicator></ColorIndicator>
                                            </td>
                                            <td>Risk of gluten sensitivity is increased due to ... </td>
                                        </tr>

                                        <tr>
                                            <td>{1}</td>
                                            <td>rs2187668</td>
                                            <td>AA</td>
                                            <td>
                                                <ColorIndicator></ColorIndicator>
                                            </td>
                                            <td>Risk of gluten sensitivity is increased due to ... </td>
                                        </tr>



                                    </tbody>
                                )
                        }
                    </Table>

                </div>

            </div>
        </>
    )

}

export default ReportDetails
