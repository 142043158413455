import  React  from "react";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SearchDeptBox from "../../components/SearchBox/SearchDeptBox";
import DeptTable from "../../components/Tables/DeptTable";
import Pagination from "../../components/Pagination/Pagination";
import {apiService} from '../../services/api_service'
import { alertActions } from '../../actions/alert-action';
import { connect } from 'react-redux';
import { Modal,Spinner, Form, Button } from "react-bootstrap";
import FilerButton from "../../components/UserMenu/FilerButton";
import { SortArray } from "../../constants";

class DepartManagement extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            practitioner_id: this.props.user?.id,
            email: this.props.user?.email,
            partner_id: "",
            clinic_id: "",
            name: "",
            type: "",
            loading: false,
            request_loading: false,
            user_details: [],
            dropdown: [
                { label: 'Practitioner', value: 'practitioner' }
            ],
            addButton: false,
            select: "all",
            select_all: "",
            show: false,
            show1: false,
            show2: false,
            show3: false,
            modal: {},
            access: [],
            accessChecked: [],
            current_pract: "",
            current_id: '',
            current_type: "",
            current_partner: 1,
            user_type: '',
            departmentError: false,
            partnerError: false,
            dmessage: "",
            pmessage: "",
            partner: [],
            clinic: [],
            practitioner: [],
            clinic_name: "",
            partner_name: "",
            users: [],
            usersCollection: [],
            total: 0,
            limit: 10,
            skip: 0,
            pages: [],
            request_access: false,
            search: ""
        }
    }

    async componentWillReceiveProps(nextProps){
        this.setState({ request_loading: true });
        if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
          let practitioner_details = nextProps.user;
          try {
            let type = practitioner_details.type;
            let practitioner_id = btoa(practitioner_details._id);
            let users = await apiService.departmentList({
              practitioner_id,
              id: practitioner_id,
            });
            let total = 0,
              pages = [],
              user_details = [];
            if (users.length > 0) {
              total = users[0].total.length > 0 ? users[0].total[0].count : 0;
              pages = [...Array(Math.ceil(total / this.state.limit)).keys()];
              user_details = users[0].users;
            }
            let dropdown = [];
            if (practitioner_details.type === "clinic") {
              dropdown = [{ label: "All", value: "all" }];
            } else if (practitioner_details.type === "partner") {
              dropdown = [
                { label: "All", value: "all" },
                { label: "Sub-Partner", value: "clinic" },
              ];
            } else {
              dropdown = [
                { label: "All", value: "all" },
                { label: "Partner", value: "partner" },
                { label: "Sub-Partner", value: "clinic" },
              ];
            }
           
            let clinic = [];
            if (type === "admin") {
              console.log("admin");
              await this.practitionerList({ practitioner_id });
              await this.partnerList({ practitioner_id });
              await this.subPartnerList({ practitioner_id });
            } else if (type === "partner") {
              console.log("partner");
              await this.practitionerList({ practitioner_id });
              await this.subPartnerList({ practitioner_id });
              clinic = await apiService.userPractClinic(
                this.state.email,
                this.state.select,
                practitioner_details._id
              );
            } else {
              await this.practitionerList({ practitioner_id });
            }
            this.setState({
              practitioner_id : practitioner_id,
              email : practitioner_details.email,
              partner_id: practitioner_details.partner_id,
              clinic_id: practitioner_details.clinic_id,
              name:
                practitioner_details.first_name +
                practitioner_details.last_name,
              type: practitioner_details.type,
              user_details: user_details,
              total: total,
              pages: pages,
              user_type: practitioner_details.type,
              current_type: practitioner_details.type,
              current_pract: practitioner_details._id,
              dropdown,
              request_loading: false,
              clinic: clinic,
              ...practitioner_details
            });
          } catch (error) {
            console.log("error", error);
          }
        }
    }

    async updateList(){
        try {
            let users = await apiService.departmentList({ practitioner_id: this.state.practitioner_id, id: this.state.practitioner_id, type: this.state.select, limit: this.state.limit, skip: this.state.skip*this.state.limit })
            if(users){
                let total = users[0].total.length>0? users[0].total[0].count: 0
                let pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
                let user_details = users[0].users
                this.setState({user_details, total, pages, skip:0})
            }
            
        } catch (error) {
            console.log("error",error)
        }
    }

    async updateStatus (updated_user){
        updated_user.loading = true
        this.setState({
            data: this.state.user_details.map(el => (el.id === updated_user.id ? updated_user : el))
          });
        let userId = Buffer.from(String(updated_user._id)).toString('base64')
        let requestAccess = await apiService.requestAccess(userId)
        if(requestAccess.code === 200) { 
            updated_user.report_access_status ='Request Pending'
            updated_user.is_accepted = false
        }
        updated_user.loading = false
        this.setState({
            data: this.state.user_details.map(el => (el.id === updated_user.id ? updated_user : el))
          });

    }

    async handleFilter () {
        let partner = await apiService.userPractPartner(this.state.email, this.state.select)
        let clinic = await apiService.userPractClinic(this.state.email, this.state.select)
        this.setState({partner: partner, clinic})
    }

    async updateUsersList(userList, search){
        let total = userList[0].total.length>0? userList[0].total[0].count: 0
        let pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
        let user_details = userList[0].users
        let partner = await apiService.userPractPartner(this.state.email, this.state.select)
        let clinic = await apiService.userPractClinic(this.state.email, this.state.select)
        user_details.length > 0 && user_details.map(async(user, ind) => {
            partner.map(items => {
                if(items._id == user.partner_id){
                    user.partner = items.first_name + " " +items.last_name
                }
            })
            clinic.map(items => {
                if(items._id == user.clinic_id){
                    user.clinic = items.first_name + " " +items.last_name
                }
            })
        })
        this.setState({user_details:user_details, total, pages, skip: 0, search})
    }

    handleSelect(value){
        this.setState({select: value}, ()=> this.updateList())
    }
    handleSelectNew(value){
        this.setState({select: value})
    }

    handleAccessChanges(checked){
        let modal = this.state.modal
        modal.access_controls = checked;
        this.setState({accessChecked: checked, modal })
    }

    handleUserChanges(e){
        let value = e[e.length-1]._id
        let usersCollection = [];
        e.map((items)=>{
            usersCollection.push(items._id)
        })
    //     if(usersCollection.includes(value)){
    //         const index = usersCollection.indexOf(value);
    //         if (index > -1) {
    //             usersCollection.splice(index, 1);
    //         }
    //     }else{
    //         usersCollection.push(value)
    //     }
        this.setState({usersCollection})
    }

    async handleAdd(){
        this.setState({loading: true, modal: {}})
        let data = await apiService.accessManagementUser({
            id : this.state.practitioner_id,
        })
        let partner = []
        if(this.state.select === "partner"){
            partner = await apiService.PartnerListNewNIN(this.state.email, this.state.select)
        }else{
            partner = await apiService.userPractPartner(this.state.email, this.state.select)
        }
        let list = []
        Object.entries(data).map(items=>{
            let value = items[0]
            let label = items[0]
            let condition = false
            let children = []
            if(typeof(items[1]) == "boolean"){
                condition = items[1]
            }else{
                Object.entries(items[1]).map((item, index) =>{
                    let cvalue = item[0]
                    let clabel = item[0]
                    let ccondition = item[1]
                    children.push({"value": cvalue, "label": clabel.replace(/_/, " "), "condition": ccondition})
                })
            }
            list.push({value, label, condition, "children": children.length>0? children: undefined})
        })
        this.setState({show:true, access: list, loading: false, partner: partner, message: "" })        
    }

    async handleAddApi(){
        this.setState({loading: true})
        let modal = this.state.modal
        modal.partner_id = btoa(modal.partner_id)
        if(modal.department && modal.partner_id){
            let data ={}
            if(this.state.type == "partner"){
                modal.partner_id = this.state.practitioner_id
                data = await apiService.departmentCreate(this.state.practitioner_id, this.state.practitioner_id, modal)
            }else if(this.state.type == "clinic"){
                modal.partner_id = this.state.partner_id
                modal.clinic_id = this.state.practitioner_id
                data = await apiService.departmentCreate(this.state.practitioner_id, this.state.practitioner_id, modal)
            }else{
                data = await apiService.departmentCreate(this.state.practitioner_id, this.state.practitioner_id, modal)
            }
            if(data){
                this.setState({message: "Successfully added Department"})
                this.updateList()
                this.handleClose()
                this.setState({loading: false, modal: [], departmentError:false, partnerError: false, dmessage: "", pmessage: ""})
            }
        }else{
            if(!modal.department){
                this.setState({ departmentError: true, dmessage: "Enter a custom filter name"})
            }
            if(!modal.partner_id){
                this.setState({partnerError: true, pmessage: "Please select a partner"})
            }
        }
        this.setState({loading: false})
        
    }

    async handleEdit(id){
        // let data = await apiService.viewManagementUser(this.state.select, btoa(id))
        let data = await apiService.departmentListSingle(this.state.practitioner_id, btoa(id))
        let partner = await apiService.userPractPartner(this.state.email, this.state.select)
        let partner_name = ""
        let clinic = await apiService.userPractClinic(this.state.email, this.state.select,btoa(data.partner_id))
        let clinic_name = ""
        partner.map(items => {
            if(items.partner_id == data.partner_id){
                partner_name = items.first_name + " " +items.last_name
            }
        })
        clinic.map(items => {
            if(items._id == data.clinic_id){
                clinic_name = items.first_name + " " +items.last_name
            }
        })

        this.setState({show1:true, modal:data, partner:partner, clinic:clinic, clinic_name:clinic_name, partner_name: partner_name, current_id: id})
    }

    async handleEditApi(){
        this.setState({loading: true})
        let modal = this.state.modal
        modal.partner_id = btoa(modal.partner_id)
        let data = await apiService.departmentUpdate(this.state.practitioner_id, btoa(this.state.current_id), modal)
        if(data){
            console.log("Successfully updated filter")
            this.setState({message: `Successfully updated Custom Filter`, loading: false, usersCollection: [], show1: false, modal: []})
            this.updateList()
            this.handleClose()
        }else{
            console.log("Something went wrong", data)
            this.setState({message: data, loading: false, usersCollection: []})
        }
    }

    async handleView(id){
        // let data = await apiService.viewManagementUser(this.state.select, btoa(id))
        // let access = await apiService.accessManagementUser()
        // let partner = await apiService.PartnerListNew(this.state.email, this.state.select)
        // let clinic = await apiService.userPractClinic(this.state.email, this.state.select)

        let data = await apiService.departmentListSingle(this.state.practitioner_id, btoa(id))
        let partner_name = "", clinic_name = "";
        
        let selectedPartner = this.state.user_details.filter(item => item._id == id);
        partner_name = selectedPartner.length && selectedPartner[0].partner ? selectedPartner[0].partner : "-";
        clinic_name = selectedPartner.length && selectedPartner[0].clinic ? selectedPartner[0].clinic : "-";
        
        this.setState({show2:true, modal: data, clinic_name, partner_name})
    }

    async handleDelete(id){
        let data = await apiService.departmentListSingle(this.state.practitioner_id,  btoa(id))
        this.setState({show3:true, modal: data, current_id: id})
    }

    async handleDeleteApi(){
        let data = await apiService.departmentRemove(this.state.practitioner_id, btoa(this.state.current_id))
        if(data){
            console.log("Successfully removed user")
            this.handleClose()
            this.setState({message: "Successfully removed Custom Filter"})
            this.updateList()
        }else{
            console.log("Something went wrong", data.error)
            this.setState({message: data.error})
        }
    }

    handleClose(){
        this.setState({
            show:false, 
            show1: false, 
            show2:false, 
            show3: false, 
            message: "", 
            departmentError: false,
            partnerError: false,
            dmessage: "",
            pmessage: "",
            usersCollection: []
        })
    }

    async handleChange(e){
        let name = e.target.name, value= e.target.value
        switch(name){
            case "department":
                if(!value){
                    this.setState({departmentError: true, dmessage: "Enter a custom filter name"})
                }else{
                    this.setState({departmentError: false, dmessage: ""})
                }
                break;
            
            default:
                break
        }
        this.state.modal[name] = value
    }

    handleOperation(modal, id){
        this.setState({current_id: id})
        switch(modal){
            case 'view':
                this.handleView(id)
                break;
            case 'edit':
                this.handleEdit(id)
                break;
            case 'delete':
                this.handleDelete(id)
                break;
            default:
                this.handleView(id)
                break
        }
    }

    async resendEmail(modal, id){
        this.setState({current_id: id})
        let email = await apiService.resendUMEmail(this.state.email, btoa(id))
    }

    async handlePartnerSelect(e){
        let modal = this.state.modal
        modal.partner_id = e.target.value;
        let clinic =[]
        if(modal.partner_id !== "select"){
            clinic = await apiService.userPractClinic(this.state.email, this.state.select, btoa(modal.partner_id))
        }
        this.setState({modal: modal, clinic: clinic, usersCollection: []})
    }

    async handleClinicSelect(e){
        let modal = this.state.modal
        modal["clinic_id"] = e.target.value;
        this.setState({modal: modal})
    }

    async practitionerList (condition){
        let practitioners = await apiService.practitionerList(condition)
        this.setState({practitionerList: practitioners})
    }
    async partnerList (condition){
        let partners = await apiService.PartnerList(condition)
        this.setState({partnerList:partners})
    }
    async subPartnerList (condition){
        let subPractitioners = await apiService.ClinicList(condition)
        this.setState({subPartnerList:subPractitioners})
    }

    async updateUserFilter(condition){
        try {
            condition.limit = this.state.limit
            condition.skip = this.state.skip*this.state.limit
            condition.practitioner_id = this.state.practitioner_id
            let users = await apiService.departmentList(condition)
            let total = users[0].total.length>0? users[0].total[0].count: 0
            let pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
            let user_details = users[0].users
            this.setState({ user_details, total, pages, skip:0 })
        } catch (error) {
            console.log("error", error)
        }
    }

    async selectHandler(e) {
        let type = e.target.name
        let value = e.target.value;
        let condition={email: this.state.email, type: "all" }
        if(type == 'practitioner'){
            if(value != 'select')
                condition.id = btoa(value) 
        }
        if(type == 'clinic'){
            if(value != 'select'){
                condition.id = btoa(value)
                await this.practitionerList({practitioner_id:btoa(value)})
            }else{
                await this.practitionerList({practitioner_id:btoa(this.state.current_pract)})
            }
        }
        if(type == 'admin'){
            if(value != 'select'){
                condition.id = btoa(value)
                await this.subPartnerList({practitioner_id:btoa(this.state.current_pract),partner_id:btoa(value)})
                await this.practitionerList({practitioner_id:btoa(this.state.current_pract),partner_id:btoa(value)})
            }else{
                await this.subPartnerList({practitioner_id:btoa(this.state.current_pract)})
                await this.practitionerList({practitioner_id:btoa(this.state.current_pract)})
            }
        }
        this.updateUserFilter(condition)
    }

    async validateEmail(email){
        let result = await apiService.validateUMEmail(email)
        if(result == true){
            return true
        }else{
            return false
        }

    }

    async handlePagination(skip){
        // this.setState({skip: skip})
        try {
            let users = [], total = 0, pages = [], user_details = []
            if(this.state.search !== ""){
                users = await apiService.departmentSearch(this.state.practitioner_id, this.state.search, this.state.limit, this.state.skip*this.state.limit)
                total = users[0].total.length> 0? users[0].total[0].count: 0
                pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
                user_details = users[0].users
            }else{
                users = await apiService.departmentList({ practitioner_id: this.state.practitioner_id, id: this.state.practitioner_id, limit:this.state.limit, skip:skip*this.state.limit})
                total = users[0].total.length> 0? users[0].total[0].count: 0
                pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
                user_details = users[0].users
            }
            
            this.setState({user_details, total, pages, skip})
            // this.forceUpdate()
        } catch (error) {
            console.log("error",error)
        }
    }

    render(){
        const { dropdown, show, show1, show2, show3, modal, access, departmentError, partnerError, dmessage, pmessage, message, loading, select, partner, clinic, current_type, clinic_name, 
            partner_name, users, partnerList, subPartnerList, practitionerList,type } = this.state
        const addModal = (
            <Modal show={show} onHide={() => this.handleClose()} centered className="trackingModal" size="sm" onEntered={() => this._input.focus()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Custom Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-management-modal"> 
                        <>
                            <Form>
                                <Form.Group className="sign-field">
                                    <Form.Label>Custom Filter</Form.Label>
                                    <Form.Control placeholder="Enter Custom Filter" type="text" name="department" value={modal.department} onChange={(e)=>this.handleChange(e)} ref={input => this._input = input}/>
                                    <Form.Text className="text-muted">
                                        {departmentError? dmessage: null}
                                    </Form.Text>
                                </Form.Group>
                                {type !=="partner" && type !=="clinic" &&
                                <Form.Group className="sign-field">
                                    <Form.Label>Partner</Form.Label>
                                    <Form.Control as="select" value={modal._id} className="multiselect-patients" onChange={(e) => this.handlePartnerSelect(e)}>
                                    <option selected value = 'select'>--Select Partner--</option>
                                        {partner && SortArray(partner).map(user =>(
                                            <option key={user._id} value={user._id}>{user.first_name+" "+user.last_name}</option>)
                                        )}
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        {partnerError? pmessage: null}
                                    </Form.Text>
                                </Form.Group>
                                 }
                                 {type !=="clinic" &&
                                <Form.Group className="sign-field">
                                    <Form.Label>Clinic</Form.Label>
                                    <Form.Control as="select" value={modal.clinic_id} onChange={(e) => this.handleClinicSelect(e)}>
                                    <option selected value = 'select'>--Select Clinic--</option>
                                        {clinic && SortArray(clinic).map(user =>(
                                            <option key={user._id} value={user._id}>{user.first_name+" "+user.last_name}</option>)
                                        )}
                                    </Form.Control>
                                </Form.Group>
                                }

                                <Form.Group className="sign-field">
                                    <Form.Text className="text-muted">
                                        {message? message : null}
                                    </Form.Text>
                                    <Button className="continuebtn" onClick={()=>this.handleAddApi()}>Add Custom Filter {loading?<Spinner animation="border" variant="light" size="sm" style = {{float : 'center'}} />: null}</Button>
                                </Form.Group>

                            </Form>
                        </>
                    </Modal.Body>
                </Modal>
        )
        const editModal = (
            <Modal show={show1} onHide={() => this.handleClose()} centered className="trackingModal" size="sm" onEntered={() => this._input.focus()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Custom Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-management-modal">
                        <>
                            <Form>
                                
                                <Form.Group className="sign-field">
                                    <Form.Label>Custom Filter</Form.Label>
                                <Form.Control
                                    placeholder="Enter Custom FIlter"
                                    type="text" name="department" 
                                    value={modal.department} 
                                    onChange={(e) => this.handleChange(e)} 
                                    ref={input => this._input = input}
                                />
                                    <Form.Text className="text-muted">
                                        {departmentError? dmessage : null}
                                    </Form.Text>
                                </Form.Group>
                                {type !=="partner" && type !=="clinic" &&
                                <Form.Group className="sign-field">
                                    <Form.Label>Partner</Form.Label>
                                    <Form.Group>
                                        <Form.Control as="select" value={modal.partner_id} className="multiselect-patients" onChange={(e) => this.handlePartnerSelect(e)}>
                                        <option selected value = 'select'>--Select Partner--</option>
                                            {partner && SortArray(partner).map(user =>(
                                                <option key={user._id} value={user._id}>{user.first_name+" "+user.last_name}</option>)
                                            )}
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            {partnerError? pmessage: null}
                                        </Form.Text>
                                    </Form.Group>
                                </Form.Group>
                                }
                                {type !=="clinic" &&
                                <Form.Group className="sign-field">
                                    <Form.Label>Clinic</Form.Label>
                                    <Form.Group>
                                        <Form.Control as="select" value={modal.clinic_id} onChange={(e) => this.handleClinicSelect(e)}>
                                        <option selected value = 'select'>--Select Clinic--</option>
                                            {clinic && SortArray(clinic).map(user =>(
                                                <option key={user._id} value={user._id}>{user.first_name+" "+user.last_name}</option>)
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Group>
                                }
                                <Form.Group className="sign-field">
                                    <Button className="continuebtn" onClick={()=> this.handleEditApi()}>Edit Custom Filter {loading?<Spinner animation="border" variant="light" size="sm" style = {{float : 'center'}} />: null}</Button>
                                </Form.Group>
                            </Form>
                        </>
                    </Modal.Body>
                </Modal>
        )
        const viewModal = (
            <Modal show={show2} onHide={() => this.handleClose()} centered className="trackingModal" size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>View Custom Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-management-modal">
                        {modal?
                        <>
                            <Form>
                                <Form.Group className="sign-field">
                                    <Form.Label>Custom Filter</Form.Label>
                                    <Form.Control placeholder="Enter Custom Filter" type="text" name="department" value={modal.department} disabled />
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>Partner</Form.Label>
                                    <Form.Control placeholder="Enter partner" type="text" name="partner" value={partner_name} disabled />
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>Clinic</Form.Label>
                                    <Form.Control placeholder="Enter clinic" type="text" name="clinic" value={clinic_name} disabled />
                                </Form.Group>
                            </Form>
                        </>
                        :<h1>No result was found</h1>}
                    </Modal.Body>
                </Modal>
        )
        const deleteModal = (
            <Modal show={show3} onHide={() => this.handleClose()} centered className="trackingModal" size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Custom Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <h5>Are you sure you want to delete the Custom Filter {modal.department} ?</h5><br />
                            <Button className="bgBtn mr-3" type="submit" size='sm' onClick={()=> this.handleDeleteApi()}>
                                {this.state.submitted ? <Spinner animation="border" variant="light" size="sm" style = {{float : 'center'}} /> : 'YES' }
                            </Button>
                            <Button className="bgBtn mr-3" type="submit" size='sm' onClick={() => this.handleClose()}>NO</Button>
                    </Modal.Body>
                </Modal>
        )
        return (
            <>
    
                <div className="home-wrp">
                    <div className="homepagehead">
                        <div className="row justify-content-between align-items-end">
                            <div className="col-12 col-sm-auto">
                                <h1>Custom Filters</h1>
                                <BreadCrumb title="Custom Filters"></BreadCrumb>
                            </div>
                            <div className="col-12 col-sm-auto d-flex">
                                <FilerButton types={dropdown} updateList={this.handleSelect.bind(this)}></FilerButton>
                                {/* <FilerButtonNew partner={partner} clinic={clinic} practitioner={practitioner} handleFilter={() => this.handleFilter()} onClick={() => this.handleFilter()}></FilerButtonNew> */}
                                <SearchDeptBox practitioner_id = {this.state.practitioner_id} limit={this.state.limit} skip={this.state.skip} updateUsersList = {this.updateUsersList.bind(this)}></SearchDeptBox>
                            </div>
                        </div>
    
                    </div>
                    <div className="selectionBar-admin">
                    <div className="innerpagesFiltration">

                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-sm-auto">
                                    <ul className="list-unstyled-um">
                                        {current_type == 'admin' &&
                                            <li key={"partner"}><h6>Partner : </h6>  <Form.Group>
                                            <Form.Control as="select" name ='admin' onChange={this.selectHandler.bind(this)}>
                                                <option selected value='select'>--Select Partner--</option>
                                                {partnerList && SortArray(partnerList,"first_name").map(user => (
                                                    <option key={user._id} value={user._id}>{user.first_name}</option>)
                                                )}
                                            </Form.Control>
                                        </Form.Group>  </li>
                                        }
                                        {(current_type == 'admin'  ||  current_type == 'partner') &&
                                            <li key={"clinic"}><h6>Sub-partner : </h6>   <Form.Group>
                                            <Form.Control as="select" name ='clinic' onChange={this.selectHandler.bind(this)}>
                                                <option selected value='select'>--Select Sub-partner--</option>
                                                {subPartnerList && SortArray(subPartnerList,"first_name").map(user => (
                                                    <option key={user.id} value={user._id}>{user.first_name}</option>)
                                                )}
                                            </Form.Control>
                                        </Form.Group>  </li>
                                        }
                                        {/* {(current_type == 'admin'  ||  current_type == 'partner' || current_type == 'clinic')  &&
                                        <li key={"practitioner"}><h6>Practitioner : </h6>   <Form.Group>
                                            <Form.Control as="select" name='practitioner' onChange={this.selectHandler.bind(this)}>
                                                <option selected value='select'>--Select Practitioner--</option>
                                                {practitionerList && practitionerList.map(user => (
                                                    <option key={user._id} value={user._id}>{user.first_name}</option>)
                                                )}
                                            </Form.Control>
                                        </Form.Group>  </li>
                                        }  */}
                                    </ul>
                            </div>
                            <div className="col-12 col-sm-auto">
                                {/* {select !== "all" && select != "1"? */}
                                    <button className="addBtn" onClick={() => this.handleAdd()}> <i className="bi bi-person-plus-fill"></i> Add Custom Filter {loading ?<Spinner animation="border" size="sm" style = {{float : 'center'}} />: null}</button>
                                {/* :null} */}
                            </div>
                        </div>
                    </div>
                        
                    </div>
                    <div className="patientsTable">
                        <DeptTable 
                            userDetails = {this.state.user_details} 
                            practitionerId = {this.state.practitioner_id} 
                            updateUser= {this.updateStatus.bind(this)}
                            loading_status = {this.state.request_loading} 
                            handleOperation = {this.handleOperation.bind(this)}
                            resendEmail = {this.resendEmail.bind(this)}
                            select ={this.state.select}
                            skip={this.state.skip}
                        >
                        </DeptTable>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Pagination paginationClick={(num)=>this.handlePagination(num)} total={this.state.total} pages={this.state.pages} skip={this.state.skip} limit={this.state.limit}></Pagination>
                    </div>
                </div>

                {addModal}
                {editModal}
                {viewModal}
                {deleteModal}
            </>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return {loggingIn, user};
  }

const actionCreators = {
    success: alertActions.success
  }

export default connect(mapState, actionCreators)(DepartManagement)
