import  React from "react";
import moment from 'moment';
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import {apiService} from '../../services/api_service'
import Checkbox from "../Checkbox/Checkbox";
import { alertActions } from '../../actions/alert-action';
import { connect } from 'react-redux';
import Loader from "../Loader/Loader";
import {
    BsPencilSquare,
    BsFillTrashFill,
    BsEye,
    BsEnvelope
} from "react-icons/bs";

class DeptTable extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            show : false,
            show1 : false,
            kit_id:'',
            trackingHistory : [],
            history_width : '100%',
            request_status : 'Request Access',
            open: true,
            loading : false,
            show2:false
        }
    }

    handleClose = () => this.setState({show : false}) 
   
    handleOpen = () => this.setState(!this.state.open);

    handleClose1 = () => this.setState({show1: false})
    handleShow1 = () => this.setState({show1 : true})
    handleClose2 = () => this.setState({show2: false})
    handleShow2 = () => this.setState({show2 : true})

    render(){
        let { loading_status } = this.props;
        let {show,show1,kit_id ,trackingHistory, history_width, request_status,loading, open,show2} = this.state
        return (
            <div className="cmnTable">
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>Custom Filter Name</th>
                            <th>Partner</th>
                            <th>Clinic</th>
                            {/* <th>Practitioner</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading_status ? <Spinner animation="border" variant="info" className='spinnerClass'  style = {{marginLeft:'258%', marginRight:'50%', padding:'20px',marginTop:'30px', marginBottom:'30px'}} />: 
                            this.props.userDetails.length <= 0 ? <tr style={{ textAlign: "center"}}><td colSpan={10}><h5>Departments are not present</h5></td></tr>:
                            this.props.userDetails && this.props.userDetails.map((user, index) => (
                                <tr key = {user._id}>
                                <td>{index+1}</td>
                                <td>{user.department}</td>
                                <td>{user.partner}</td>
                                <td>{user.clinic}</td>
                                {/* <td>{user.practitioner_id}</td> */}
                                <td>
                                    <div className="action-icons" style={{display: "inline-flex"}}>
                                        <button onClick={() =>this.props.handleOperation("view", user._id)} id={user._id+"view"} className="tableviewLink" title="View user">
                                            {/* <img src="images/eye-image.png"></img> */}
                                            <BsEye
                                            size={18}
                                            // color={user.user_type!='Practitioner' ? 'grey':''}
                                            ></BsEye>
                                        </button>
                                        <button onClick={() =>this.props.handleOperation("edit", user._id)} id={user._id+"edit"} className="tableviewLink" title="Edit user">
                                            <BsPencilSquare
                                            size={18}
                                            // color={user.user_type!='Practitioner' ? 'grey':''}
                                            ></BsPencilSquare>
                                        </button>
                                        <button onClick={() =>this.props.handleOperation("delete", user._id)} id={user._id+"delete"} className="tableviewLink" title="Delete user">
                                            <BsFillTrashFill
                                            size={18}
                                            color={'red'}
                                            ></BsFillTrashFill>
                                        </button>
                                    </div>
                                </td>
                                
                            </tr>
                            ))
                        }
                        
                        
                    </tbody>
                </Table>
                <Modal show={show2} onHide={this.handleClose2} centered className="trackingModal" size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Kit tracking History: {kit_id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> No history found</Modal.Body>
    
                </Modal>
    
                <Modal show={show} onHide={this.handleClose} centered className="trackingModal" >
                    <Modal.Header closeButton>
                        <Modal.Title>Kit tracking History: {kit_id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* add class name "st-dispatched /st-received / st-resultReady" for changing the progress*/}
                        
                        <div className="tracking-wrp st-resultReady" >
                            <ul className="list-unstyled statustypelist">
                            {trackingHistory.map((kit) => (
                                <li className="active">{kit.status}</li>
                            ))}
                            </ul>
                            <div className="trackingbar-wrp">
                                <div className="trackingbar">
                                    <div className="trackingstatus  " style={{width:'100%'}}></div>
                                </div>
                                <div className="spanwrp">
                                {trackingHistory.map((kit) => (
                                    
                                    <span style={{left: kit.width}}><img src="images/statuschecked.svg"></img></span>
                                   
                                ))}
                                 </div>
                                
                            </div>
                            <ul className="list-unstyled">
                               {trackingHistory.map((kit) => (
                                   <li className="active" style={{width:kit.width,}}>{moment(kit.date).format("YYYY-MM-DD")}</li>
                                ))}
                            </ul>
                        </div>
                      
    
    
                    </Modal.Body>
    
                </Modal>
    
    
                <Modal show={show1} onHide={this.handleClose1} centered className="trackingModal releaseModal" >
                <Modal.Header closeButton>
                    <Modal.Title>Release Results & Generate Pdf</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 
                 <Checkbox title="Release DNA Results"></Checkbox>
                 <Checkbox title="Release Epigenetics Results"></Checkbox>
                 <Checkbox title="Release DNA Pdf"></Checkbox>
                 <Checkbox title="Release Epigenetics Pdf"></Checkbox>
               
                  
                </Modal.Body>
                {open?
                <Modal.Footer>
                    <div>
                    <Button className="bgBtn mr-3" onClick={this.handleOpen}>
                        Submit
                    </Button>
                    </div>
                </Modal.Footer>
                :
                <Modal.Footer>
                    <h5>Are you sure you want to Release DNA Results?</h5>
                    <div>
                        <Button className="bgBtn mr-3" onClick={() =>{
                            this.handleClose1()
                            this.handleOpen()
                        }}>
                            CONFIRM
                        </Button>
                        <Button className="outerlineBtn" onClick={() =>{
                            this.handleClose1()
                            this.handleOpen()
                        }}>
                            CANCEL
                        </Button>
                    </div>
                </Modal.Footer>
                }
            </Modal>
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return {loggingIn};
  }

const actionCreators = {
    success: alertActions.success,
    error: alertActions.error
    
  }
export default connect(mapState, actionCreators) (DeptTable);
