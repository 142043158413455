import { alertConstants } from "../constants";

const localCurrent = sessionStorage.getItem("alert");
let currentVal = localCurrent ? JSON.parse(localCurrent) : {};

const initialState = currentVal || {};

export function alert(state = initialState, action) {
  let alert = {};
  switch (action.type) {
    case alertConstants.SUCCESS:
      alert = {
        type: "alert-success",
        message: action.message,
      };
      sessionStorage.setItem("alert", JSON.stringify(alert));
      return alert;
    case alertConstants.ERROR:
      alert = {
        type: "alert-danger",
        message: action.message,
        status: action?.statusCode || 400,
      };
      sessionStorage.setItem("alert", JSON.stringify(alert));
      return alert;
    case alertConstants.CLEAR:
      sessionStorage.removeItem("alert");
      return {};
    case alertConstants.BULK_ALERT: {
      sessionStorage.setItem("alert", JSON.stringify(action.data));
      return action.data;
    }

    default:
      return state;
  }
}
