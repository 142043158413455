import { render } from "@testing-library/react";
import React from "react";
import { Doughnut } from "react-chartjs-2";



const Doughnutgraph = (props) => {
  let data1 = {
  labels: ["DNA and EPI", "DNA", "EPI", "Others"],
  datasets: [
    {
      label: "My First Dataset",
      data: [300, 50],
      backgroundColor: ["#05615c","#08918a","#5DD0FC","#0ac2b9"],
      hoverOffset: 4,
    },
  ],
}
if(props.products){
  data1.datasets[0].data = [props.products.dna_epi,props.products.dna,props.products.epi,props.products.remaining]
}
if(props.devices){
  data1.labels = ["Android", "Ios", "Others"]
  data1.datasets[0].data = props.devices.map((items) => items.count)
  data1.datasets[0].backgroundColor = ["#05615c","#09a79e","#5DD0FC"]
}
if(props.tracking){
  data1.labels = ["Yes", "No"]
  data1.datasets[0].data =[props.tracking.withTracking,props.tracking.withoutTracking]
  data1.datasets[0].backgroundColor = ["#05615c","#09a79e"]
}
const options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: false,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}

  return(
  <>
    <div className="doughnutchart-wrp">
      <Doughnut data={data1} height="390" options={options} />
    </div>
  </>
  )

  
}

export default Doughnutgraph;
