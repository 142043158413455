import React from "react";
import Select from "react-select";

class SelectionsBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(item) {
    this.props.changeItem(item.value, item);
  }

  render() {
    const { list, title } = this.props;
    return (
      <>
        <div className="selectionBar">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-auto">
              <h3>{title}</h3>
            </div>
            {list.length > 0 ? (
              <div className="col-md col-md-auto">
                <Select
                  onChange={this.handleChange.bind(this)}
                  defaultValue={this?.props?.value || list[0]}
                  key={list[0]}
                  options={list}
                  menuPlacement="auto"
                  isSearchable={false}
                  className="customSelect-wrp"
                  classNamePrefix="customSelect"
                />
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default SelectionsBar;
