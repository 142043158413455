import React from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
const dna = [
    { label: 'Running', value: 1 },
    { label: 'Running', value: 2 },
    { label: 'Running', value: 3 },
    { label: 'Health', value: 4 }
];

class GraphFilter extends React.Component{

    constructor(props){
        super()
        this.state={
            startDate: props.start_date ? props.start_date :new Date(),
            endDate:props.end_date ? props.end_date :null,
            datePickerIsOpen: false,
            weekPickerIsOpen: false,
            monthPickerIsOpen: false,
            yearPickerIsOpen: false,
            selected:props.selected_type ? props.selected_type : 'month'
        }
        this.handleChange = this.handleChange.bind(this);
        this.openDatePicker = this.openDatePicker.bind(this)
        this.openMonthPicker = this.openMonthPicker.bind(this)
        this.openWeekPicker = this.openWeekPicker.bind(this)
        this.openYearPicker = this.openYearPicker.bind(this)
        this.handleYearChange = this.handleYearChange.bind(this)
        this.handleMonthChange = this.handleMonthChange.bind(this)
        this.handleweekChange = this.handleweekChange.bind(this)
    }
    handleChange(date) {
        this.setState({
          startDate: date,
          datePickerIsOpen: false
        });

        let condition = {
            type:'day',
            startDate:moment(date).format('YYYY-MM-DD'),
            endDate:moment(date).format('YYYY-MM-DD'),
            start_date:moment(date).format('YYYY-MM-DD'),
            last_date:moment(date).format('YYYY-MM-DD')

        }
        this.props.DetailsGetAPI(condition,'day')
      }
      handleweekChange(dates){
          const [start, end] = dates;
            this.setState({
            startDate: start,
            endDate:end
            });

            if(end){
                let condition = {
                    type:'week',
                    startDate:moment(start).format('YYYY-MM-DD'),
                    endDate:moment(end).format('YYYY-MM-DD'),
                    start_date:moment(start).format('YYYY-MM-DD'),
                    last_date:moment(end).format('YYYY-MM-DD')
                }

                this.setState({weekPickerIsOpen:false})
                this.props.DetailsGetAPI(condition,'week')


            }
      }

      handleYearChange(year){
          let selectedYear = year.getFullYear()
          let start_day = moment(year).startOf('year').format('YYYY-MM-DD')
          let end_day = moment(year).endOf('year').format('YYYY-MM-DD')
          let today = moment().format('YYYY-MM-DD')
          this.setState({startDate:year,yearPickerIsOpen:false})
          let condition = {
              type:'year',
              year:selectedYear,
              start_date:start_day,
              last_date:end_day > today ? today : end_day,
          }
          this.props.DetailsGetAPI(condition,'year')

      }

      handleMonthChange(month){
          let Selectedmonth = month.getMonth()+1
          let year = month.getFullYear()
          let start_day = moment(month).startOf('month').format('YYYY-MM-DD')
          let end_day = moment(month).endOf('month').format('YYYY-MM-DD')
          let today = moment().format('YYYY-MM-DD')
          this.setState({startDate:month,monthPickerIsOpen:false})
          let condition = {
            type:'month',
            month:Selectedmonth,
            year: year,
            start_date:start_day,
            last_date:end_day > today ? today : end_day ,
        }
        this.props.DetailsGetAPI(condition,'month')
      }


      handleWeekClick(week){
        //   console.log(week)
      }

    openDatePicker() {
        this.setState({
          datePickerIsOpen: !this.state.datePickerIsOpen,
        });
      };

      openMonthPicker() {
        this.setState({
            monthPickerIsOpen: !this.state.monthPickerIsOpen,
        });
      };

      openWeekPicker() {
        this.setState({
            weekPickerIsOpen: !this.state.weekPickerIsOpen,
        });
      };

      openYearPicker() {
        this.setState({
            yearPickerIsOpen: !this.state.yearPickerIsOpen,
        });
      };

    render(){
        return(
            <>
            <div className="graphfilter">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-auto">
                        {/* <div className="activityfilter">
                            <label>  Activity : </label><Select
                                defaultValue={dna[2]}
                                options={dna}
                                menuPlacement="auto"
                                isSearchable={false}
                                className="customSelect-wrp"
                                classNamePrefix="customSelect"
                            />
                        </div> */}

                        {this.props.type === 'line' &&
                        <ul className="list-unstyled iconlist">
                            <li><span style={{backgroundColor : "#FF7700"}}></span> {this.props.label1}</li>
                            <li><span style={{backgroundColor : "#09A79E"}}></span> {this.props.label2}</li>
                            <li><span style={{backgroundColor : "#FC1504"}}></span> {this.props.label3}</li>
                        </ul>
                        }
    
                        
    
                    </div>
                    <div className="col-12 col-md-auto">
                        <ul className="list-unstyled dwmy-list">
                            <li className={this.state.selected == 'day' ? 'active' : null}>
                                <button className={this.state.selected == 'day' ? 'active' : null} onClick={()=>this.openDatePicker()}>D</button>
                                <div className='filterDatePicker'>
                                    {this.state.datePickerIsOpen&&
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange.bind(this)}
                                        onClickOutside={this.openDatePicker}
                                        open={this.state.datePickerIsOpen}
                                        maxDate={new Date()}
                                    ></DatePicker>}
                                </div>
                            </li>
                            <li className={this.state.selected == 'week' ? 'active' : null}>
                                <button className={this.state.selected == 'week' ? 'active' : null} onClick={()=>this.openWeekPicker()}>W</button>
                                <div className='filterDatePicker'>
                                    {this.state.weekPickerIsOpen&&
                                    <DatePicker
                                        // selected={this.state.startDate}
                                        selected={this.state.startDate}
                                        onChange={this.handleweekChange}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        selectsRange
                                        open={this.state.weekPickerIsOpen}
                                        onClickOutside={this.openWeekPicker}
                                        maxDate={new Date()}
                                    ></DatePicker>}
                                </div>
                            </li>
                            <li className={this.state.selected == 'month' ? 'active' : null}>
                                <button className={this.state.selected == 'month' ? 'active' : null} onClick={()=>this.openMonthPicker()}>M</button>
                                <div className='filterDatePicker'>
                                    {this.state.monthPickerIsOpen&&
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleMonthChange}
                                        onClickOutside={this.openMonthPicker}
                                        open={this.state.monthPickerIsOpen}
                                        dateFormat="mm/yyyy"
                                        showMonthYearPicker
                                        showFourColumnMonthYearPicker
                                        maxDate={new Date()}
                                     ></DatePicker>}
                                </div>
                            </li>
                            <li className={this.state.selected == 'year' ? 'active' : null}>
                                <button className={this.state.selected == 'year' ? 'active' : null} onClick={()=>this.openYearPicker()}>Y</button>
                                <div className='filterDatePicker'>
                                    {this.state.yearPickerIsOpen&&
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleYearChange}
                                        onClickOutside={this.openYearPicker}
                                        open={this.state.yearPickerIsOpen}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        yearItemNumber={9}
                                        maxDate={new Date()}
                                    ></DatePicker>}
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* {this.state.datePickerIsOpen && 
                    <input type="hidden" id="datepicker"> 
                    </input>
                    } */}
                </div>
            </div>
        </>
        )
    }
}

export default GraphFilter;