export function validatePasswordCriteria(password) {
    const criteria = [
        { name: "Atleast 1 uppercase letter", pattern: /(?=.*[A-Z])/ },
        { name: "Atleast 1 lowercase  letter", pattern: /(?=.*[a-z])/ },
        { name: "Atleast 1 digit", pattern: /(?=.*\d)/ },
        { name: "At least 2 special character", pattern: /(?:[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*){2}/, },
        { name: "At least 15 characters", pattern: /^.{15,}$/ },
    ];

    return criteria
        .filter((criterion) => !criterion.pattern.test(password))
        .map((criterion) => criterion.name);
}
