import { React } from "react";
import Doughnutgraph from "../Doughnutgraph";
import { DiAndroid, DiApple } from "react-icons/di";
import { MdTabletAndroid } from "react-icons/md"
import {TiTickOutline,TiTimesOutline} from "react-icons/ti"
import{GiDna2} from "react-icons/gi"
import {SiMicrogenetics} from "react-icons/si"

const DoughnutCard = (props) => {
  return (
    <>
      <div className="doughnutCard">
        <h5>{props.title}</h5>
        <div className={`doughnutcardBody ${ props.classname }`}>
        
            <Doughnutgraph products={props.products} devices={props.devices} tracking ={props.tracking}></Doughnutgraph>
          
         
            <div className="doughnutdetails">
              {props.products &&
              <>
                <ul className="list-unstyled">
                  <li>
                    <span style={{ backgroundColor: "#08918a" }}></span> DNA
                  </li>
                  {/* <li>
                    <span style={{ backgroundColor: "#5DD0FC" }}></span>EPI
                  </li> */}
                  <li>
                    <span style={{ backgroundColor: "#05615c" }}></span>DNA and EPI
                  </li>
                  {/* <li>
                    <span style={{ backgroundColor: "#0ac2b9" }}></span>Others
                  </li> */}
                </ul> 
                <div className="percentagedetails">
                  <img src="images/bi-dna.svg" className="filterit"></img> <strong>{props.products.dna}%</strong> DNA
                </div>
                {/* <div className="percentagedetails" >
                  <img src="images/bi-epi.svg" className="filterit"></img> <strong>{props.products.epi}%</strong> EPI
                </div> */}
                <div className="percentagedetails">
                  <GiDna2 size='15px' style={{marginRight:'9px'}} />   <strong>{props.products.dna_epi}%</strong> DNA and EPI
                </div>
                {/* <div className="percentagedetails">
                  <SiMicrogenetics size='15px' style={{marginRight:'9px'}} /> <strong>{props.products.remaining}%</strong> Others
                </div> */}
              </>
              }
            {props.devices &&
              <>
                <ul className="list-unstyled">
                  <li>
                    <span style={{ backgroundColor: "#05615C" }}></span>{props.devices[0] && props.devices[0].title}
                  </li>
                  <li>
                    <span style={{ backgroundColor: "#09A79E" }}></span>{props.devices[1] && props.devices[1].title}
                  </li>
                  {props.devices[2] ?
                    <li>
                      <span style={{ backgroundColor: "#5DD0FC" }}></span>{props.devices[2] && props.devices[2].title}
                    </li> : null}
                </ul>
                <div className="percentagedetails">
                  <DiAndroid size='14px'/> <strong>{props.devices[0] && props.devices[0].percent}%</strong> {props.devices[0] && props.devices[0].title}
                </div>
                <div className="percentagedetails">
                  <DiApple size='14px'/> <strong>{props.devices[1] && props.devices[1].percent}%</strong> {props.devices[1] && props.devices[1].title}
                </div>
                {props.devices[2] ?
                <div className="percentagedetails">
                  <MdTabletAndroid size='14px'/> <strong>{props.devices[2] && props.devices[2].percent}%</strong> {props.devices[2] && props.devices[2].platform}
                </div> : null }
              </>
              }
              {props.tracking &&
              <>
                <ul className="list-unstyled">
                  <li>
                    <span style={{ backgroundColor: "#05615c" }}></span> Yes
                  </li>
                  <li>
                    <span style={{ backgroundColor: "#09A79E" }}></span> No
                  </li>
                </ul>
                <div className="percentagedetails">
                  <TiTickOutline size='18px'/> <strong>{props.tracking.withTracking}%</strong>  Yes
                </div>
                <div className="percentagedetails">
                 <TiTimesOutline size='18px'/> <strong>{props.tracking.withoutTracking}%</strong> No
                </div>
              </>
              }
              </div>
         
        </div>
      </div>
    </>
  );
};

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toUpperCase() : word.toUpperCase();
  }).replace(/\s+/g, ' ');
}

export default DoughnutCard;
