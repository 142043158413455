import React from "react";
import ContentTable from "../../components/Tables/ContentTable";
import TabItems from "../../components/TabItems/TabItems";
import PageHead from "../../components/PageHead/PageHead";
import {apiService} from '../../services/api_service'

import { Bar } from "react-chartjs-2";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../actions";

// let data1 = {
//   labels: [
//     ["Stress & Pressure", "(Average Person)"],
//     ["Stress & Pressure", "(Your Staff)"],
//     ["Tiredness - Stress", "that affects sleep", "(Average Person)"],
//     ["Tiredness - Stress ", "that affects sleep", "(Your Staff)"],
//   ],
//   datasets: [
//     {
//       data: [10, 40, 60, 80],
//       backgroundColor: "#09A79E",
//       maxBarThickness: 40,
//     },
//   ],
// };

const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        color: "#1E1E1E",
        font: {
          size: 14,
        },
      },
      title: {
        display: true,
      },
    },
    x: {
     
      title: {
        display: true,
       
      },
      ticks: {
       
        color: "#1E1E1E",
        align: "center",
        autoSkip: false,
        maxRotation: 90,
        minRotation: 90,
      },
    },
  },
};

class ManagementInsight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practitioner_id: "",
      email: "",
      users_list: [],
      loader: false,
      tab: [
        "Colds/flu/infection",
        "Stress/Anxiety/Depression",
        "Musculoskeletal",
        "Digestion/Gut health",
      ],
      view: "Colds/flu/infection",
      topics: [],
      data1: {
        labels: [
          // ["Stress & Pressure", "(Average Person)"],
          // ["Stress & Pressure", "(Your Staff)"],
          // ["Tiredness - Stress", "that affects sleep", "(Average Person)"],
          // ["Tiredness - Stress ", "that affects sleep", "(Your Staff)"],
        ],
        datasets: [
          {
            data: [
              // 10, 40, 60, 80
            ],
            backgroundColor: "#09A79E",
            maxBarThickness: 40,
          },
        ],
      },
    };
  }

  async componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
        const practitioner_id = btoa(nextProps.user?._id);

        //let user_details = await apiService.AccessUsersList(practitioner_id);
        let tab = await apiService.ManagementITabs(practitioner_id);
        let view = tab[0];
        let response = await apiService.ManagementIGetAll(
          practitioner_id,
          view
        );
        let response2 = await apiService.ManagementIGetSingle(
          practitioner_id,
          view
        );
        console.log("response", response, response2);
        let topics = this.CalculateAvg(response, response2);
        // let risk = this.CalculateRisk(response, response2)
        // console.log("risk", risk)
        console.log("topics", topics);
        let data1 = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: "#09A79E",
              maxBarThickness: 40,
            },
          ],
        };
        data1.labels = [];
        data1.datasets[0].data = [];
        topics.map((items) => {
          data1.labels.push([items.title, "(Average Person)"]);
          data1.labels.push([items.title, "(Your Staff)"]);
          data1.datasets[0].data.push(items.avg);
          data1.datasets[0].data.push(items.your);
        });
        this.setState({
          tab,
          view,
          topics,
          data1,
          //users_list: user_details,
          loader: false,
          practitioner_id,
          ...nextProps.user
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  CalculateRisk(resp, resp2) {
    let result = [],
      nums = [],
      high = [],
      index = [],
      response = [];
    let counter = 0;
    resp.map((items, index) => {
      if (items.title !== "") {
        let title = items.title;
        let key = items.key;
        let avg = Math.floor((items.total / items.high_total) * 100);
        let your = resp2
          ? Math.floor((resp2[counter].total / resp2[counter].high_total) * 100)
          : avg - Math.floor(Math.random() * (10 - 1 + 1)) + 1;
        your = your > 100 ? 100 : your;
        counter++;
        result.push({ title, key, avg, your });
        console.log("check nan", your, avg, your - avg);
        nums.push({ value: Math.abs(your - avg), index });
      }
    });
    nums.map((items) => {
      console.log(items);
      // nums.push({value: Math.abs(items.your-items.avg), index})
      if (!high.includes(items.value)) {
        let num = items.value;
        if (high.length <= 1) {
          high.push(items.value);
          index.push(items.index);
        }
        if (num > high[0]) {
          high[0] = items.value;
          index[0] = items.index;
        } else if (num > high[1]) {
          high[1] = items.value;
          index[1] = items.index;
        }
      }
    });
    return result;
  }

  CalculateAvg(resp, resp2) {
    let result = [],
      counter = 0;
    resp.map((items, index) => {
      if (items.title != "") {
        let title = items.title;
        let key = items.key;
        let avg = Math.floor((items.total / items.high_total) * 100);
        let single = resp2.filter((r) => r.title == items.title);
        let your;
        if (single.length > 0) {
          your = Math.floor((single[0].total / single[0].high_total) * 100);
        } else {
          your = avg - Math.floor(Math.random() * (10 - 1 + 1)) + 1;
        }
        your = your > 100 ? 100 : your;
        counter++;
        result.push({ title, key, avg, your });
      }
    });
    return result;
  }

  async ChangeTabs(tab) {
    this.setState({ loader: true });
    let response = await apiService.ManagementIGetAll(
      this.state.practitioner_id,
      tab
    );
    let response2 = await apiService.ManagementIGetSingle(
      this.state.practitioner_id,
      tab
    );
    console.log("response", response, response2);
    let topics = this.CalculateAvg(response, response2);
    // let risk = this.CalculateRisk(response, response2)
    // console.log("risk", risk)
    let data1 = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: "#09A79E",
          maxBarThickness: 40,
        },
      ],
    };
    data1.labels = [];
    data1.datasets[0].data = [];
    topics.map((items) => {
      data1.labels.push([items.title, "(Average Person)"]);
      data1.labels.push([items.title, "(Your Staff)"]);
      data1.datasets[0].data.push(items.avg);
      data1.datasets[0].data.push(items.your);
    });
    this.setState({ view: tab, topics, data1, loader: false });
  }

  render() {
    let { tab, view, topics, data1 } = this.state;
    return (
      <>
        <div className="managamentInsight-wrp">
          <PageHead title="Management Insights" is_disabled={true}></PageHead>

          {/* use this for tab head */}
          <TabItems
            tab={tab}
            category={view}
            categorySelector={(tabs) => this.ChangeTabs(tabs)}
          ></TabItems>

          {/* <div className="tabContainer">
            <ul>
              <li className="tabItem active">Stress/Anxiety/Depression</li>
              <li className="tabItem ">Musculoskeletal</li>
              <li className="tabItem">Colds/Flus/Infections</li>
              <li className="tabItem">Digestion</li>
            </ul>
          </div> */}

          {/* use this for tab head */}

          <div className="tabContent">
            <div className="row">
              {/* <div className="col-12 col-lg-5"> */}
              {/* use this for Bar chart */}
              {/* <BarGrapgh></BarGrapgh> */}
              {/* use this for Bar chart */}
              {/* <div className="graph-wrp">
                  <h5>Risks of {view}</h5>
                  <div className="barchart-wrp cmnchart-wrp">
                    <Bar data={data1} height="390" options={options} />
                  </div>
                </div>
              </div> */}

              <div className="col-12 col-lg-12">
                {/* <ContentTable></ContentTable> */}
                {/* use this "ContentTable" table component with below hard coded table data */}
                <div className="cmnTable1">
                  <Table className="table-topics-responsive" responsive striped>
                    <thead>
                      <tr>
                        <th>Genetic Outcome</th>
                        <th className="text-center">Your Users</th>
                        <th className="text-center">Average</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topics.length > 1
                        ? topics.map((items) => (
                            <tr
                              key={items.title.toLowerCase().replace(" ", "_")}
                            >
                              <td>{items.title}</td>
                              <td className="text-center">
                                <span
                                  className={
                                    items.your <= items.avg
                                      ? "textcolor-green"
                                      : "textcolor-red"
                                  }
                                >
                                  {isNaN(items.your) ? 0 : items.your}%
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="textcolor-black">
                                  {items.avg}%
                                </span>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                </div>
              </div>

              {/* <div className="col-12 col-lg-5">
                <div className="cmnCard">
                  <div className="cmncardHead">
                      <h4>Company Priorities</h4>
                  </div>
                  <div className="cmncardBody">
                    <span style={{fontSize: "14px"}}>Coming Soon...</span>
                    <ul className="list-unstyled squareList">
                      <li>Magnesium/B6 Supplements</li>
                      <li>On-site Councellor</li>
                      <li>Stress/Email Management</li>
                    </ul> 
                  </div>
                </div>
              </div> */}

              <div className="col-12 col-lg-12">
                {/* <ContentTable></ContentTable> */}
                {/* use this "ContentTable" table component with below hard coded table data */}

                <div className="cmnTable">
                  {/* <Table responsive striped>
                    <thead>
                      <tr>
                        <th>Risks of {view}</th>
                        <th>Avg Person</th>
                        <th>Your Staff</th>
                        <th>Alert</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                        topics.length > 1 ?
                          topics.map((items) => (
                            items.your > items.avg?
                            <tr key={items.title.toLowerCase().replace(" ","_")}>
                              <td>{items.title}</td>
                              <td className="text-center">
                                <span className="textcolor-black">{isNaN(items.your)? 0 : items.your}%</span>
                              </td>
                              <td className="text-center">
                                <span className="textcolor-black">{items.avg}%</span>
                              </td>
                              <td>
                                <span className="textcolor-red">{items.your-items.avg}% More Risk</span>
                              </td>
                              <td className="text-center">
                                <img src="/images/eyeiconopen.svg" className="filterit"></img>
                              </td>
                            </tr>
                            :null
                          ))
                        :
                        null
                      }
                    </tbody>
                  </Table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
};

export default connect(mapState, actionCreators)(ManagementInsight);

