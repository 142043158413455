import React from 'react';

class Disclaimer extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
    }
    

    render() {
        const divStyle = {
            maxHeight: '500px',
            overflowY: 'auto',
            color: 'black',
            lineHeight: '35px',
            padding: '5px'
        };
        
        return (
            <div style={divStyle}>
                <h4 style={{marginBottom: '20px'}}>Please read this disclaimer carefully before proceeding.</h4>
                <p style={{ color: 'black' }}>
                    The following disclaimer is intended to inform you about the results in this section. It is crucial to understand the limitations, potential risks and responsibilities associated with these results:
                </p>
                <p style={{ color: 'black' }}><b>Informational Purposes Only:</b></p>
                <p style={{ color: 'black' }}>
                    The genetic test results provided are for informational purposes only and should not be considered a substitute for professional medical advice, diagnosis or treatment. The test results do not provide a comprehensive evaluation of an individual's health and should not be solely relied upon to make important healthcare decisions.
                </p>
                <p style={{ color: 'black' }}><b>Limited Scope:</b></p>
                <p style={{ color: 'black' }}>
                    The genetic test results focus on specific genetic markers associated with certain diseases or conditions, these are listed in the “details” tab, depending on the type of testing used the coverage will differ (WGS vs. GSA). It does not provide a comprehensive analysis of an individual's overall health or cover all possible genetic variants or risk factors for a given disease. Other non-genetic factors should be taken into account.
                </p>
                <p style={{ color: 'black' }}><b>Interpretation Challenges:</b></p>
                <p style={{ color: 'black' }}>
                    Interpreting genetic test results can be complex and subject to ongoing scientific research and advancements. Variability in the accuracy of genetic testing technologies, limitations in available genetic data and evolving scientific understanding of gene-disease associations can affect the interpretation of results. Test results may be subject to change as new information becomes available.
                </p>
                <p style={{ color: 'black' }}><b>Risk Estimations:</b></p>
                <p style={{ color: 'black' }}>
                    Disease risk estimations provided by the genetic test are based on population studies and statistical models. They are not absolute predictions but rather provide probabilities or relative risks. Individual outcomes may differ significantly due to factors not accounted for in the analysis.
                </p>
                <p style={{ color: 'black' }}><b>False Positives and Negatives:</b></p>
                <p style={{ color: 'black' }}>
                    Genetic testing can yield false-positive or false-negative results. False-positive results may indicate an increased risk of disease when none exists, causing unwarranted anxiety or unnecessary medical interventions. False-negative results may provide a false sense of security, leading to neglect of appropriate preventive measures or screenings.
                </p>
                <p style={{ color: 'black' }}><b>Psychological Impact:</b></p>
                <p style={{ color: 'black' }}>
                    Genetic testing can have emotional and psychological consequences. Learning about disease risk may cause anxiety, stress or other adverse psychological effects. It is essential to consider these potential impacts and seek appropriate support and counselling if needed.
                </p>
                <p style={{ color: 'black' }}><b>Confidentiality and Privacy:</b></p>
                <p style={{ color: 'black' }}>
                    Genetic testing involves the disclosure of personal health information. While efforts are made to maintain the confidentiality and privacy of individuals' genetic data, there is always a risk of unintended data breaches or unauthorized access. It is important to carefully review and understand our privacy policy and data handling practices before proceeding.
                </p>
                <p style={{ color: 'black' }}><b>Test Limitations and Future Discoveries:</b></p>
                <p style={{ color: 'black' }}>
                    Genetic testing technologies and scientific knowledge are continuously evolving. New genetic variants, associations or risk factors may be discovered in the future that were not included in the initial analysis.
                </p>
                <p style={{ color: 'black' }}><b>
                    By proceeding, you acknowledge that you have read, understood and agree to the terms and conditions outlined in this disclaimer. You accept responsibility for interpreting the results and use these in conjunction with professional medical advice and assume any associated risks. It is recommended to consult with a healthcare professional before making any decisions regarding your health based on these genetic test results.
                </b></p>
            </div>
        )
    }
}

export default Disclaimer