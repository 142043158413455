import React from "react";
import { connect } from "react-redux";
import { alertActions } from "../../actions";

function mapState(state) {
  const { alert, authentication, current } = state;
  const { loggingIn, user } = authentication;
  return { loggingIn, current, user, alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};
function ShowAlert(restProps) {
  console.log(restProps);
  const { type, message, ...props } = restProps;
  return (
    <div className={`alert ${type}`}>
      {message}
      <button className="close" onClick={props.clearAlerts}>
        x
      </button>
    </div>
  );
}

const ShowAlertComp = connect(mapState, actionCreators)(ShowAlert);

function Alert(props) {
  console.log(props);
  if (Array.isArray(props.alert) && props.alert.length > 0) {
    return props.alert.map((item, index) => (
      <ShowAlertComp key={index} {...item} />
    ));
  }

  return props.alert.message ? (
    <div className={`alert ${props.alert.type}`}>
      {props.alert.message}
      <button className="close" onClick={props.handleClose}>
        x
      </button>
    </div>
  ) : null;
}

export default connect(mapState, actionCreators)(Alert);
