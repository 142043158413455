import { React } from "react";
import { Dropdown } from "react-bootstrap";
import { connect, useSelector } from "react-redux";

const UserMenu = (props) => {
  const { user } = useSelector((state) => state.authentication);
  
  return (
    <>
      <div className="userDetails">
        <h6>{`${user?.first_name || ""} ${user?.last_name || ""}`}</h6>
        <Dropdown className="customDropdown">
          <Dropdown.Toggle>
            <figure>
              <img className="img-fluid" src="/images/user.png"></img>
            </figure>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={props.modal()}>Profile</Dropdown.Item>
            <Dropdown.Item onClick={props.logout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default UserMenu;
