import React from "react";
import { Form, Modal, Button, Spinner, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiService } from '../../services/api_service'
import moment from 'moment';
import { alertActions } from '../../actions';
import { connect } from 'react-redux';
import { SortArray, nameRegex, regexKit, genderList } from "../../constants";
import CustomerKitType from "./CustomerKitType";
import { getPartnerURLs } from "../../helpers/partner";
class EditUser extends React.Component {

    constructor(props) {
        super(props)
        console.log(props.UserDetails);
        this.state = {
            show1: props.show ? props.show : false,
            show2: false,
            fname: '',
            lname: '',
            email: '',
            phnum: '',
            dob: '',
            gender: '',
            kitId: '',
            bloodKitId: '',
            departments: '',
            selected_dep: '',
            partner: '',
            selected_clinic: '',
            relation: [],
            terms_condition: false,
            research: false,
            newsletter: false,
            error: {
                fname: false,
                lname: false,
                email: false,
                kitId: false,
                bloodKitId: false,
                kitIdSelect: false,
                phnum: false,
                dob: false,
                gender: false,
                age: false,
                usertype: false,
                partner: false,
                selected_dep: false,
                selected_clinic: false,
                practitioner: false,
                relationShip: false,
                terms_condition: false
            },
            emailErrorMsg: 'Please enter valid email',
            kitErrorMsg: 'Please enter valid kit id',
            bloodKitErrMsg: 'Please enter valid kit id',
            loader: false,
            userDetails: props.UserDetails ? props.UserDetails : {},
            alert: {
                message: 'Error while update user',
                type: 'alert-danger'
            },
            clinics: [],
            userType: [
                { label: 'Partner', value: 'Partner' },
                { label: 'Clinic/ Sub-Partner', value: 'Clinic' },
                { label: 'Practitioner', value: 'Practitioner' }
            ],
            user_type: '',
            practitioner: '',
            practitionerList: [],
            show4: false,
            warning: false,
            privacy: "https://muhdo.com/privacy-policy/",
            terms: "https://muhdo.com/terms-conditions/",
            text: "Muhdo Health",
            blood_test_access: false,
            showGeneticKit: false,
            showBloodKit: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nameValidate = this.nameValidate.bind(this)
        this.EmailValidateHandler = this.EmailValidateHandler.bind(this)
        this.Validator = this.Validator.bind(this)
        this.kitIdValidateHandler = this.kitIdValidateHandler.bind(this)
        this.bloodKitIdValidateHandler = this.bloodKitIdValidateHandler.bind(this)
        this.DOBValidator = this.DOBValidator.bind(this)
        this.errorValidation = this.errorValidation.bind(this)
        this.phnValidator = this.phnValidator.bind(this)
        this.genderValidator = this.genderValidator.bind(this)
        this.handleDOBChange = this.handleDOBChange.bind(this)
        this.termsValidator = this.termsValidator.bind(this)
        this.handleBloodKitClick = this.handleBloodKitClick.bind(this)
        this.handleGeneticKitClick = this.handleGeneticKitClick.bind(this)
        this.saveCustomer = this.saveCustomer.bind(this)
    }

    async componentDidMount() {
        let user = this.props.user
        let practitioner_details = await apiService.practitionerDetails(user.email)
        let { privacy, terms, text } = getPartnerURLs(practitioner_details.partner_id)
        this.setState({ privacy, terms, text })
        await this.bloodTestAccess(this.props.access)
        console.log('component mounted')
        let userType = [
            { label: 'Practitioner', value: 'Practitioner' }
        ]


        if (practitioner_details.type == 'admin') {
            userType = [
                { label: 'Partner', value: 'Partner' },
                { label: 'Clinic/ Sub-Partner', value: 'Clinic' },
                { label: 'Practitioner', value: 'Practitioner' }
            ]
        }
        let partner_id = ''
        let clinic_id = ''
        let practitioner_id = ''
        let user_type = ''
        let departments = []
        if (practitioner_details && practitioner_details.type == 'partner') {
            partner_id = practitioner_details.partner_id
            departments = await apiService.departmentListAPI({ partner_id, practitioner_id: this.props.practitioner_id })
            departments = departments[0] ? departments[0].dept : []
            user_type = this.props.type == 'create' ? '' : 'Partner'
            userType = [
                { label: 'Partner', value: 'Partner' },
                { label: 'Clinic/ Sub-Partner', value: 'Clinic' },
                { label: 'Practitioner', value: 'Practitioner' }
            ]
        }
        if (practitioner_details && practitioner_details.type == 'clinic') {
            partner_id = practitioner_details.partner_id
            clinic_id = practitioner_details._id
            departments = await apiService.departmentListAPI({ partner_id, clinic_id, practitioner_id: this.props.practitioner_id })
            departments = departments[0] ? departments[0].dept : []
            user_type = this.props.type == 'create' ? '' : 'Clinic'
            userType = [
                { label: 'Clinic/ Sub-Partner', value: 'Clinic' },
                { label: 'Practitioner', value: 'Practitioner' }
            ]
        }
        if (practitioner_details && practitioner_details.type == 'practitioner') {
            partner_id = practitioner_details.partner_id
            clinic_id = practitioner_details.clinic_id
            practitioner_id = practitioner_details._id
            departments = await apiService.departmentListAPI({ partner_id, practitioner_id: this.props.practitioner_id })
            departments = departments[0] ? departments[0].dept : []
            user_type = this.props.type == 'create' ? '' : 'Practitioner'
            userType = [
                { label: 'Practitioner', value: 'Practitioner' }
            ]
        }
        if (practitioner_details && practitioner_details.type == 'admin') {
            userType = [
                { label: 'Partner', value: 'Partner' },
                { label: 'Clinic/ Sub-Partner', value: 'Clinic' },
                { label: 'Practitioner', value: 'Practitioner' }
            ]
        }

        if (this.props.UserDetails.gender === 'Male') {
            this.props.UserDetails.gender = '';
        }
        // console.log(partner_id,clinic_id,practitioner_id,user_type,'hhhhhhhhhhhhhhhhhhhhhhhhh')
        this.setState({
            userType,
            user_type: this.props.UserDetails && this.props.UserDetails.user_type ? this.props.UserDetails.user_type : user_type,
            fname: this.props.UserDetails.first_name ? this.props.UserDetails.first_name : '',
            lname: this.props.UserDetails.last_name ? this.props.UserDetails.last_name : '',
            email: this.props.UserDetails.email ? this.props.UserDetails.email : '',
            phnum: this.props.UserDetails.phone ? this.props.UserDetails.phone : '',
            dob: this.props.UserDetails.birthday ? new Date(this.props.UserDetails.birthday) : '',
            gender: this.props.UserDetails.gender ? this.props.UserDetails.gender : '',
            departments: this.props.departments ? this.props.departments : departments,
            selected_dep: this.props.UserDetails ? this.props.UserDetails.department : '',
            partner: this.props.UserDetails.partner_id ? this.props.UserDetails.partner_id : partner_id,
            selected_clinic: this.props.UserDetails && this.props.UserDetails.clinic_id && this.props.UserDetails.clinic_id.length > 0 ? this.props.UserDetails.clinic_id[0] : clinic_id,
            clinics: this.props.subPartnerList ? this.props.subPartnerList : [],
            practitioner: this.props.UserDetails && this.props.UserDetails.practitioner_id && this.props.UserDetails.practitioner_id != "" ? this.props.UserDetails.practitioner_id[0] : practitioner_id,
            practitionerList: this.props.practitionerList ? this.props.practitionerList : [],
            terms_condition: this.props.UserDetails.terms_and_condition ? true : false,
            research: this.props.UserDetails.research_purpose ? true : false,
            newsletter: this.props.UserDetails.newsletter ? true : false,
            kitId: this.props.UserDetails.kit_id ? this.props.UserDetails.kit_id : '',
            relation: this.props.UserDetails.relation ? this.props.UserDetails.relation : [],
            bloodKitId: this.props.UserDetails?.blood_kit_id ?? '',
            relationbloodKits: this.props.UserDetails?.bloodKits ?? [],
        })
        console.log("state in component mount", this.state)
    }

    async bloodTestAccess(access) {
        let blood_test_access = false
        if (access?.blood_test)
            blood_test_access = true
        this.setState({ blood_test_access })
    }

    selectHandler(e) {
        let user_id = e.target.value
        this.props.DetailsGetAPI(user_id, this.props.page)

    }
    async handleChange(e) {
        let { name, value } = e.target;
        console.log({ name, value });
        if (["kitId", "bloodKitId"].includes(name)) {
            value = value.toUpperCase();
        }
        this.setState({ [name]: value });

        setTimeout(() => {
            this.Validator(name);
        }, 0);
    }
    async handleDOBChange(date) {
        this.setState({ dob: date });
    }
    Validator(name) {
        switch (name) {
            case "fname":
                this.nameValidate(name);
                break;
            case "lname":
                this.nameValidate(name);
                break;
            case "phnum":
                this.phnValidator();
                break;
            case "dob":
                this.DOBValidator();
                break;
            case "email":
                this.EmailValidateHandler();
                break;
            case "gender":
                this.genderValidator();
                break;
            case "kitId":
                this.kitIdValidateHandler();
                break;
            case "bloodKitId":
                if (this.props?.access?.blood_test) {
                    this.bloodKitIdValidateHandler();
                }
                break;
            case "partner":
                this.deptValidator("partner");
                break;
            case "selected_dep":
                this.deptValidator("selected_dep");
                break;
            case "all":
            default:
                this.nameValidate("fname");
                this.nameValidate("lname");
                this.EmailValidateHandler();
                this.phnValidator();
                this.DOBValidator();
                this.KitTypeValidator();
                this.kitIdValidateHandler();
                if (this.props?.access?.blood_test) {
                    this.bloodKitIdValidateHandler();
                }
                this.userTypeValidator();
                this.termsValidator();
                this.genderValidator();
                break;
        }
    }

    nameValidate(name) {
        const text = this.state[name].trim();
        const fieldName = name === "fname" ? "first" : "last";
        // console.log(this.state[name])
        if (!text.length) {
            this.state.error[name] = true;
            this.state.error[
                `${name}_message`
            ] = `Please enter ${fieldName} name`;
            this.setState({ error: this.state.error });
        } else if (!nameRegex.test(text)) {
            this.state.error[name] = true;
            this.state.error[
                `${name}_message`
            ] = `Please enter a valid ${fieldName} name.`;
            this.setState({ error: this.state.error });
        } else {
            this.state.error[name] = false;
            this.state.error[`${name}_message`] = "";
            this.setState({ error: this.state.error });
        }
    }
    async phnValidator() {
        let phoneno = /^\+\d+$/
        if (!this.state.phnum.match(phoneno)) {
            this.state.error.phnum = true
        } else {
            this.state.error.phnum = false
        }
        this.setState({ error: this.state.error })
    }
    async deptValidator(name) {
        if (this.state[name] && this.state[name].length == 0 || this.state[name] == 'select') {
            this.state.error[name] = true
        } else {
            this.state.error[name] = false
        }
        this.setState({ error: this.state.error })
    }
    async termsValidator() {
        if (!this.state.terms_condition) {
            this.state.error.terms_condition = true
        } else {
            this.state.error.terms_condition = false
        }
        this.setState({ error: this.state.error })
    }

    async EmailValidateHandler() {
        let regexEmail = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if (!this.state.email || !this.state.email.match(regexEmail)) {
            this.state.error['email'] = true
            this.setState({ error: this.state.error, emailErrorMsg: 'Please enter valid email' })
            return
        } else {
            if (this.props.UserDetails.email == this.state.email) {
                this.state.error['email'] = false
                this.setState({ error: this.state.error })
            } else {
                let valid = await apiService.EmailValidator(this.state.email)
                if (valid) {
                    this.state.error['email'] = false
                    this.setState({ error: this.state.error })
                } else {
                    this.state.error['email'] = true
                    this.setState({ emailErrorMsg: 'Email id already exists', error: this.state.error })
                }

            }

        }
    }


    async handleGenderSelect(e) {
        const selectedGender = e.target.value;
        this.setState({ gender: selectedGender }); // Update gender state first

        // Validate the selected gender
        if (selectedGender === '' || selectedGender === 'select') {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    gender: true // Set error for gender
                }
            }));
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    gender: false // Clear error for gender
                }
            }));
        }
    }

    async handlePartnerSelect(e) {
        let type = e.target.name
        let value = e.target.value
        let { error } = this.state
        let departments = []
        let clinics = []
        let practitionerList = []
        if (value != 'select') {
            if (value) {
                departments = await apiService.departmentListAPI({ partner_id: value, practitioner_id: this.props.practitioner_id })
                // console.log(departments,'departments')
                departments = departments[0] ? departments[0].dept : []
            }
            if (this.state.user_type === 'Clinic' && this.props.practitioner_type === 'admin') {
                clinics = await apiService.ClinicList({ partner_id: value, practitioner_id: this.props.practitioner_id })
                // console.log(clinics,'departments')
                clinics = clinics.length ? clinics : []
            }
            if (this.state.user_type === 'Practitioner') {
                practitionerList = await apiService.practitionerList({ practitioner_id: this.props.practitioner_id, partner_id: value })
                // console.log(practitionerList,'practitioner')
                practitionerList = practitionerList.length ? practitionerList : []
                clinics = await apiService.ClinicList({ partner_id: value, practitioner_id: this.props.practitioner_id })
                // console.log(clinics,'departments')
                clinics = clinics.length ? clinics : []
            }
            error = { ...error, partner: false }
        }
        this.setState({ [type]: value, departments, clinics, selected_dep: '', selected_clinic: '', practitioner: '', practitionerList, error });
    }

    async handleSubPartnerSelect(e) {
        let type = e.target.name
        console.log(type, e, 'type')
        let value = e.target.value, error = this.state.error
        let departments = [], practitionerList = []

        if (value !== 'select') {
            departments = await apiService.departmentListAPI({ clinic_id: value, practitioner_id: this.props.practitioner_id, partner_id: this.state.partner })
            departments = departments[0] ? departments[0].dept : []

            if (this.state.user_type === 'Practitioner') {
                practitionerList = await apiService.practitionerList({ practitioner_id: btoa(value) })
                practitionerList = practitionerList ? practitionerList : []
            }
            error = { ...error, selected_clinic: false }
        }
        this.setState({ [type]: value, departments, selected_dep: '', practitioner: '', practitionerList, error });
    }

    async handleUserType(e) {
        let value = e.target.value
        console.log(value, this.state)
        let stateValues = { user_type: value }
        if (value === 'select' && e.target.name === 'Partner') {
            stateValues.selected_clinic = ''
            stateValues.selected_dep = ''
            stateValues.partner = ''
            stateValues.practitioner = ''

        } else if (value === 'select' && e.target.name === 'Clinic') {
            stateValues.selected_clinic = ''
            stateValues.selected_dep = ''
            stateValues.practitioner = ''

        } else if (value === 'select' && e.target.name === 'Practitioner') {
            stateValues.practitioner = ''
        }

        if (value === 'Partner' && this.props.practitioner_type === 'partner') {
            //  console.log(this.state.partner,'this.state.partner_id')
            let departmentList = await apiService.departmentListAPI({ partner_id: this.state.partner, practitioner_id: this.props.practitioner_id })
            departmentList = departmentList[0] ? departmentList[0].dept : []
            // console.log(departmentList,'departmentList')
            stateValues.departments = departmentList
        }
        if (value === 'Clinic' && this.props.practitioner_type === 'clinic') {
            // console.log(this.state.partner,'this.state.partner_id')
            let departmentList = await apiService.departmentListAPI({ partner_id: this.state.partner, practitioner_id: this.props.practitioner_id, clinic_id: this.state.selected_clinic })
            departmentList = departmentList[0] ? departmentList[0].dept : []
            //    console.log(departmentList,'departmentList')
            stateValues.departments = departmentList
        }
        stateValues.error = { ...this.state.error, usertype: false };
        // console.log(stateValues, "sssssssssssss")

        this.setState(stateValues)
    }
    async handleDeptSelect(e) {
        let value = e.target.value
        // console.log(value)
        this.setState({ selected_dep: value })
    }

    async handlePractitionerSelect(e) {
        let value = e.target.value, error = this.state.error;
        // console.log(value)
        if (value != 'select') {
            error = { ...error, practitioner: false }
        }
        this.setState({ practitioner: value, error })
    }

    async genderValidator() {
        if (this.state.gender == '' || this.state.gender == 'select') {
            this.state.error.gender = true
        } else {
            this.state.error.gender = false
        }
        this.setState({ error: this.state.error })
    }

    async userTypeValidator() {
        console.log(this.state, 'typeee', this.props.practitioner_type)
        const { practitioner_type } = this.props;
        const { error } = this.state

        if (practitioner_type != 'practitioner') {
            if (this.state.partner == '' || this.state.partner == 'select') {
                error.partner = this.props.practitioner_type == 'partner' ? false : true
            }
            console.log("errrrrrrr", error)
            this.setState({ error })
        }
    }

    async kitIdValidateHandler() {
        if (this.props.type == 'create') {

            if (this.state.blood_test_access && !this.state.showGeneticKit) {
                this.state.error.kitId = false
                this.setState({ error: this.state.error })
                return
            }
            if (!this.state.kitId || !this.state.kitId.match(regexKit)) {
                this.state.error.kitId = true
                this.setState({ error: this.state.error, kitErrorMsg: 'Please enter valid kit id' })
            } else {

                let valid = await apiService.KitIdValidator(this.state.kitId)
                if (valid.status == 200) {
                    this.state.error.kitId = false
                    this.setState({ error: this.state.error })
                } else {
                    this.state.error.kitId = true
                    this.setState({ kitErrorMsg: valid.message || "Invalid Kit Id", error: this.state.error })
                }

            }
        } else {
            this.state.error.kitId = false
            this.setState({ error: this.state.error })
        }

    }

    async bloodKitIdValidateHandler() {
        if (this.props.type === "create") {
            if (this.state.blood_test_access && !this.state.showBloodKit) {
                this.state.error.bloodKitId = false;
                this.setState({ error: this.state.error });
                return;
            }
            if (
                !this.state.bloodKitId ||
                !this.state.bloodKitId.match(regexKit)
            ) {
                this.state.error.bloodKitId = true;
                this.setState({
                    error: this.state.error,
                    bloodKitErrMsg: "Please enter valid kit id",
                });
            } else {
                let valid = await apiService.CheckAssignBloodKitStatus({
                    kit_id: this.state.bloodKitId,
                });
                console.log("blood kit validator", valid);
                if (valid.status === 200) {
                    this.state.error.bloodKitId = false;
                    this.setState({ error: this.state.error });
                } else {
                    this.state.error.bloodKitId = true;
                    this.setState({
                        bloodKitErrMsg: valid.message || "Invalid Kit Id",
                        error: this.state.error,
                    });
                }
            }
        } else {
            this.state.error.bloodKitId = false;
            this.setState({ error: this.state.error });
        }
    }

    async DOBValidator() {
        let today = moment().format("YYYY-MM-DD")
        let diff = moment().diff(moment(this.state.dob, 'YYYYMMDD'), 'years')
        if (!this.state.dob || this.state.dob > today) {
            this.state.error.dob = true
        } else {
            this.state.error.dob = false
        }
        if (diff < 18 && !this.state.warning) {
            this.state.error.age = true
            this.state.show4 = true
        } else {
            this.state.error.age = false
            this.state.show4 = false
        }
        this.setState({ error: this.state.error })

    }


    errorValidation() {
        let error_states = Object.keys(this.state.error).find(key => this.state.error[key] === true);
        if (error_states) {
            return false
        } else {
            return true
        }
    }

    async saveCustomer() {
        if (this.errorValidation()) {
            let req = {
                first_name: this.state.fname.trim(),
                last_name: this.state.lname.trim(),
                email: this.state.email.trim(),
                phone: this.state.phnum.trim(),
                birthday: moment(this.state.dob).format("MM-DD-YYYY"),
                gender: this.state.gender,
                terms_and_condition: this.state.terms_condition,
                partner_id: this.state.partner,
            };

            if (this.state.selected_dep && this.state.selected_dep !== "select") {
                req["department"] = btoa(this.state.selected_dep)
            }

            if (this.props?.type === "create") {
                if (!this.state.blood_test_access && this.state.kitId) {
                    req.kit_id = this.state.kitId;
                }

                if (this.state.blood_test_access) {
                    if (this.state.showGeneticKit && this.state.kitId) {
                        req.kit_type = "genetic";
                        req.kit_id = this.state.kitId;
                    }
                    if (this.state.showBloodKit && this.state.bloodKitId) {
                        req.kit_type = "blood";
                        req.blood_kit_id = this.state.bloodKitId;
                    }
                    if (this.state.showGeneticKit && this.state.showBloodKit) {
                        req.kit_type = "both";
                    }
                }
            }

            const { practitionerDetails } = this.props;

            if (practitionerDetails) {
                if (["admin", "partner"].includes(practitionerDetails.type)) {
                    req.user_type = "Partner";
                } else if (practitionerDetails.type === "clinic") {
                    req.user_type = "Clinic";
                } else if (practitionerDetails.type === "practitioner") {
                    req.user_type = "Practitioner";
                }
            }

            req.research_purpose = this.state.research || false;
            req.newsletter = this.state.newsletter || false;

            console.log(req, 'reqqqqqqqqqq');

            let resp = await this.props.updateAPI(req);

            if (req.blood_kit_id) {
                const body = {
                    user_id: Buffer.from(String(resp._id)).toString('base64'),
                    kit_id: req.blood_kit_id,
                    //practitioner_id: this.props.practitioner_id
                };
                let bloodkitResp = await apiService.assignBloodKitToUser(body)
                console.log("bloodkitResp", bloodkitResp)
            }
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        //Todo validation

        // const { filter_type, practitioner_type } = this.props;
        // if (
        //   filter_type === "Practitioner" ||
        //   practitioner_type === "practitioner"
        // ) {
        //   console.log("kit validator");
        //   this.Validator("kitId");
        // } else {
        // this.Validator("all");
        // }

        this.Validator("all");
        setTimeout(() => {
            console.log(this.errorValidation(), 'errrrrrrrrrrr', this.state.error);
            this.saveCustomer()
        }, 0)
    }

    addUserHandler = () => {
        this.setState({ show1: true })
    }
    handleClose1 = () => {
        this.setState({
            fname: '',
            lname: '',
            email: '',
            phnum: '',
            dob: '',
            gender: '',
            kitId: '',
            bloodKitId: '',
            departments: '',
            selected_dep: '',
            partner: '',
            selected_clinic: '',
            user_type: '',
            relation: [],
            terms_condition: false,
            research: false,
            newsletter: false,
            error: {
                fname: false,
                lname: false,
                email: false,
                kitId: false,
                bloodKitId: false,
                kitIdSelect: false,
                phnum: false,
                dob: false,
                gender: false,
                partner: false,
                clinic: false,
                practitioner: false,
                selected_dep: false,
                selected_clinic: false,
                relationShip: false,
                terms_condition: false,
            },
        })
        this.props.closeModal()
    }
    handleShow1 = () => this.setState({ show1: true })
    handleClose = () => {
        this.props.clearAlert();
    }

    handleGeneticKitClick(event) {
        let updatedState = {
            ...this.state,
            showGeneticKit: event.target.checked,
        };
        if (!event.target.checked) {
            updatedState = {
                ...updatedState,
                kitId: "",
                error: { ...this.state.error, kitId: false },
                kitErrorMsg: "",
            };
        }
        this.setState({ ...updatedState }, () => {
            this.KitTypeValidator();
        });
    }
    handleBloodKitClick(event) {
        let updatedState = {
            ...this.state,
            showBloodKit: event.target.checked,
        };
        if (!event.target.checked) {
            updatedState = {
                ...updatedState,
                bloodKitId: "",
                error: { ...this.state.error, bloodKitId: false },
                bloodKitErrMsg: "",
            };
        }
        this.setState({ ...updatedState }, () => {
            this.KitTypeValidator();
        });
    }

    KitTypeValidator() {
        const { blood_test_access, showGeneticKit, showBloodKit } = this.state;
        if (!blood_test_access || this.props.type === 'edit') return;

        let { error } = this.state

        if (!showGeneticKit && !showBloodKit) {
            error.kitIdSelect = true;
        } else {
            error.kitIdSelect = false;
        }
        this.setState({ error })
    }
    render() {
        const {
            fname, lname, email, phnum, dob, gender, kitId, relation, error, emailErrorMsg,
            kitErrorMsg, alert, terms_condition, research, newsletter, show4, privacy, terms,
            text, blood_test_access, showGeneticKit, bloodKitId, showBloodKit, bloodKitErrMsg, relationbloodKits
        } = this.state;
        const { show, showAlert, title } = this.props;

        console.log(showGeneticKit, showBloodKit);

        return (
            <>
                <Modal show={show} onHide={this.handleClose1} centered className="userModal" size="sm" onEntered={() => this._input.focus()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form autoComplete='off'>
                            {showAlert &&
                                <div className={`alert ${alert.type}`} style={{ marginBottom: 15 }}>
                                    {alert.message}
                                    <button class="close" onClick={this.handleClose} >x</button>
                                </div>
                            }
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter first name" name='fname' value={fname} onChange={this.handleChange} required ref={input => this._input = input} />
                                <Form.Text className="text-muted">
                                    {error.fname ? error.fname_message || 'Please enter first name' : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter last name" name='lname' value={lname} onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                    {error.lname ? error.lname_message || 'Please enter last name' : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" name='email' value={email} onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                    {error.email ? emailErrorMsg : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" placeholder="Enter phone number eg:(+1987654321)" name='phnum' value={phnum} onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                    {error.phnum ? 'Please enter valid phone number eg:(+1987654321) ' : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Date of Birth</Form.Label>
                                <div >
                                    <DatePicker
                                        className="form-control"
                                        selected={dob}
                                        onChange={this.handleDOBChange}
                                        onClickOutside={this.openDatePicker}
                                        maxDate={new Date()}
                                        placeholderText="DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                    ></DatePicker>
                                </div>


                                <Form.Text className="text-muted">
                                    {error.dob ? 'Please enter valid date of birth' : null}
                                    {error.age ?
                                        <Modal show={show4} onHide={() => { this.setState({ show4: false, warning: true }) }} className="dob-warning" centered size="sm" >
                                            <Modal.Header closeButton className="dob-warning-head">
                                                <Modal.Title>Warning</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="user-management-modal">
                                                <h5>Please note the date of birth you have entered makes the customer younger than 18 years old.<br></br> Before proceeding, please amend the date of birth if this is not correct.</h5>
                                                <Button style={{ marginLeft: "34%", marginTop: "2%" }} className="bgBtn mr-3" type="submit" size='sm' onClick={() => { this.setState({ show4: false, warning: true }) }}>OK</Button>
                                            </Modal.Body>
                                        </Modal> : null}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Select Gender</Form.Label>
                                <Form.Control as="select" name='gender' value={gender} className="multiselect-patients" onChange={(e) => this.handleGenderSelect(e)}>
                                    <option selected value='select'>--Select Gender--</option>
                                    {genderList && SortArray(genderList)?.map(gender => (
                                        <option key={gender.key} value={gender.value}>{gender.label}</option>)
                                    )}

                                </Form.Control>
                                <Form.Text className="text-muted">
                                    {error.gender && 'Please select a gender'}
                                </Form.Text>
                            </Form.Group>

                            <CustomerKitType
                                {...{
                                    error,
                                    kitId,
                                    kitErrorMsg,
                                    handleChange: this.handleChange,
                                    type: this.props.type,
                                    relation,
                                    blood_test_access,
                                    bloodKitId,
                                    bloodKitErrMsg,
                                    showGeneticKit,
                                    showBloodKit,
                                    handleGeneticKitClick: this.handleGeneticKitClick,
                                    handleBloodKitClick: this.handleBloodKitClick,
                                    relationbloodKits,
                                }}
                            />





                            {/* { this.props.type =='create' && this.props.practitioner_type !=='practitioner' &&
                                <Form.Group controlId="formBasicEmail">
                                <Form.Label>Who can view the Customer?</Form.Label>
                                <Form.Control as="select" name ='user_type' value={this.state.user_type} className="multiselect-patients" onChange={(e) => this.handleUserType(e)}>
                                    <option selected value =''>--Select Who can view the Customer?--</option>
                                        {userType&& userType.map(user =>(
                                            <option key={user.value} value={user.value}>{user.label}</option>)
                                            )}
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    {error.usertype ? 'Select User Type' : null}
                                </Form.Text>
                                </Form.Group>
                            } */}
                            {/* { this.props.type =='edit' &&
                                <Form.Group controlId="formBasicEmail">
                                <Form.Label>User Type</Form.Label>
                                <Form.Control type="text" placeholder="User type not found" name='kitId' value={this.state.user_type} disabled = {true}  />
                                </Form.Group>
                            } */}
                            {this.props.practitioner_type == 'admin' && this.props.type == 'edit' &&
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Select Partner</Form.Label>
                                    <Form.Control as="select" name='partner' value={this.state.partner} disabled className="multiselect-patients" onChange={(e) => this.handlePartnerSelect(e)}>
                                        <option selected value='select'>--Select Partner--</option>
                                        {this.props?.partnerList && SortArray(this.props.partnerList)?.map(user => (
                                            <option key={user._id} value={user.partner_id}>{user.first_name} {user.last_name}</option>)
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            }
                            {this.props.practitioner_type === 'admin' && this.props.type === 'create' &&
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Select Partner</Form.Label>
                                    <Form.Control as="select" name='partner' value={this.state.partner} className="multiselect-patients" onChange={(e) => this.handlePartnerSelect(e)}>
                                        <option selected value='select'>--Select Partner--</option>
                                        {this.props?.partnerList && SortArray(this.props.partnerList)?.map(user => (<option key={user._id} value={user.partner_id}>{user.first_name} {user.last_name}</option>))}
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        {error.partner ? 'Select Partner' : null}
                                    </Form.Text>
                                </Form.Group>
                            }
                            {/* {
                               (this.props.practitioner_type =='admin' || this.props.practitioner_type =='partner') && (user_type =='Clinic' || user_type =='Practitioner') &&
                                <Form.Group controlId="formBasicEmail">
                                <Form.Label>Select Sub-Partner/ Clinic</Form.Label>
                                <Form.Control as="select" value={this.state.selected_clinic} name ='selected_clinic' className="multiselect-patients" onChange={(e) => this.handleSubPartnerSelect(e)}>
                                    <option selected value ='select'>--Select Sub-Partner/ Clinic--</option>
                                        {this.state.clinics&& this.state.clinics.map(user =>(
                                            <option key={user._id} value={user._id}>{user.first_name} {user.last_name}</option>)
                                            )}
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    {error.selected_clinic ? 'Select Clinic' : null}
                                </Form.Text>
                                </Form.Group>
                            } */}
                            {(this.state.partner || this.state.selected_clinic) &&
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Custom Filters</Form.Label>
                                    <Form.Control as="select" value={this.state.selected_dep} name='selected_dep' className="multiselect-patients" onChange={(e) => this.handleDeptSelect(e)}>
                                        <option selected value='select'>--Select Custom Filters--</option>
                                        {
                                            this.state?.departments && SortArray(this.state.departments, "department")?.map(dep => (
                                                <option key={dep._id} value={dep._id}>{dep.department}</option>)
                                            )}
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        {error.selected_dep ? 'Select department' : null}
                                    </Form.Text>
                                </Form.Group>
                            }
                            {/* { (this.props.practitioner_type !='practitioner' && user_type =='Practitioner') &&
                                <Form.Group controlId="formBasicEmail">
                                <Form.Label>Select Practitioner</Form.Label>
                                <Form.Control as="select" name ='practitioner' value={this.state.practitioner} className="multiselect-patients" onChange={(e) => this.handlePractitionerSelect(e)}>
                                    <option selected value ='select'>--Select Practitioner--</option>
                                        {this.state.practitionerList&& this.state.practitionerList.map(user =>(
                                            <option key={user._id} value={user._id}>{user.first_name} {user.last_name}</option>)
                                            )}
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    {error.practitioner ? 'Select Practitioner' : null}
                                </Form.Text>
                                </Form.Group>
                            } */}
                            <Form.Group controlId="formBasicCheckbox" className="customRadio">
                                <input checked={terms_condition} onClick={() => { this.setState({ terms_condition: !this.state.terms_condition }) }} type="radio" label="Terms and Condition" className="termsRadioBtn" />
                                <Form.Label className="radioForm">Our end user client has read and agreed to the <a href={terms} target="_blank" style={{ color: '#09a79e', pointerEvents: this.state.partner === 50 ? 'none' : '' }}>Terms & Conditions</a> and <a href={privacy} target="_blank" style={{ color: '#09a79e', pointerEvents: this.state.partner === 50 ? 'none' : '' }}>Privacy Policy</a></Form.Label>
                                <Form.Text className="text-muted">
                                    {error.terms_condition ? 'Please accept the terms and condition and privacy policy' : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox" className="customRadio">
                                <input checked={research} onClick={() => { this.setState({ research: !this.state.research }) }} type="radio" label="Research Purpose" className="termsRadioBtn" />
                                <Form.Label className="radioForm">Our end user client agrees that {text} can use their data for research purposes in order to develop and provide hyper-personalised epigenetic recommendations <span style={{ fontStyle: "italic" }}> (please note without consent, your client's epigenetic recommendations will remain generic and future epigenetic aspects will not be made available)</span>
                                </Form.Label>
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox" className="customRadio">
                                <input checked={newsletter} onClick={() => { this.setState({ newsletter: !this.state.newsletter }) }} type="radio" label="Newsletter" className="termsRadioBtn" />
                                <Form.Label className="radioForm">Our end user client agrees to receive updates to their genetic reports and future offers<span style={{ fontStyle: "italic" }}>(please note without consent, your client will not receive new DNA aspects)</span>
                                </Form.Label>
                            </Form.Group>
                            <Button className="continuebtn" type="submit" size='sm' onClick={this.handleSubmit}>
                                {this.props.submitted ? <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style={{ float: 'center' }} /> : 'SUBMIT'}
                            </Button>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert, user };
}

const actionCreators = {
    clearAlerts: alertActions.clear
}

export default connect(mapState, actionCreators)(EditUser)
