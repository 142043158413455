import React from 'react'
import debounce from 'lodash.debounce'

export default class TabItems extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            n: [...Array(25).keys()],
            hasOverflow: false,
            canScrollLeft: false,
            canScrollRight: false
        }           
        this.checkForOverflow = this.checkForOverflow.bind(this)
        this.checkForScrollPosition = this.checkForScrollPosition.bind(this)

        this.debounceCheckForOverflow = debounce(this.checkForOverflow, 1000)
        this.debounceCheckForScrollPosition = debounce(
            this.checkForScrollPosition,
            200
        )
        this.container = null
    }

    componentDidMount() {
        this.checkForOverflow()
        // this.checkForScrollPosition()

        this.container.addEventListener(
            'scroll',
            this.debounceCheckForScrollPosition
        )
    }

    componentWillUnmount() {
        this.container.removeEventListener(
            'scroll',
            this.debounceCheckForScrollPosition
        )
        this.debounceCheckForOverflow.cancel()
    }

    componentDidUpdate(nextProps, prevState) {
        if (this.props.tab && this.props.tab.length !== nextProps.tab.length) {
            this.checkForOverflow()
            this.checkForScrollPosition()
        }
    }

    checkForScrollPosition() {
        const { scrollLeft, scrollWidth, clientWidth } = this.container
        this.setState({
            canScrollLeft: scrollLeft > 0,
            canScrollRight: this.compare(scrollLeft, scrollWidth - clientWidth) == 1? false: scrollLeft !== scrollWidth - clientWidth
        })
    }

    compare(x, y){
        return x-y
    }

    checkForOverflow() {
        const { scrollWidth, clientWidth } = this.container
        const hasOverflow = scrollWidth > clientWidth
        this.setState({ hasOverflow })
    }



    scrollContainerBy(distance) {
        this.container.scrollBy({ left: distance, behavior: 'smooth' })
    }



    buildControls() {
        const { canScrollLeft, canScrollRight } = this.state
        return (
            <div className="tabItem-controls">
                { 
                    this.props.tab.length > 0 &&
                    <>
                        <button
                            type="button"
                            className="leftBtn"
                            disabled={!canScrollLeft}
                            onClick={() => {
                                this.scrollContainerBy(-200)
                            }}
                        >
                        
                        <img width={18} height={18} src="/images/arrow-left.svg" className="filterit"></img>
                        </button>
            
                        <button
                            type="button"
                            className="rightBtn"
                            disabled={!canScrollRight}
                            onClick={() => {
                                this.scrollContainerBy(200)
                            }}
                        >
                            <img width={18} height={18} src="/images/arrow-right.svg" className="filterit"></img>
                        </button>
                    </>
                }
                
            </div>
        )
    }

    render() {
        return (
            <>
            <div className="tabContainer">
                <ul                  
                    ref={node => {
                        this.container = node
                    }}
                >
                    {this.props.tab.map((tab, index)=>(
                        <li onClick={()=>this.props.categorySelector(tab)} className={tab.toUpperCase() == this.props.category.toUpperCase() ? 'tabItem active' : 'tabItem'} key={index}  value={tab}>
                        {tab.toUpperCase()}
                    </li>
                    ))}
                    
                </ul>
                {this.buildControls()}
                </div>
            </>
        )
    }
}
