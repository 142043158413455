import { combineReducers } from 'redux';

import { authentication } from './authentication-reducer';
import { registration } from './registeration-reducer';
import { users } from './users-reducer';
import { alert } from './alert-reducer';
import { current } from './current-reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  current
});

export default rootReducer;