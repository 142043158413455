import { React } from "react";
import { Spinner } from "react-bootstrap";

const Loader = (props) => {

    return (
        <>
            {
                props.loading ?
                <div className="loader-wrp" ><Spinner animation="border" variant="info" className='spinnerClass' /></div>
                : null
            }
        </>
    )

}

export default Loader