import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Loader from "../../components/Loader/Loader";
import { alertActions } from '../../actions';
import { apiService } from "../../services/api_service";

class SetPassword extends React.Component {
    constructor(props) {
        super(props)
        // this.props.logout(this.props.history);

        this.state = {
            heightCm: "165",
            heightFt: "5",
            heightIn: "5",
            heightUnit: "cm",
            weightUnit: "lbs",
            weightLbs: "164",
            weightKg: "74",
            weightGm: "8",
            user_id: "",
            email: "",
            gender: "",
            submitted: false,
            hide: true,
            loading: false,
            passwordError: false,
            passwordFormat: false,
            hide1: true,
            confirmError: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillMount() {
        this.setState({ loading: true })
    }

    async componentDidMount() {
        // set default color
        document.getElementById("bodyID").style.setProperty('--themecolor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--themefontcolor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#6e2b8b');
        document.getElementById("bodyID").style.setProperty('--filterSvg', '');
        // let user_id = this.props.match.params.user;
        // console.log("USer token", user_id)
        // let resp = await apiService.verifyResetLink(user_id);
        // console.log("resp", resp)
        this.setState({
            // user_id,
            // email: resp.email,
            loading: false
        });
        console.log("props", this.props)

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    async handleSubmit(e) {
        this.setState({ submitted: true });
        const { heightCm, heightIn, heightFt, heightUnit, weightUnit, weightKg, weightGm, weightLbs, gender } = this.state;
        console.log("weightGm", weightGm)
        let data = {
            height: heightUnit == "cm" ? heightCm : parseInt(heightFt) + (0.0833 * parseInt(heightIn)),
            weight: weightUnit == "lbs" ? weightLbs : weightKg + "." + weightGm,
            height_unit: heightUnit,
            weight_unit: weightUnit,
            gender
        }

        console.log("data for api", data);
        // let resp = await apiService.userSetPassword(data);
        // if (resp.success) {
        //     this.props.success("Successfully set the password")
        //     setTimeout(() => {
        //         this.props.history.push("/signin")
        //     }, 3000)
        // } else {
        //     this.props.error(resp.message)
        // }
    }

    handleClose = () => {
        this.props.clearAlerts();
    }
    render() {
        const { heightCm, heightFt, heightIn, heightUnit, weightUnit, weightLbs, weightKg, weightGm, submitted, loading } = this.state;
        const { alert } = this.props;
        return (
            <div className="formPart forgotForm">
                {alert.message &&
                    <div className={`alert ${alert.type}`} style={{ marginBottom: 0 }}>
                        {alert.message}
                        <button className="close" onClick={this.handleClose} >x</button>
                    </div>
                }
                <Loader loading={loading} />
                <h1>Complete Signup</h1>
                <h6>Please enter the following details to complete your signup</h6>
                <Form>
                        <Form.Label>How tall are you?</Form.Label>
                    <Form.Group className="sign-field input-group">
                        {heightUnit == "cm" ?
                            <Form.Control placeholder="Height" type="number" min={12} max={275} name="heightCm" value={heightCm} required onChange={this.handleChange}/>
                            :
                            <>
                                <Form.Control placeholder="Height" type="number" min={1} max={8} name="heightFt" value={heightFt} required onChange={this.handleChange}/>
                                <Form.Control placeholder="Height" type="number" min={0} max={11} name="heightIn" value={heightIn} required onChange={this.handleChange}/>
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </>
                        }
                        {/* <Form.Check inline type='radio' name="heightUnit" value="cm" label="cm" onChange={this.handleChange}/>
                        <Form.Check inline type='radio' name="heightUnit" value="ft" label="ft" onChange={this.handleChange} /> */}
                        <select name="heightUnit" onChange={this.handleChange}>
                            <option>CM</option>
                            <option>FT</option>
                        </select>
                    </Form.Group>
                        <Form.Label>What is your weight?</Form.Label>
                    <Form.Group className="sign-field input-group">
                        {weightUnit == "lbs" ?
                            <Form.Control placeholder="Weight" type="number" min={12} max={275} name="weightLbs" value={weightLbs} required onChange={this.handleChange}/>
                            :
                            <>
                                <Form.Control inline="true" placeholder="Weight" type="number" min={1} max={200} name="weightKg" value={weightKg} required onChange={this.handleChange}/>
                                <Form.Control inline="true" placeholder="Weight" type="number" min={0} max={9} name="weightGm" value={weightGm} required onChange={this.handleChange}/>
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </>
                        }
                        {/* <Form.Check inline type='radio' name="weightUnit" value="kg" label="kg" onChange={this.handleChange} />
                        <Form.Check inline type='radio' name="weightUnit" value="lbs" label="lbs" onChange={this.handleChange} /> */}
                        <select name="weightUnit" onChange={this.handleChange}>
                            <option value="lbs">LBS</option>
                            <option value="kg">KG</option>
                        </select>
                    </Form.Group>
                    <Form.Label>What is your gender?</Form.Label>
                    <Form.Group className="sign-field input-group">
                        <Form.Check inline type='radio' name="gender" value="male" label="Male" onChange={this.handleChange} />
                        <Form.Check inline type='radio' name="gender" value="female" label="Female" onChange={this.handleChange} />
                    </Form.Group>
                    <Button className="continuebtn" onClick={this.handleSubmit}>
                        Submit
                    </Button>
                </Form>
            </div>
        );
    }
};

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    success: alertActions.success,
    error: alertActions.error
}
export default connect(mapState, actionCreators)(SetPassword);
// export default SetPassword;
