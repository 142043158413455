import React from 'react';

class Intro extends React.Component {
    constructor(props){
        super()
        this.state = {

        }
    }

    render() {
        return (
            <div className="intro-text">
                <h6>Welcome to HelixPortal.Health, which has been specially designed to help health practitioners and clinicians to identify key genomic and epigenomic health markers that could be affecting their client’s long-term personal goals or relating to healthy ageing and lifestyle.</h6><br />
                <h6>HelixPortal.Health is based on the latest genomic, epigenomic and lifestyle information and technology available, which provides a holistic platform and specific insights for clinicians and practitioners to help their clients to improve their health or overcome chronic conditions and live a longer, healthier and happier life.</h6><br />
                <h6>A first of its kind, HelixPortal.Health provides a unique health ecosystem, which is underpinned by a specific panel of genetic and epigenetic biomarkers with real time health and fitness tracker data such as steps, sleep, active time, heart rate, and pollution etc, which is also crucially aligned to your clients biological age.</h6>
            </div>
        )
    }
}

export default Intro