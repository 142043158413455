import React from "react";
import { connect } from "react-redux";
import { currentActions } from "../../actions";

import Select from "react-select";
import AsyncLookup from "../AsyncLookup/AsyncLookup";

const style = {
  blockquote: {
    fontStyle: "italic",
    fontSize: ".75rem",
    margin: "1rem 0",
  },
  label: {
    fontSize: ".75rem",
    fontWeight: "bold",
    lineHeight: 2,
  },
};

class SelectUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      name: "",
      IsMenuOpen: false,
      ariaFocusMessage: "",
      selectedUser: { value: "select", label: "Type to search" },
      inputValue: "",
    };
  }

  async componentWillReceiveProps(nextProps) {}

  componentDidUpdate() {
    // Dont Use componentDidUpdate due to infinite loop in CompareDetailsGetAPI
  }

  async compareSelectHandler(e) {
    if (!e) {
      this.setState({
        selected: {
          value: "select",
          label: "Type to search",
        },
        hide: true,
      });
      this.props.remove("", "", this.props.page);
      this.props?.onUserClear?.();
      return;
    }

    let user_id = e.value;
    let name = e.label;

    this.setState(
      { selectedUser: { value: user_id, label: name } },
      async () => {
        await this.props.CompareDetailsGetAPI(user_id, name);
      }
    );
  }

  onMenuOpen = () => this.setState({ isMenuOpen: true });
  onMenuClose = () => this.setState({ isMenuOpen: false });

  onFocus = () => {};

  render() {
    const { UserList, buttonTitle, handleAdd, DisplayText } = this.props;
    let { selectedUser, isMenuOpen, ariaFocusMessage } = this.state;
    return (
      <>
        <div className="col-12 col-sm-auto d-flex">
          <p
            style={{
              color: "white",
              fontSize: "1.2em",
              paddingRight: "15px",
            }}
          >
            {DisplayText}
          </p>
          {!!ariaFocusMessage && !!isMenuOpen && (
            <blockquote style={style.blockquote}>
              "{ariaFocusMessage}"
            </blockquote>
          )}

          <AsyncLookup
            ariaLiveMessages={this.onFocus()}
            inputId="aria-example-input"
            value={selectedUser}
            onChange={(e) => this.compareSelectHandler(e)}
            inputValue={this.state.inputValue}
            onInputChange={(inputValue) => {
              this.setState({ inputValue });
            }}
            type={this.props.type || "genetic"}
            {...this.props}
          />

          {/* <Select
            className="select-options"
            aria-labelledby="aria-label"
            ariaLiveMessages={this.onFocus()}
            value={selectedUser}
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={this.onMenuOpen}
            onMenuClose={this.onMenuClose}
            options={UserList}
            onChange={(e) => this.compareSelectHandler(e)}
          />
          {selectedUser.value !== "select" ? (
            <button
              onClick={() => {
                this.compareSelectHandler({
                  value: "select",
                  label: "Type to search",
                })
                this.props?.onUserClear?.()
              }
              }
              className="search-close"
            >
              X
            </button>
          ) : null} */}
          {this.props.showButton && (
            <>
              <div className="col-12 col-sm-auto">
                <button className="addBtn" onClick={() => handleAdd()}>
                  {" "}
                  <i className="bi bi-person-plus-fill"></i> Add {buttonTitle}
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  const { alert, current } = state;
  return { loggingIn, alert, current, user };
}

const actionCreators = {
  add: currentActions.add,
  remove: currentActions.remove,
};

export default connect(mapState, actionCreators)(SelectUser);