import React from "react";
import { Table, Modal, Button, Spinner } from "react-bootstrap";

class BillingTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        let { loading_status } = this.props;

        return (
            <div className="cmnTable">
                <Table className="table-billing-responsive" responsive striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>User Type</th>
                            <th>No. of Reports Viewed</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading_status && this.props.userDetails.length <= 0 ? <tr style={{ textAlign: "center" }}><td colSpan={10}><h5>No data available</h5></td></tr> : null}

                        {
                            loading_status ? <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '250%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} /> :
                                this.props.userDetails && this.props.userDetails.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.practitioner.first_name + " " + user.practitioner.last_name}</td>
                                        <td>{user.practitioner.email}</td>
                                        <td>{user.practitioner.type}</td>
                                        <td>{user.count}</td>
                                        <td>
                                            <Button className="show-kits" onClick={() => this.props.showKits(index)}>
                                                Click to view Kit ids
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default BillingTable;
