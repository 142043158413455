import { React } from "react";
import { Table } from "react-bootstrap";

import ColorIndicator from "../../components/ColorIndicator/ColorIndicator";
import { generateReferenceLink } from "../../helpers/generateReferenceLink";
const CongenitalDetails = ({data}) => {

    return (
        <>

            <div className="reportDetail-wrp">
                {/* <ContentTable></ContentTable> */}
                {/* use this "ContentTable" table component with below hard coded table data */}

                <div className="cmnTable">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>Rsid</th>
                                <th>Genotypes Index</th>
                                <th>Reference</th>
                            </tr>
                        </thead>
                        {
                            data ?
                            (
                                <tbody>
                                    {data.map((item)=>(
                                        <tr>
                                            <td>{item.rsid}</td>
                                            <td style={item.index[0] !== item.index[1] ? { color: 'red', fontWeight: 'bold' } : {}}>
                                                {item.index}
                                            </td>
                                            <td><a href={generateReferenceLink(item.rsid)} target="__blank" className="reference-link">{generateReferenceLink(item.rsid)}</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            ):(
                                <tbody>
                                <tr>
                                    <td></td>
                                </tr>
                            </tbody>
                            )
                        }
                    </Table>

                </div>

            </div>
        </>
    )

}

export default CongenitalDetails
