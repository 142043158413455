import React from "react";
import { Form, Modal, Button, Spinner, } from "react-bootstrap";
import { apiService } from '../../services/api_service'
import { alertActions } from '../../actions';
import { connect } from 'react-redux';
import CSVReader from "react-csv-reader";
import { store } from '../../helpers'

class BulkUploadModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show1: props.show ? props.show : false,
            error: {
                csv: false,
            },
            csvErrMsg: 'Please enter valid kit id',
            loader: false,
            showAlert: false,
            alert: {
                message: 'Error while update user',
                type: 'alert-danger'
            },
            kits: [],
            submitted: false
        }
        // this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.bloodKitIdValidateHandler = this.bloodKitIdValidateHandler.bind(this)
        this.errorValidation = this.errorValidation.bind(this)
    }

    // handleChange(e) {
    //     const { name, value } = e.target;
    //     this.setState({ [name]: value });
    // }

    errorValidation() {
        if (this.state.kits.length == 0) {
            this.setState({ csvErrMsg: 'Please upload a valid csv file', error: { ...this.state.error, csv: true } })
            return false
        }
        return true
    }

    async handleUpload(data, fileName) {
        console.log(data, fileName);
        let kits = [];
        for (let kit of data) {
            if (!kit.kit_id) break

            kits.push(kit.kit_id)
        }
        console.log("kits", kits)
        this.setState({ kits: kits })
    }

    async handleSubmit(e) {
        e.preventDefault();
       
        if (!this.errorValidation()) {
            return;
        }
        
        let body = {
            kits: this.state.kits.map(k=> k.toUpperCase()),
        }

        this.setState({ submitted: true });

        let uploadResp = await apiService.BulkUploadBloodKit(body)

        this.setState({ submitted: false })
        this.props.closeModal()
        if (uploadResp.status === 200) {
            setTimeout(() => {
                store.dispatch(alertActions.success(uploadResp.message))
            }, 1500)
            this.props.updateAPI()
            return
        }

        setTimeout(() => {
            store.dispatch(alertActions.error(uploadResp.message || "Error in uploading kits"))
        }, 1500)

    }

    handleClose = () => {
        // this.props.clearAlert();
        this.setState({
            kits: [],
            error: { csv: false }
        })
        this.props.closeModal()
    }

    render() {
        const { error, csvErrMsg, showAlert, alert } = this.state
        const { show, title } = this.props
        const csvParseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
        }
        return (
            <>
                <Modal show={show} onHide={this.handleClose} centered className="userModal" size="sm" >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form autoComplete='off'>
                            {showAlert &&
                                <div className={`alert ${alert.type}`} style={{ marginBottom: 15 }}>
                                    {alert.message}
                                    <button class="close" onClick={this.handleClose} >x</button>
                                </div>
                            }
                            <CSVReader
                                cssClass="react-csv-input"
                                label="Select a CSV file"
                                onFileLoaded={this.handleUpload}
                                parserOptions={csvParseOptions}
                            />
                            <div className="smDownLink">Sample file - <a download="sample.csv" target="_blank" href="sampleFile/kitsample.csv" style={{ color: "#007bff",  }}>Click here to Download Sample</a></div>
                            <Form.Text className="text-muted">
                                {error.csv ? csvErrMsg : null}
                            </Form.Text>
                            <Button className="continuebtn" type="submit" size='sm' onClick={this.handleSubmit}>
                                {this.state.submitted ? <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style={{ float: 'center' }} /> : 'SUBMIT'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
}

export default connect(mapState, actionCreators)(BulkUploadModal)
