
const EyeIconHide = () => {
    return (
        <div className="sideMenuIcons">
            <svg id="eyeicon" xmlns="http://www.w3.org/2000/svg" width="16.417" height="14.075" viewBox="0 0 16.417 14.075">
                <path id="Path_28133" data-name="Path 28133" d="M5.393,4.65a.521.521,0,0,0-.737.737L17.686,18.423a.516.516,0,0,0,.5.132.508.508,0,0,0,.235-.136.52.52,0,0,0,0-.733Z" transform="translate(-3.331 -4.499)" fill="#09a79e" />
                <g id="Group_13043" data-name="Group 13043" transform="translate(0 1.76)">
                    <path id="Path_28134" data-name="Path 28134" d="M10.615,18.2a3.3,3.3,0,0,1-3.134-4.706L5.524,11.531A25.709,25.709,0,0,0,2.4,14.511a.579.579,0,0,0,0,.781c2.379,2.628,4.475,4.889,8.052,4.889a7.057,7.057,0,0,0,3.016-.7l-1.594-1.594A3.33,3.33,0,0,1,10.615,18.2Z" transform="translate(-2.252 -9.625)" fill="#09a79e" />
                    <path id="Path_28135" data-name="Path 28135" d="M23.289,13.519a.575.575,0,0,0,.018-.759c-1.917-2.327-4.574-4.886-8.082-4.886a6.861,6.861,0,0,0-3.005.7l1.6,1.6a3.229,3.229,0,0,1,1.257-.315,3.3,3.3,0,0,1,3.134,4.706l1.961,1.961A24.244,24.244,0,0,0,23.289,13.519Z" transform="translate(-7.024 -7.875)" fill="#09a79e" />
                    <path id="Path_28136" data-name="Path 28136" d="M13.508,17.553a2.355,2.355,0,0,0,2.159,2.159,2.291,2.291,0,0,0,.858-.1L13.6,16.692A2.4,2.4,0,0,0,13.508,17.553Z" transform="translate(-7.637 -12.096)" fill="#09a79e" />
                    <path id="Path_28137" data-name="Path 28137" d="M21.78,17.869c0-.048,0-.1,0-.143a1.625,1.625,0,0,1-.667.143c-.04,0-.077,0-.114,0l.682.685A2.344,2.344,0,0,0,21.78,17.869Z" transform="translate(-11.225 -12.591)" fill="#09a79e" />
                    <path id="Path_28138" data-name="Path 28138" d="M17.377,14.123a1.722,1.722,0,0,1,.114-.619c-.037,0-.073,0-.114,0a2.319,2.319,0,0,0-.678.1l.685.685C17.381,14.233,17.377,14.178,17.377,14.123Z" transform="translate(-9.168 -10.568)" fill="#09a79e" />
                </g>
            </svg>
        </div>
    );
};
const EyeIconShow = () => {
    return (
        <div className="sideMenuIcons">
            <svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="10.551" viewBox="0 0 16.414 10.551">
                <g id="Icon_ionic-ios-eye" data-name="Icon ionic-ios-eye" transform="translate(-2.252 -7.875)">
                    <path id="Path_29028" data-name="Path 29028" d="M10.45,7.875C7.5,7.875,5.182,9.729,2.4,12.759a.579.579,0,0,0,0,.78c2.378,2.627,4.473,4.887,8.049,4.887,3.532,0,6.18-2.847,8.064-4.909a.575.575,0,0,0,.018-.758C16.612,10.432,13.956,7.875,10.45,7.875Zm.161,8.569a3.3,3.3,0,1,1,3.14-3.14A3.3,3.3,0,0,1,10.611,16.444Z" transform="translate(0 0)" fill="#09a79e" />
                    <path id="Path_29029" data-name="Path 29029" d="M15.845,14.123a1.721,1.721,0,0,1,.114-.619c-.037,0-.073,0-.114,0a2.344,2.344,0,1,0,2.345,2.345c0-.048,0-.1,0-.143a1.625,1.625,0,0,1-.667.143A1.7,1.7,0,0,1,15.845,14.123Z" transform="translate(-5.388 -2.694)" fill="#09a79e" />
                </g>
            </svg>
        </div>
    );
};

export { EyeIconHide, EyeIconShow }