import React from "react";
import PieChart from "../../components/Graphs/pieChart/pieChart";
import { getColorCode } from "../../helpers/getColorCode";

const CongenitalSummary = ({ num, summary }) => {

    return (
        <>
            <div className="reportSummary-wrp">
                <div className="congenital-summary">
                    <div className="left-part">
                        <PieChart summary={summary} num={num}  graphColor={ getColorCode(num) }/>
                    </div>
                    <div className="right-part">
                        <p className="summary-heading" style={{ color: getColorCode(num) }}>{summary.title}</p>
                        {summary.intro &&
                            <div className="summary-text" dangerouslySetInnerHTML={{ __html: summary.intro }} ></div>
                        }
                    </div>
                </div>
                <hr />
                <h6>Summary</h6>
                {
                    summary.title ?
                        <p>The individual score is {num}, {summary.total && summary.total.length == 2 ? summary.total[1] : "2"} being the highest risk. This score would indicate a {summary.title} </p>
                        :
                        <p>Out of a potential {summary.total && summary.total.length == 2 ? summary.total[1] : "2"} the individual scores {summary.total && summary.total.length == 2 ? summary.total[0] : "20"}, {summary.total && summary.total.length == 2 ? summary.total[1] : "46"} being the highest risk. This is a generally average outcome with {num} areas of note</p>
                }
            </div>
        </>
    )
}

export default CongenitalSummary;
