import React from 'react';
import { Route } from 'react-router-dom';
import UserHeader from '../UserHeader/UserHeader'

const UserLayout = ({ children }) => (

    <>
        <div className="signin-wrp-reset">
        <UserHeader></UserHeader>
            {children}
        </div>
    </>
);

const UserLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <UserLayout>
                <Component {...matchProps} />
            </UserLayout>
        )} />
    )
};

export default UserLayoutRoute;  
