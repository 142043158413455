import React from "react";

const BioAgeIcon = (props) => {
    return (
        <div className="epiReportIcons">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={21}
                height={21}
                viewBox="0 0 21 21"
                {...props}
            >
                <g id="BiologicalAge" transform="translate(0 0)">
                    <path
                        id="Path_28995"
                        data-name="Path 28995"
                        d="M120.115,113.082h1.972l-1.846-1.846a.7.7,0,1,0-.986.986Z"
                        transform="translate(-114.169 -106.477)"
                        fill="#09a79e"
                    />
                    <path
                        id="Path_28996"
                        data-name="Path 28996"
                        d="M321.842,112.24a.7.7,0,1,0-.986-.986L319.01,113.1h1.972Z"
                        transform="translate(-305.929 -106.496)"
                        fill="#09a79e"
                    />
                    <circle
                        id="Ellipse_3997"
                        data-name="Ellipse 3997"
                        cx={1.353}
                        cy={1.353}
                        r={1.353}
                        transform="translate(9.146 3.898)"
                        fill="#09a79e"
                    />
                    <path
                        id="Path_28997"
                        data-name="Path 28997"
                        d="M119.05,314.005a.7.7,0,0,0,1.19.493l1.516-1.516V311.01l-2.5,2.5a.693.693,0,0,0-.2.493Z"
                        transform="translate(-114.169 -298.256)"
                        fill="#09a79e"
                    />
                    <path
                        id="Path_28998"
                        data-name="Path 28998"
                        d="M327.05,311.01v1.972l1.516,1.516a.7.7,0,1,0,.986-.986Z"
                        transform="translate(-313.639 -298.256)"
                        fill="#09a79e"
                    />
                    <path
                        id="Path_28999"
                        data-name="Path 28999"
                        d="M84.608,192.444a.7.7,0,1,0,0-1.394H70.747a.7.7,0,1,0,0,1.394h4.634a.615.615,0,0,1,.612.558c0,.04,0-.4,0,8.094a.533.533,0,1,0,1.066,0v-4.429a.615.615,0,1,1,1.23,0V201.1a.533.533,0,0,0,1.066,0c0-8.486,0-8.054,0-8.094a.615.615,0,0,1,.612-.558Z"
                        transform="translate(-67.178 -183.215)"
                        fill="#09a79e"
                    />
                    <path
                        id="Path_29000"
                        data-name="Path 29000"
                        d="M17.914,3.086a10.486,10.486,0,1,0,0,14.829,10.452,10.452,0,0,0,0-14.829ZM15.42,17.676a1.914,1.914,0,0,1-1.363-.565l-.647-.647v1.416A1.762,1.762,0,0,1,10.5,19.219a1.762,1.762,0,0,1-2.912-1.337V16.465l-.647.647a1.927,1.927,0,1,1-2.725-2.725l3.372-3.372v-.555H3.569a1.927,1.927,0,0,1,0-3.855h.638A1.927,1.927,0,0,1,6.941,3.889l1,1a2.584,2.584,0,0,1,5.116,0l1-1A1.927,1.927,0,0,1,16.792,6.6h.638a1.927,1.927,0,1,1,0,3.855H13.411v.555l3.372,3.372a1.927,1.927,0,0,1-1.363,3.29Z"
                        transform="translate(0 0)"
                        fill="#09a79e"
                    />
                </g>
            </svg>
        </div>
    );
};

const EyeAgeIcon = (props) => {
    return (
        <div className="epiReportIcons">
            <svg
                id="EyeAge"
                xmlns="http://www.w3.org/2000/svg"
                width={21}
                height={21}
                viewBox="0 0 21 21"
                {...props}
            >
                <path
                    id="Path_29001"
                    data-name="Path 29001"
                    d="M.615,3.732a.615.615,0,0,0,.615-.615V1.23H3.117a.615.615,0,0,0,0-1.23H.615A.615.615,0,0,0,0,.615v2.5A.615.615,0,0,0,.615,3.732Z"
                    fill="#09a79e"
                />
                <path
                    id="Path_29002"
                    data-name="Path 29002"
                    d="M.615,424.732h2.5a.615.615,0,0,0,0-1.23H1.23v-1.887a.615.615,0,0,0-1.23,0v2.5A.615.615,0,0,0,.615,424.732Z"
                    transform="translate(0 -403.732)"
                    fill="#09a79e"
                />
                <path
                    id="Path_29003"
                    data-name="Path 29003"
                    d="M424.117,0h-2.5a.615.615,0,0,0,0,1.23H423.5V3.117a.615.615,0,0,0,1.23,0V.615A.615.615,0,0,0,424.117,0Z"
                    transform="translate(-403.732)"
                    fill="#09a79e"
                />
                <path
                    id="Path_29004"
                    data-name="Path 29004"
                    d="M424.117,421a.615.615,0,0,0-.615.615V423.5h-1.887a.615.615,0,0,0,0,1.23h2.5a.615.615,0,0,0,.615-.615v-2.5A.615.615,0,0,0,424.117,421Z"
                    transform="translate(-403.732 -403.732)"
                    fill="#09a79e"
                />
                <path
                    id="Path_29005"
                    data-name="Path 29005"
                    d="M184.076,181a3.076,3.076,0,1,0,3.076,3.076A3.08,3.08,0,0,0,184.076,181Zm0,4.922a1.846,1.846,0,1,1,1.846-1.846A1.848,1.848,0,0,1,184.076,185.922Z"
                    transform="translate(-173.576 -173.576)"
                    fill="#09a79e"
                />
                <path
                    id="Path_29006"
                    data-name="Path 29006"
                    d="M10.5,91C4.813,91,.3,97.148.116,97.409a.613.613,0,0,0,0,.716c.187.262,4.7,6.409,10.385,6.409s10.2-6.148,10.385-6.41a.613.613,0,0,0,0-.716C20.7,97.148,16.188,91,10.5,91Zm0,11.074a4.307,4.307,0,1,1,4.307-4.307A4.312,4.312,0,0,1,10.5,102.074Z"
                    transform="translate(0 -87.268)"
                    fill="#09a79e"
                />
                <circle
                    id="Ellipse_3998"
                    data-name="Ellipse 3998"
                    cx={0.522}
                    cy={0.522}
                    r={0.522}
                    transform="translate(9.916 9.916)"
                    fill="#09a79e"
                />
            </svg>
        </div>
    );
};

const HearingAgeIcon = (props) => {
    return (
        <div className="epiReportIcons">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={21}
                height={23.893}
                viewBox="0 0 21 23.893"
                {...props}
            >
                <g id="HearingAge" transform="translate(-31)">
                    <g id="Group_13310" data-name="Group 13310" transform="translate(31)">
                        <path
                            id="Path_29007"
                            data-name="Path 29007"
                            d="M38.7,0A7.709,7.709,0,0,0,31,7.7a7.631,7.631,0,0,0,2.285,5.466A6.485,6.485,0,0,1,35.2,17.751V18.2a5.6,5.6,0,1,0,11.2,0V7.7A7.709,7.709,0,0,0,38.7,0Zm4.9,16.1a2.1,2.1,0,0,1-4.194.165l0-.165a3.215,3.215,0,0,1,1.783-2.726A1.823,1.823,0,0,0,42.2,11.735V11.2A4.2,4.2,0,0,0,38,7V5.6a5.565,5.565,0,0,1,4.179,1.91,3.484,3.484,0,0,0-4.141-3.25,3.521,3.521,0,0,0-2.755,2.663,3.473,3.473,0,0,0,.954,3.256l-.984,1A4.9,4.9,0,1,1,43.6,7.7v8.4Z"
                            transform="translate(-31)"
                            fill="#09a79e"
                        />
                        <path
                            id="Path_29008"
                            data-name="Path 29008"
                            d="M241.059,310.3a.7.7,0,0,0,1.4-.063v-1.7a3.343,3.343,0,0,1-.387.228A1.823,1.823,0,0,0,241.059,310.3Z"
                            transform="translate(-231.256 -294.141)"
                            fill="#09a79e"
                        />
                    </g>
                    <g
                        id="Group_13311"
                        data-name="Group 13311"
                        transform="translate(47.383 7.193)"
                    >
                        <path
                            id="Path_29009"
                            data-name="Path 29009"
                            d="M0,0H3.233V1.4H0Z"
                            transform="translate(0 1.439) rotate(-26.411)"
                            fill="#09a79e"
                        />
                    </g>
                    <g
                        id="Group_13312"
                        data-name="Group 13312"
                        transform="translate(47.372 13.902)"
                    >
                        <path
                            id="Path_29010"
                            data-name="Path 29010"
                            d="M0,0H1.4V3.233H0Z"
                            transform="translate(0 1.254) rotate(-63.64)"
                            fill="#09a79e"
                        />
                    </g>
                    <g
                        id="Group_13313"
                        data-name="Group 13313"
                        transform="translate(47.8 11.2)"
                    >
                        <path
                            id="Path_29011"
                            data-name="Path 29011"
                            d="M391,240h4.2v1.4H391Z"
                            transform="translate(-391 -240)"
                            fill="#09a79e"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};


const MemoryAgeIcon = (props) => {
    return (
        <div className="epiReportIcons">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={21.001}
                height={24.312}
                viewBox="0 0 21.001 24.312"
                {...props}
            >
                <g id="MemoryAge" transform="translate(0)">
                    <path
                        id="Path_29012"
                        data-name="Path 29012"
                        d="M184.29,120a4.29,4.29,0,1,1-4.29,4.29A4.3,4.3,0,0,1,184.29,120Zm1.011,5.3a1.429,1.429,0,0,1-2.022,0l-1.011,1.011a2.86,2.86,0,1,0,0-4.045l1.011,1.011A1.43,1.43,0,0,1,185.3,125.3Z"
                        transform="translate(-174.505 -114.28)"
                        fill="#09a79e"
                    />
                    <path
                        id="Path_29013"
                        data-name="Path 29013"
                        d="M37.324,20.46a4.9,4.9,0,0,0-.426-3.6,12.6,12.6,0,0,1-2.174-6.85,10.011,10.011,0,0,1,20.022,0v2.572l.98,4.578H52.6v3.814H49.025v3.337H35.759A33.8,33.8,0,0,0,37.324,20.46Zm7.186-4.729a5.72,5.72,0,1,0-5.72-5.72A5.727,5.727,0,0,0,44.51,15.731Z"
                        transform="translate(-34.725 0)"
                        fill="#09a79e"
                    />
                </g>
            </svg>
        </div>
    );
};

const InflammationAgeIcon = (props) => {
    return (
        <div className="epiReportIcons">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={21}
                height={25.669}
                viewBox="0 0 21 25.669"
                {...props}
            >
                <g id="InflammationAge" transform="translate(-46.564)">
                    <g
                        id="Group_13315"
                        data-name="Group 13315"
                        transform="translate(53.542 15.067)"
                    >
                        <g id="Group_13314" data-name="Group 13314">
                            <path
                                id="Path_29014"
                                data-name="Path 29014"
                                d="M192.1,304.011a1.935,1.935,0,0,1-1.368.387,2.463,2.463,0,0,1-1.887-1.585,3.98,3.98,0,0,1-.247-2.283c-1.249.669-2.852,2.187-2.852,5.576a3.522,3.522,0,1,0,6.353-2.095Z"
                                transform="translate(-185.747 -300.529)"
                                fill="#09a79e"
                            />
                        </g>
                    </g>
                    <g id="Group_13317" data-name="Group 13317" transform="translate(46.564)">
                        <g id="Group_13316" data-name="Group 13316" transform="translate(0)">
                            <path
                                id="Path_29015"
                                data-name="Path 29015"
                                d="M67.547,14.577l-.113-2.023L66.2,14.16a3.4,3.4,0,0,1-.575.56,8,8,0,0,0,.262-1.189A8.436,8.436,0,0,0,64.1,7.179l-.782-1.044-.512,1.2c-.591,1.384-1.548,2.078-2.437,1.769a2.865,2.865,0,0,1-1.526-2.149A11.4,11.4,0,0,1,59.2,1.207L59.529,0,58.309.275c-4.117.928-6.892,3.851-8.023,8.455a20.472,20.472,0,0,0-.552,5.009,8.2,8.2,0,0,1-1.471-1.495l-.927-1.361-.422,1.594A10.506,10.506,0,0,0,56.935,25.669a5.034,5.034,0,0,1-4.9-5.026c0-3.518,1.511-5.392,2.779-6.345a6.529,6.529,0,0,1,2.837-1.232l1.741-.218-1.042,1.412a2.514,2.514,0,0,0-.331,2.487c.2.448.48.67.674.692.246.028.492-.254.618-.424l.492-.668.617.554a5.028,5.028,0,0,1-3.228,8.768,10.512,10.512,0,0,0,10.371-10.5C67.564,14.979,67.559,14.779,67.547,14.577Z"
                                transform="translate(-46.564)"
                                fill="#09a79e"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};
const ProInflammationIcon = (props) => {
    return (
        <div className="epiReportIcons">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={25}
                height={25}
                viewBox="0 0 25 25"
                {...props}
            >
                <g id="surface1">
                    <path
                        style={{
                            stroke: "none",
                            fillRule: "nonzero",
                            fillOpacity: 1,
                        }}
                        d="M 6.101562 25 C 4.472656 21.773438 5.34375 19.898438 6.589844 18.128906 C 7.460938 16.984375 8.003906 15.683594 8.273438 14.332031 C 8.925781 15.371094 9.199219 16.621094 8.925781 17.714844 C 10.445312 15.945312 11.207031 13.601562 10.878906 11.207031 C 15.226562 14.175781 17.070312 20.316406 14.574219 24.945312 C 27.769531 17.816406 17.886719 7.148438 16.09375 5.898438 C 16.691406 7.097656 16.746094 9.125 15.605469 10.117188 C 13.597656 2.984375 8.707031 1.527344 8.707031 1.527344 C 9.304688 5.171875 6.589844 9.230469 4.039062 12.195312 C 4.039062 10.84375 3.710938 9.542969 3.007812 8.292969 C 2.84375 10.949219 0.726562 13.132812 0.128906 15.839844 C -0.414062 19.585938 0.941406 22.1875 6.101562 25 Z M 6.101562 25 "
                    />
                    <path
                        style={{
                            fillRule: "nonzero",
                            fillOpacity: 1,
                            strokeWidth: 0.5,
                            strokeLinecap: "square",
                            strokeLinejoin: "miter",
                            stroke: "rgb(100%,100%,100%)",
                            strokeOpacity: 1,
                            strokeMiterlimit: 4,
                        }}
                        d="M 28.301561 14.197869 L 22.000064 14.197869 C 21.798646 14.197869 21.597229 14.002706 21.597229 13.800038 L 21.597229 7.397206 C 21.597229 7.096956 21.302296 6.796706 21.000169 6.796706 L 16.799171 6.796706 C 16.403529 6.796706 16.202111 7.096956 16.202111 7.397206 L 16.202111 13.702456 C 16.202111 13.897619 16.000694 14.100288 15.799276 14.100288 L 9.39707 14.100288 C 9.001428 14.100288 8.800011 14.400538 8.800011 14.700788 L 8.800011 19.001869 C 8.800011 19.3997 9.102137 19.602369 9.39707 19.602369 L 15.698567 19.602369 C 15.899985 19.602369 16.101403 19.797531 16.101403 20.0002 L 16.101403 26.297944 C 16.101403 26.703281 16.403529 26.898444 16.698462 26.898444 L 21.000169 26.898444 C 21.403004 26.898444 21.597229 26.598194 21.597229 26.297944 L 21.597229 20.0002 C 21.597229 19.797531 21.798646 19.602369 22.000064 19.602369 L 28.301561 19.602369 C 28.697203 19.602369 28.898621 19.302119 28.898621 19.001869 L 28.898621 14.798369 "
                        transform="matrix(0.543026,0,0,0.5204,9.154961,-3.408097)"
                    />
                </g>
            </svg>
        </div>
    );
};

const AntiInflammationIcon = (props) => {
    return (
        <div className="epiReportIcons">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={25}
                height={25}
                viewBox="0 0 25 25"
                {...props}
            >
                <g id="surface1">
                    <path
                        style={{
                            stroke: "none",
                            fillRule: "nonzero",

                            fillOpacity: 1,
                        }}
                        d="M 8.027344 24.992188 C 6.324219 21.554688 7.242188 19.578125 8.554688 17.648438 C 9.425781 16.445312 10.039062 15.070312 10.300781 13.609375 C 11 14.726562 11.261719 16.015625 11 17.21875 C 12.574219 15.328125 13.359375 12.839844 13.011719 10.304688 C 17.46875 13.398438 19.433594 19.964844 16.769531 24.863281 C 30.445312 17.261719 20.175781 5.882812 18.339844 4.59375 C 18.953125 5.882812 19.039062 8.027344 17.816406 9.058594 C 15.808594 1.546875 10.738281 0 10.738281 0 C 11.351562 3.863281 8.554688 8.199219 5.933594 11.378906 C 5.933594 9.917969 5.582031 8.542969 4.882812 7.257812 C 4.707031 10.089844 2.523438 12.410156 1.910156 15.285156 C 1.167969 19.238281 2.65625 21.984375 8.027344 24.992188 Z M 8.027344 24.992188 "
                    />
                    <path
                        style={{
                            fillRule: "nonzero",

                            fillOpacity: 1,
                            strokeWidth: 1,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            stroke: "rgb(100%,100%,100%)",
                            strokeOpacity: 1,
                            strokeMiterlimit: 4,
                        }}
                        d="M 33.399752 -3.495555 L 38.29701 -3.499881 L 38.300781 71.597172 L 33.403523 71.601497 Z M 33.399752 -3.495555 "
                        transform="matrix(0.30896,-0.303633,0.30896,0.303633,-9.106378,13.292468)"
                    />
                    <path
                        style={{
                            fillRule: "nonzero",
                            fillOpacity: 1,
                            strokeWidth: 1,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            stroke: "rgb(100%,100%,100%)",
                            strokeOpacity: 1,
                            strokeMiterlimit: 4,
                        }}
                        d="M 33.398189 -3.501697 L 38.301768 -3.503694 L 38.30443 71.599791 L 33.400851 71.601788 Z M 33.398189 -3.501697 "
                        transform="matrix(-0.30896,-0.303633,0.30896,-0.303633,13.068545,33.964273)"
                    />
                    <path
                        style={{
                            stroke: "none",
                            fillRule: "nonzero",
                            fillOpacity: 1,
                        }}
                        d="M 0.132812 2.089844 L 1.644531 0.601562 L 24.847656 23.402344 L 23.335938 24.890625 Z M 0.132812 2.089844 "
                    />
                    <path
                        style={{
                            stroke: "none",
                            fillRule: "nonzero",
                            fillOpacity: 1,
                        }}
                        d="M 1.667969 24.886719 L 0.152344 23.398438 L 23.355469 0.59375 L 24.871094 2.082031 Z M 1.667969 24.886719 "
                    />
                </g>
            </svg>
        </div>
    );
};

export { BioAgeIcon, EyeAgeIcon, HearingAgeIcon, MemoryAgeIcon, InflammationAgeIcon, ProInflammationIcon, AntiInflammationIcon }