import React, { Component } from 'react';
import './styles.css'

class Error404 extends Component {
    constructor() {
        super()
        this.state = {
            name: "random",
            number: 13
        }
    }
    componentDidMount(){
        let user = JSON.parse(sessionStorage.getItem("user"))
        if(!user){
            window.location.href = "/signin"
        }
    }
    render(){
        return (
            <div className="error404">
                
                <section class="page_404">
                <div class="container">
                    <div class="row"> 
                    <div class="col-sm-12 ">
                    <div class="col-sm-10 col-sm-offset-1  text-center">
                    <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                    </div>
                    
                    <div class="contant_box_404">
                        <h3 class="h2">
                            Look like you're lost
                        </h3>
                        
                        <p>the page you are looking for not avaible!</p>
                        
                        {/* <a href="" class="link_404">Go to Home</a> */}
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
                </section>

            </div>
        )
    }
}

export default Error404
