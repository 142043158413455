import React, { PureComponent } from 'react'
import debounce from 'lodash.debounce'
import GraphFilter from '../Graphs/GraphFilter/GraphFilter'

export default class TabItems extends PureComponent {
    constructor(props) {
        super()

        this.state = {
            items: [...Array(25).keys()],
            hasOverflow: false,
            canScrollLeft: false,
            canScrollRight: false
        }           
        this.checkForOverflow = this.checkForOverflow.bind(this)
        this.checkForScrollPosition = this.checkForScrollPosition.bind(this)

        this.debounceCheckForOverflow = debounce(this.checkForOverflow, 1000)
        this.debounceCheckForScrollPosition = debounce(
            this.checkForScrollPosition,
            200
        )
        this.container = null
    }

    componentDidMount() {
        this.checkForOverflow()
        this.checkForScrollPosition()

        this.container.addEventListener(
            'scroll',
            this.debounceCheckForScrollPosition
        )
    }

    componentWillUnmount() {
        this.container.removeEventListener(
            'scroll',
            this.debounceCheckForScrollPosition
        )
        this.debounceCheckForOverflow.cancel()
    }

    componentDidUpdate(nextProps, prevState) {
        if (prevState.items.length !== this.state.items.length) {
            this.checkForOverflow()
            this.checkForScrollPosition()
        }
    }

    checkForScrollPosition() {
        const { scrollLeft, scrollWidth, clientWidth } = this.container

        this.setState({
            canScrollLeft: scrollLeft > 0,
            canScrollRight: scrollLeft !== scrollWidth - clientWidth
        })
    }

    checkForOverflow() {
        const { scrollWidth, clientWidth } = this.container
        const hasOverflow = scrollWidth > clientWidth

        this.setState({ hasOverflow })
    }



    scrollContainerBy(distance) {
        this.container.scrollBy({ left: distance, behavior: 'smooth' })
    }



    buildControls() {
        const { canScrollLeft, canScrollRight } = this.state
        return (
            <div className="tabItem-controls">
                <button
                    type="button"
                    className="leftBtn"
                    disabled={!canScrollLeft}
                    onClick={() => {
                        this.scrollContainerBy(-200)
                    }}
                >
                
                   <img src="/images/arrow-left.svg"></img>
                </button>
       
                <button
                    type="button"
                    className="rightBtn"
                    disabled={!canScrollRight}
                    onClick={() => {
                        this.scrollContainerBy(200)
                    }}
                >
                      <img src="/images/arrow-right.svg"></img>
                </button>
            </div>
        )
    }

    render() {
        return (
            <>
            <div className="tabContainer">
                <ul                  
                    ref={node => {
                        this.container = node
                    }}
                >

                {this.props.tabs && this.props.tabs.map(tab =>(
                    <li 
                       id = {tab}
                       className={tab == this.props.view ? 'tabItem active' : 'tabItem'}
                       onClick = {()=>this.props.ChangeTabs(tab)}                    >
                   {tab}
                    </li>
                ))}
               
                </ul>
                
                {this.buildControls()}
                </div>
               
            </>
        )
    }
}
