import { React } from "react";

const UserRegisteredcountCard = (props) => {
  return (
    <>
      <div className="userRegisteredcard">
        <h2>Registered Users</h2>
        <ul className="list-unstyled">
          <li>
            <img src="/images/biuser.svg" className="filterit"></img> <span>{props.userDetail.total} </span>
            Registered Users
          </li>
          <li>
            <img src="/images/Union.svg" className="filterit"></img> Last Week -{" "}
            <span>{props.userDetail.lastWeek} New Users</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserRegisteredcountCard;
