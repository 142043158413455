import  React  from "react";

import OverviewRecentDetails from "../../components/OverviewCard/OverviewRecentDetails";
import { RiHeartPulseFill } from "react-icons/ri";


class OverviewCard extends React.Component{
   
    constructor(props){
        super()
        this.state = {
            show:false
        }
    }
    ShowDetails(){
         this.setState({show:true})
    }

    render(){
        const {show}= this.state
        const{details} =this.props
        return (
            <>
                <div className="overviewcard-wrp">
                    <div className="overviewHead">
                        <h3>{details.Title} <small>Recent</small></h3>
                        <figure>
                            <img src={details.main_img} class="filterit"></img>
                        </figure>
                        <h4> {details.avg}</h4>
                        <ul className="list-unstyled">
                            {details.value1 && <li> <img src={details.list_img1} class="filterit"></img>{details.value1}</li>}
                            {details.value2 && <li> <img src={details.list_img2} class="filterit"></img>{details.value2}</li>}
                            {details.value3 && <li> <img src={details.list_img3} class="filterit"></img>{details.value3}</li>}
                        </ul>
                    </div>

                    {details.Title === "Active Time" ? 
                    <div className="overviewDetails">
                    <ul className="activeTimeDetails">
                        <li> <img src="/images/lifestyle/stepindicator1.svg" class="filterit"></img>{details.cal}</li>
                        <li> <img src="/images/lifestyle/stepindicator2.svg" class="filterit"></img>{details.distance}</li>
                    </ul>

                    </div> 
                : 
                   details.Title === "Heart Rate" ? 
                   <div className="overviewDetails">
                    <ul className="list-unstyled">
                        <li> 
                            <RiHeartPulseFill
                            color ='red'
                            size={20}/> Min HR - {details.min}
                        </li>
                        <li> <RiHeartPulseFill
                            color ='red'
                            size={20}/> Max HR - {details.max}</li>
                    </ul>

                   </div> 
                    
                : <OverviewRecentDetails
                      icon = {details.icons}
                      details={details}
                ></OverviewRecentDetails>}
                    

                    {/* <ul className="list-unstyled iconlist">
                        <li><span style={{backgroundColor : "#FF7700"}}></span> Minimum Heart Rate</li>
                        <li><span style={{backgroundColor : "#09A79E"}}></span> Maximum Heart Rate</li>
                        <li><span style={{backgroundColor : "#FC1504"}}></span> Avg Heart Rate</li>
                    </ul> */}
                    
                    
                    
                </div>
            </>
        )

    }
   
}

export default OverviewCard