import { Provider } from "react-redux";
import React from "react";
import Routes from "./routes/index";
import { store } from "./helpers";

class App extends React.Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <Routes />
        </Provider>
      </div>
    );
  }
}

export default App;
