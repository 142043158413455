import React from "react";
import { Breadcrumb } from "react-bootstrap";
const BreadCrumb = ({title}) => {
    return (
        <div className="breadcrumb-wrp">
            <Breadcrumb>
                <Breadcrumb.Item href="#">Customers</Breadcrumb.Item>
                {title !== "Customers"?<Breadcrumb.Item active> {title}</Breadcrumb.Item>: null}
            </Breadcrumb>
        </div>
    );
};

export default BreadCrumb;
