import React from "react";
import {Modal} from 'react-bootstrap'
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
//import { Document, Page } from 'react-pdf/build/entry.noworker';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
// import "./index.scss";

class TrainingPdf extends React.Component {
    constructor(props){
        super(props)    
        this.state = {
            showpdf: false,
            loading: false,
            modal: [],
            numPages: null,
            pageNumber: 1,
            scale: 1.17,
            changePage: 1
        }
    }

    async componentDidMount(){
        // let partner_id = this.props.user?.partner_id
        // let logo = await this.logoPick(partner_id)
        // this.setState({logo:logo})
        console.log("component did mount", this.props.showpdf)
        this.setState({showpdf: this.props.showpdf})
    }


    onDocumentLoadSuccess({ numPages }) {
        // setNumPages(numPages);
        this.setState({ numPages, pageNumber: 1 });
        // setPageNumber(1);
    }
    
    // changePage(offset) {
    //     this.setState({ pageNumber: prevPageNumber => prevPageNumber + offset});
    // }

    previousPage() {
        // this.changePage(-1);
        this.setState({pageNumber: this.state.pageNumber-1})
    }

    nextPage() {
        // this.changePage(1);
        this.setState({pageNumber: this.state.pageNumber+1})
    }

    handlePDFModal = async() => {
        this.setState({showpdf: !this.state.showpdf, modal: "Hello"})
    }

    scrollToItem(page) {
        // windowRef.current && windowRef.current.scrollToItem(page - 1, "start");
        this.setState({pageNumber: parseInt(page)})
      };

    render(){
        const { pageNumber, numPages, changePage, scale } = this.state;

        return (
            <>
            <Modal show={this.state.showpdf} onHide={() => this.handlePDFModal()} centered className="trackingModalPdf" size="sm" >
                <Modal.Header closeButton>
                    <Modal.Title>Training PDF:</Modal.Title>
                </Modal.Header>
                <Modal.Body className="training-pdf-modal">
                    <Document
                        file="/pdf/Education.pdf"
                        // file={{
                        //     url: "https://live-others.s3.eu-west-2.amazonaws.com/pdf-stuffs/Education.pdf"
                        // }}
                        onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
                        options={{
                            workerSrc:
                            "cdn.jsdelivr.net/npm/react-pdf@4.0.0-beta.5/dist/pdf.worker.entry.js"
                        }}
                        >
                            <Page pageNumber={pageNumber} scale={scale} wrap={true} />
                        {/* {Array.from(new Array(numPages), (el, index) => (
                            <Page size="A4" key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))} */}
                    </Document>
                    <div className="pdf-controls">
                        <button type="button" onClick={() => this.setState({scale: scale-0.1})}>
                            -
                        </button>
                            Zoom
                        <button type="button" onClick={() => this.setState({scale: scale+0.1})}>
                            +
                        </button>
                        <button type="button" disabled={pageNumber <= 1} onClick={() => this.previousPage()}>
                            Previous
                        </button>
                        <p>
                            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                        </p>
                        <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={() => this.nextPage()}
                        >
                            Next
                        </button>
                        <input value={changePage} size={5} type="number" style={{width:"72px"}} onChange={e => this.setState({ changePage: e.target.value})} />
                        <button type="button" onClick={() => this.scrollToItem(changePage)}>
                            goto
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            </>
        );
    } 
};

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return {loggingIn, user};
  }

const actionCreators = {
    success: alertActions.success
  }

export default connect(mapState, actionCreators)(TrainingPdf)
