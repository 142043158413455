import { React } from "react";

const Checkbox = ({title,value}) => {

    return (
        <>
            <div className="customCheckbox">
                <label className="checklabel"> {title}
                    <input value={value} type="checkbox"></input>
                    <span className="checkmark"></span>
                </label>
            </div>

        </>
    )

}

export default Checkbox